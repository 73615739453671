import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import EventBus from '../../../elements/EventBus';
import ParticipantsOverlay from './ParticipantsOverlay';

function ParticipantsButton({
  event, className, disabled, past,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!disabled) setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    EventBus.on('brandClick', handleClose);

    return () => {
      EventBus.remove('brandClick', handleClose);
    };
  });

  return (
    <>
      <button onClick={handleOpen} className={`btn-connect btn-participants ${className}`} disabled={disabled}>
        <Translate id="meeting.participants.button" />
      </button>
      <ParticipantsOverlay event={event} isOpen={open} onClose={handleClose} past={past} />
    </>
  );
}

export default ParticipantsButton;

import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {ROUTE_DEFAULT} from "../../config/routes";
import {connect} from "react-redux";
import Menu from "./Menu";
import logoConnect from "../../gfx/logo_connect.svg";
import logoConnectWhite from "../../gfx/logo_connect_white.svg";
import BorderWithDot from "../border/borderWithDot";
import {withRouter} from "react-router";
import CompanyChooser from "./CompanyChooser";
import {withResizeDetector} from "react-resize-detector";
import EventBus from "../EventBus";
import LanguageToggle from "../LanguageToggle";
import {withLocalize} from "react-localize-redux";

class TopBar extends React.Component {

    state = {
        toggleMenu: false,
        borderPositionX: 0
    };

    clickNavItem = (e) => {
        this.setState({
            toggleMenu: !this.state.toggleMenu
        });
    }

    componentDidMount() {
        this.setBorderPositionX();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location || this.props.width !== prevProps.width || prevProps.activeLanguage?.code !== this.props.activeLanguage?.code) {
            this.setBorderPositionX();
        }
    }

    setBorderPositionX = () => {
        let that = this;
        setTimeout(() => {
            let element = document.querySelector('.TopBar-Menu .active');
            if (element) {
                that.setState({
                    borderPositionX: element.getBoundingClientRect().left
                });
            } else {
                that.setState({
                    borderPositionX: window.outerWidth + 100
                });
            }
        }, 150);
    }

    render() {
        const {authorization} = this.props;
        const authorized = !!authorization.bearerToken;

        return (
            <div className={this.state.toggleMenu ? 'show TopBar' : 'TopBar'}>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-topBar">
                            <div className={`brand ${this.state.toggleMenu ? 'd-none' : 'd-block'} d-md-block`}>
                                <Link to={ROUTE_DEFAULT} onClick={() => EventBus.dispatch('brandClick', {})}>
                                    <img alt="" className="logo logo-connect" src={logoConnect}/>
                                    <img alt="" className="logo logo-connect-openMenu d-none" src={logoConnectWhite}/>
                                </Link>
                            </div>
                            <div className="mobile-menu-header-container d-md-none">
                                <LanguageToggle className={`d-none${this.state.toggleMenu ? ' d-inline-block' : ''}`}/>
                                <div className={`company-chooser-container ${this.state.toggleMenu ? 'd-block' : 'd-none'}`}>
                                    <CompanyChooser/>
                                </div>
                            </div>
                            <div className={`company-chooser-container d-none d-lg-flex`}>
                                <CompanyChooser/>
                            </div>
                            {authorized ? (
                                <Fragment>
                                    <button className="TopBar-Toggle d-lg-none" onClick={() => this.setState({toggleMenu: !this.state.toggleMenu})}>
                                        <BorderWithDot borderClass="border-with-dot-darkturkuoise"/>
                                        <BorderWithDot borderClass="border-with-dot-darkturkuoise"/>
                                        <BorderWithDot borderClass="border-with-dot-darkturkuoise"/>
                                    </button>
                                    <div className="TopBar-Menu-container">
                                        <LanguageToggle className="d-none d-md-inline-block d-lg-none"/>
                                        <Menu onLinkClick={this.clickNavItem}
                                        borderPositionX={this.state.borderPositionX}/>

                                        <BorderWithDot borderClass="border-with-dot-left border-with-dot-darkturkuoise d-none d-lg-block"
                                                       borderPositionX={this.state.borderPositionX}/>
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization
});

export default connect(mapStateToProps, null)(withRouter(withResizeDetector(withLocalize(TopBar))));

import {
  PROFILE_COMPANIES_LOAD_SUCCESS, PROFILE_DELETE_IMAGE_SUCCESS,
  PROFILE_LOAD_SUCCESS,
  PROFILE_SELECT_COMPANY,
  PROFILE_UPDATE_COMPANY_COLOR_SUCCESS
} from "../../config/actions";

let defaultState = {
  loaded: false,
  name: '',
  institution: '',
  email: '',
  itemId: null,
  kmb: null,
  coverage: null,
  companies: null,
  image: null,
  company: null,
  selectedCompanyId: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        name: action.name,
        institution: action.institution,
        email: action.email,
        itemId: action.itemId,
        kmb: action.kmb,
        coverage: action.coverage,
        image: action.image,
        company: action.company
      }
    case PROFILE_COMPANIES_LOAD_SUCCESS:
      return {
        ...state,
        companies: action.companies
      }
    case PROFILE_SELECT_COMPANY:
      return {
        ...state,
        selectedCompanyId: action.companyId
      }
    case PROFILE_UPDATE_COMPANY_COLOR_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          color: action.color
        }
      }
    case PROFILE_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        image: null
      }
    default:
      return state;
  }
}

import React, {Fragment, useEffect, useState} from 'react';
import ParticipantsNoParticipants from "./ParticipantsNoParticipants";
import {Translate} from "react-localize-redux";
import ParticipantsPersonRow from "./ParticipantsPersonRow";

const ParticipantsRoadshowSlot = ({slot, persons, hasFilters}) => {
  const [personsToShow, setPersonsToShow] = useState([]);

  useEffect(() => {
    setPersonsToShow(persons.filter(p => [...slot.participants, ...slot.appliers].find(ep => ep.appId === p.appId && ep.itemId === p.itemId)))
  }, [slot, persons]);

  const [sortColumn, setSortColumn] = useState('name');
  const [sortDir, setSortDir] = useState('asc');
  const [sortedPersonsToShow, setSortedPersonsToShow] = useState([]);

  useEffect(() => {
    switch (sortColumn) {
      case "name":
        setSortedPersonsToShow([...(sortDir === 'asc' ? personsToShow.sort((a, b) => a.name?.localeCompare(b.name)) : personsToShow.sort((a, b) => b.name?.localeCompare(a.name)))]);
        break;
      case "institution":
        setSortedPersonsToShow([...(sortDir === 'asc' ? personsToShow.sort((a, b) => (a.institution ? a.institution.name : '').localeCompare(b.institution ? b.institution.name : '')) : personsToShow.sort((a, b) => (b.institution ? b.institution.name : '').localeCompare(a.institution ? a.institution.name : '')))]);
        break;
      default:
        break;
    }
  }, [sortColumn, sortDir, personsToShow]);

  const toggleSort = (column) => {
    if (sortColumn === column) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDir('asc');
    }
  }

  return (
    sortedPersonsToShow && sortedPersonsToShow.length > 0 ? (
      <Fragment>
        <div className="participants-list-header space-top d-none d-md-flex">
          <div className="col-name">
            <div className="d-inline-block d-lg-none" onClick={() => toggleSort('name')}>
              <Translate id="meeting.participants.dialog.tableHeader.nameAndInstitute"/>
            </div>
            <div className="d-none d-lg-inline-block" onClick={() => toggleSort('name')}>
              <Translate id="meeting.participants.dialog.tableHeader.name"/>
            </div>
          </div>
          <div className="col-institute d-none d-lg-block"
               onClick={() => toggleSort('institution')}>
            <div className="d-inline-block">
              <Translate id="meeting.participants.dialog.tableHeader.institute"/>
            </div>
          </div>
        </div>
        {sortedPersonsToShow.map((person) => (
          <ParticipantsPersonRow person={person} key={person.itemId}/>
        ))}
      </Fragment>
    ) : (
      <ParticipantsNoParticipants hasFilters={hasFilters}/>
    )
  )
};

export default ParticipantsRoadshowSlot;

import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";
import PublicationsApi from "../../../api/PublicationsApi";
import {Overlay} from "react-portal-overlay";
import ContactsTextSearch from "../contacts/ContactsTextSearch";
import moment from "moment";
import ProfileButton from "../contacts/ProfileButton";

const DemandsButton = ({publication, ...otherProps}) => {

  const [open, setOpen] = useState(false);
  const [demandsInfo, setDemandsInfo] = useState(null);
  const [demandsToShow, setDemandsToShow] = useState([]);

  const [textSearchIsOpen, setTextSearchIsOpen] = useState(false);
  const [textSearch, setTextSearch] = useState(null);

  const onChangeTextSearch = (newTextSearchIsOpen) => {
    setTextSearchIsOpen(newTextSearchIsOpen);
  };

  useEffect(() => {
    if (open && !demandsInfo) {
      PublicationsApi.getDemands(publication.id)
        .then(response => {
          if (response.status === 200) {
            setDemandsInfo(response.data.data);
          }
        })
    }
  }, [open, demandsInfo]);

  useEffect(() => {
    if (open && demandsInfo?.demands) {
      let dts = demandsInfo.demands;
      // filter by search string
      if (textSearch && textSearch.length > 0) dts = dts.filter(item => !textSearch.split(" ").find(textSearchItem => !item.name.toLowerCase().includes(textSearchItem.toLowerCase())));

      setDemandsToShow(dts);
    }
  }, [open, demandsInfo, textSearch]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Fragment>
      <button onClick={handleOpen} className="DemandsButton" {...otherProps}>
        <Translate id="publication.numberOfDemands"/>
      </button>
      <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
        <div className="demands-dialog-outer">
          <div className="demands-dialog">
            <button onClick={handleClose}>
              <Translate id="publication.demands.dialog.back"/>
            </button>
            {demandsInfo && (
              <div className="demands-content">
                <div className="title">
                  {demandsInfo.title}
                </div>
                <div className="container-filter-row-top">
                  <ContactsTextSearch textSearchIsOpen={textSearchIsOpen}
                                      onNameChange={onChangeTextSearch}
                                      onTextSearch={val => setTextSearch(val)}/>
                </div>
                <div className="demands">
                  {demandsToShow.map((demand, dI) => (
                    <div className="demand" key={dI}>
                      <div className="col-date d-none d-lg-block text-date">
                        {demand.date?.start ? moment(demand.date.start).format('LLL') : ''}
                      </div>
                      <div className="col-name">
                        <div className="text-name">
                          {demand.name}
                        </div>
                        <div className="text-date d-lg-none">
                          {demand.date?.start ? moment(demand.date.start).format('LLL') : ''}
                        </div>
                      </div>
                      <div className="col-profile">
                        <ProfileButton appId={demand.appId} itemId={demand.itemId}/>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Overlay>
    </Fragment>
  )
}

export default DemandsButton;
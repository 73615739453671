import React from 'react';
import MeetingsPaginateRangeSelect from "./MeetingsPaginateRangeSelect";
import {connect} from "react-redux";
import Meeting from "./Meeting";
import moment from 'moment';
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import iconArrowRight from "../../../gfx/arrow-right.svg"
import iconArrowLeft from "../../../gfx/arrow-left.svg"
import ContactWidgetLink from "./ContactWidgetLink";
import {withRouter} from "react-router";
import Order from "../../../elements/order";
import EventBus from "../../../elements/EventBus";

class Meetings extends React.Component {
    state = {
        textSearchIsOpen: false,
        textSearch: null,
        rangeSelect: this.props.past ? 25 : 0,
        pageOffset: 0
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname || this.props.profile?.selectedCompanyId !== prevProps.profile?.selectedCompanyId) {
            this.onRouteChanged();
        }
        EventBus.on('brandClick', () => {
            this.onRouteChanged();
        });
    }

    onRouteChanged = () => {
        this.setState({
            textSearchIsOpen: false,
            textSearch: null,
            rangeSelect: this.props.past ? 25 : 0,
            pageOffset: 0
        });
    }

    onChangeTextSearch = (newTextSearchIsOpen) => {
        this.setState({textSearchIsOpen: newTextSearchIsOpen, pageOffset: 0});
    };

    prepareEventItems = (items) => {
        let {past} = this.props;
        let {textSearch} = this.state;
        // filter if past
        items = items?.filter(item => past ? moment(item.date.end).isBefore(moment()) : moment(item.date.end).isSameOrAfter(moment()))
        // sort by date asc
        items = items?.sort((a, b) => new Date((past ? b : a).date.start) - new Date((past ? a : b).date.start));
        // filter by text search
        if (textSearch && textSearch.length > 0) items = items?.filter(item => !textSearch.split(" ").find(textSearchItem => !`${item.type}${item.title}${item.company ? item.company.name : ''}`.toLowerCase().includes(textSearchItem.toLowerCase())));
        // return items
        return items;
    };

    render() {
        const {events, past} = this.props;
        let eventItems = this.prepareEventItems(events.items);
        return (
            <div className="Meetings">
                <ContactWidgetLink/>
                {events.loaded && <Order/>}
                <div className="container-meetings">
                    {eventItems && eventItems.length > 0 ? (
                        eventItems.slice(this.state.pageOffset * this.state.rangeSelect, this.state.rangeSelect === 0 ? eventItems.length : (this.state.pageOffset + 1) * this.state.rangeSelect).map((event, eI) => (
                            <Meeting event={event} key={eI} past={past}
                                     hideDay={eI !== 0 && moment(eventItems[eI - 1].date.start).format('YYYYMMDD') === moment(event.date.start).format('YYYYMMDD')}/>
                        ))
                    ) : (
                        <div className="no-meetings container">
                            <div className="row">
                                <div className="col-4 col-md-5 col-lg-4 offset-lg-1 pl-lg-0">
                                    <h4 className="ml-1 mt-md-5">
                                        {events.loaded && <Translate id={`meetings.noMeetings`}/>}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {past && eventItems && eventItems.length > 0 ? (
                    <div className="container-bottom container">
                        <div className="row">
                            <div className="col-6 col-lg-4 offset-lg-1 pl-lg-0 d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
                                <MeetingsPaginateRangeSelect value={this.state.rangeSelect} onChange={(rangeSelect) => this.setState({rangeSelect, pageOffset: 0})}/>
                                <div className="pager">
                                    <div className="btn-pager">
                                        <button onClick={() => {window.scrollTo(0, 0); this.setState({pageOffset: this.state.pageOffset-1})}} className={`btn-prev d-lg-none ${this.state.pageOffset === 0 ? 'd-none' : 'd-inline-block'}`}>
                                            <img src={iconArrowLeft} alt=""/></button>
                                    </div>
                                    <div className="pager-helper">
                                        <Translate id="meetings.page"/>:
                                    </div>
                                    {Array.from(Array(Math.ceil(eventItems.length / (this.state.rangeSelect === 0 ? eventItems.length: this.state.rangeSelect))).keys()).map(pI => (
                                        <button key={pI} onClick={() => {window.scrollTo(0, 0); this.setState({pageOffset: pI})}} className={`${this.state.pageOffset === pI ? 'active' : ''}`}>
                                            {pI + 1}
                                        </button>
                                    ))}
                                    <div className="line d-none d-lg-inline-block" style={{width: 78 + 22 * this.state.pageOffset}}>
                                        <BorderWithDot/>
                                    </div>
                                    <div className="btn-pager">
                                        <button onClick={() => {window.scrollTo(0, 0); this.setState({pageOffset: this.state.pageOffset+1})}} className="btn-next d-inline-block d-lg-none">
                                            <img src={iconArrowRight} alt=""/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    events: state.events,
    profile: state.profile
});

export default connect(mapStateToProps, null)(withRouter(Meetings));

import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  slotContent: {
    color: '#39464f',
    paddingLeft: 55,
    paddingRight: 55,
  },
  tableHead: {
    fontSize: 10,
    lineHeight: 1.4,
    fontWeight: 900,
    flexDirection: 'row',
    marginTop: 12,
    marginBottom: 9,
    paddingLeft: 10,
    paddingRight: 10,
  },
  th: {
    flex: 1,
    textTransform: 'uppercase',
  },
  tableBody: {
    fontSize: 15,
    lineHeight: 1.25,
    fontWeight: 500,
  },
  evenRow: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#e6f2f4',
  },
  oddRow: {
    flexDirection: 'row',
    padding: 10,
  },
  td: {
    flex: 1,
  },
});

function ParticipantsTable({
  labels,
  filteredPersonsToShow,
}) {
  return filteredPersonsToShow.length > 0 && (
    <View style={styles.slotContent}>
      <View style={styles.tableHead}>
        <Text style={styles.th}>
          {labels['header.name']}
        </Text>
        <Text style={styles.th}>
          {labels['header.institute']}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {filteredPersonsToShow.map((person, sI) => (
          <View style={sI % 2 === 0 ? styles.evenRow : styles.oddRow} key={person.id}>
            <Text key="name" style={styles.td}>
              {person.name}
            </Text>
            <Text key="institution" style={styles.td}>
              {person.institution?.name}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

export default ParticipantsTable;

import {
    EVENTS_EVENT_UPLOAD_REMOVE_SUCCESS, EVENTS_EVENT_UPLOADS_LOAD_SUCCESS,
    EVENTS_LOAD_SUCCESS,
    EVENTS_UPDATE_EVENT_NOTIFICATIONS
} from "../../config/actions";

let defaultState = {
    loaded: false,
    items: []
};

export default (state = defaultState, action) => {
    switch(action.type) {
        case EVENTS_LOAD_SUCCESS:
            return {
                ...state,
                loaded: true,
                items: action.items
            };
        case EVENTS_UPDATE_EVENT_NOTIFICATIONS:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.appId === action.appId && item.itemId === action.itemId) {
                        return {
                            ...item,
                            notifications: action.notifications
                        };
                    } else {
                        return item;
                    }
                })
            }
        case EVENTS_EVENT_UPLOAD_REMOVE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.uploads?.find(u => u.itemId === action.itemId)) {
                        return {
                            ...item,
                            uploads: item.uploads.filter(u => u.itemId !== action.itemId)
                        }
                    }
                    return item;
                })
            }
        case EVENTS_EVENT_UPLOADS_LOAD_SUCCESS:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.appId === action.appId && item.itemId === action.itemId) {
                        return {
                            ...item,
                            uploads: action.uploads
                        };
                    } else {
                        return item;
                    }
                })
            }
        default:
            return state;
    }
}

import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {authorizationLogout} from "../../store/authorization/authorizationAction";
import {Translate} from "react-localize-redux";
import {NavLink} from "react-router-dom";
import {
    ROUTE_CONTACTS,
    ROUTE_DEFAULT,
    ROUTE_MEETINGS,
    ROUTE_PAST,
    ROUTE_PROFILE,
    ROUTE_PUBLICATIONS
} from "../../config/routes";
import history from "../../history";
import BorderWithDot from "../border/borderWithDot";
import {profileCompaniesLoadSuccess, profileSelectCompany} from "../../store/profile/profileAction";

class Menu extends React.Component {

    handleLogout = () => {
        this.props.authorizationLogout();
        this.props.profileCompaniesLoadSuccess(null);
        this.props.profileSelectCompany(null);
        history.push(ROUTE_DEFAULT);
    };


    render() {
        return (
            <Fragment>
                <div className="TopBar-Menu" >
                    <NavLink exact to={ROUTE_MEETINGS} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.meetings" options={{renderInnerHtml: true}}/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.meetings" options={{renderInnerHtml: true}}/></span>
                    </NavLink>
                    <NavLink exact to={ROUTE_PAST} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.past" options={{renderInnerHtml: true}}/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.past" options={{renderInnerHtml: true}}/></span>
                    </NavLink>
                    <NavLink exact to={ROUTE_CONTACTS} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.contacts" options={{renderInnerHtml: true}}/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.contacts" options={{renderInnerHtml: true}}/></span>
                    </NavLink>
                    <NavLink to={ROUTE_PUBLICATIONS} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.publications" options={{renderInnerHtml: true}}/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.publications" options={{renderInnerHtml: true}}/></span>
                    </NavLink>
                    <NavLink to={ROUTE_PROFILE} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.profile" options={{renderInnerHtml: true}}/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.profile" options={{renderInnerHtml: true}}/></span>
                    </NavLink>
                    <button onClick={this.handleLogout}>
                        <div className="mobileActive-border d-none"/>
                        <Translate id="topBar.menu.logout"/>
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default connect(null, {
    authorizationLogout,
    profileCompaniesLoadSuccess,
    profileSelectCompany
})(Menu);

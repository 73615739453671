export const AUTHORIZATION_LOGIN_SUCCESS = 'AUTHORIZATION_LOGIN_SUCCESS';
export const AUTHORIZATION_LOGOUT = 'AUTHORIZATION_LOGOUT';
export const AUTHORIZATION_SET_ACCESS_TOKEN = 'AUTHORIZATION_SET_ACCESS_TOKEN';

export const EVENTS_LOAD_SUCCESS = 'EVENTS_LOAD_SUCCESS';
export const EVENTS_UPDATE_EVENT_NOTIFICATIONS = 'EVENTS_UPDATE_EVENT_NOTIFICATIONS';
export const EVENTS_EVENT_UPLOAD_REMOVE_SUCCESS = 'EVENTS_EVENT_UPLOAD_REMOVE_SUCCESS';
export const EVENTS_EVENT_UPLOADS_LOAD_SUCCESS = 'EVENTS_EVENT_UPLOADS_LOAD_SUCCESS';

export const CONTACTS_LOAD_SUCCESS = 'CONTACTS_LOAD_SUCCESS';

export const PROFILE_LOAD_SUCCESS = 'PROFILE_LOAD_SUCCESS';
export const PROFILE_COMPANIES_LOAD_SUCCESS = 'PROFILE_COMPANIES_LOAD_SUCCESS';
export const PROFILE_SELECT_COMPANY = 'PROFILE_SELECT_COMPANY';
export const PROFILE_UPDATE_COMPANY_COLOR_SUCCESS = 'PROFILE_UPDATE_COMPANY_COLOR_SUCCESS';
export const PROFILE_DELETE_IMAGE_SUCCESS = 'PROFILE_DELETE_IMAGE_SUCCESS';

export const PUBLICATIONS_LOAD_SUCCESS = 'PUBLICATIONS_LOAD_SUCCESS';
import React from 'react';

function CheckButton ({label, checked, onChange}) {
    return (
        <button className={`CheckButton${checked ? ' checked' : ''}`} type="button" onClick={() => onChange(!checked)}>
            {label}
        </button>
    )
}

export default CheckButton;
import {
  PROFILE_COMPANIES_LOAD_SUCCESS, PROFILE_DELETE_IMAGE_SUCCESS,
  PROFILE_LOAD_SUCCESS,
  PROFILE_SELECT_COMPANY,
  PROFILE_UPDATE_COMPANY_COLOR_SUCCESS
} from "../../config/actions";
import ProfileApi from "../../api/ProfileApi";

export const profileLoadSuccess = (name, institution, email, itemId, kmb, coverage, image, company) => {
  return {
    type: PROFILE_LOAD_SUCCESS,
    name,
    institution,
    email,
    itemId,
    kmb,
    coverage,
    image,
    company
  }
};

export const profileCompaniesLoadSuccess = (companies) => {
  return {
    type: PROFILE_COMPANIES_LOAD_SUCCESS,
    companies
  }
};

export const profileSelectCompany = (companyId) => {
  return {
    type: PROFILE_SELECT_COMPANY,
    companyId
  }
}

export const profileUpdateCompanyColorSuccess = (color) => {
  return {
    type: PROFILE_UPDATE_COMPANY_COLOR_SUCCESS,
    color
  }
}

export const profileDeleteImageSuccess = () => {
  return {
    type: PROFILE_DELETE_IMAGE_SUCCESS
  }
}

export const profileUploadWaitForImage = (count = 1) => {
  if (count > 20) return;
  return dispatch => {
    ProfileApi.get().then(response => {
      if (response.status === 200 && response.data.success) {
        if (response.data.data?.image?.uuid) {
          dispatch(profileLoadSuccess(
              response.data.data.name,
              response.data.data.institution,
              response.data.data.email,
              response.data.data.itemId,
              response.data.data.kmb,
              response.data.data.coverage,
              response.data.data.image,
              response.data.data.company
          ))
        } else {
          setTimeout(() => {
            dispatch(profileUploadWaitForImage(count + 1))
          }, 3000);
        }
      }
    })
  }
}

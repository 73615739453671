import React, {useState} from 'react';
import moment from "moment";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {Collapse} from "react-collapse/lib/Collapse";
import gfx_logoBackDrop_xs from '../../../gfx/publications/Logobackdrop_Mobile.svg';
import gfx_logoBackDrop_md from '../../../gfx/publications/Logobackdrop_Tablet.svg';
import gfx_logoBackDrop_lg from '../../../gfx/publications/Logobackdrop_Desktop.svg';
import gfx_logoBackDrop_xl from '../../../gfx/publications/Logobackdrop_4K.svg';
import gfx_arrow from '../../../gfx/publications/arrow.svg';
import gfx_pdf from '../../../gfx/publications/pdf.svg';
import gfx_browser from '../../../gfx/publications/browser.svg';
import {useSelector} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {generatePath, Link} from "react-router-dom";
import DemandsButton from "./DemandsButton";
import fileDownload from 'js-file-download';
import PublicationsApi from "../../../api/PublicationsApi";
import {ROUTE_PUBLICATION_WEB_VIEW} from "../../../config/routes";
import IconWeb from "../../../gfx/publications/IconWeb";
import IconDownload from "../../../gfx/publications/IconDownload";

const Publication = ({publication, hideYear = false, activeLanguage}) => {
  const publicationsState = useSelector(state => state.publications);

  const [showDetails, setShowDetails] = useState(false);

  const handleDownloadPdf = (pdf, language) => {
    PublicationsApi.getPdf(pdf.uuid, language)
      .then(response => {
        fileDownload(response.data, pdf.name, pdf.mimeType);
      })
  }

  const renderFlag = (language) => (
    <span className={`publication-language fi fi-${language === 'en' ? 'gb' : 'de'} fis`}/>
  );

  return (
    <div className={`Publication${showDetails ? ' showDetails' : ''} publication-item-${publication.itemId}`}>
      <div className="container">
        <div className="row">
          <div className="col-2 col-md-1">
            {!hideYear && <div className="year">
              <h2>
                {moment(publication.releaseDate?.start).format('YYYY')}
              </h2>
              <BorderWithDot borderClass="border-with-dot-left"/>
            </div>}
          </div>
          <div className="col-4 col-md-5 col-lg-4 pl-0 d-flex">
            <div className="col-left">
              <div className="date d-flex align-items-center justify-content-center">
                {moment(publication.releaseDate?.start).format(activeLanguage.code === 'de' ? 'DD. MMMM' : 'DD MMMM')}
              </div>
              <Collapse isOpened={true}>
                <div className={`company-publication-info-container ${showDetails ? 'd-none' : 'd-flex'} d-md-flex`}>
                  <div className="company-publication-info-container-inner d-flex flex-grow-1">
                    <img src={gfx_logoBackDrop_xs} alt=""
                         className="logo-backdrop d-md-none"/>
                    <img src={gfx_logoBackDrop_md} alt=""
                         className="logo-backdrop d-none d-md-inline d-lg-none"/>
                    <img src={gfx_logoBackDrop_lg} alt=""
                         className="logo-backdrop d-none d-lg-inline d-xl-none"/>
                    <img src={gfx_logoBackDrop_xl} alt=""
                         className="logo-backdrop d-none d-xl-inline"/>
                    {publicationsState.company ? (
                      publicationsState.company.logo && publicationsState.company.logo.file && publicationsState.company.logo.file.id ? (
                        <img
                          src={`${process.env.REACT_APP_API_URL}image/${publicationsState.company.logo.file.id}`}
                          alt="" className="company-logo"/>
                      ) : null
                    ) : null}
                    <div className="demands d-flex align-items-center justify-content-center flex-grow-1 flex-column">
                      <div className="big">
                        {publication.numberOfDemands}
                      </div>
                      <Translate id={publication.numberOfDemands === 1 ? 'publication.numberOfDemandsSingle' : 'publication.numberOfDemands'}/>
                    </div>
                  </div>
                </div>
                <div className={`description ${showDetails ? 'd-block' : 'd-none'} d-md-none`}>
                  {publication.title}
                  <div className="downloadButtons">
                    {publication.downloads.pdf?.uuid ? (
                      <button onClick={() => handleDownloadPdf(publication.downloads.pdf, 'de')}>
                        {renderFlag('de')}
                        <IconDownload/>
                      </button>
                    ) : null}
                    {publication.downloads.web?.title ? (
                      <Link to={generatePath(ROUTE_PUBLICATION_WEB_VIEW, {id: publication.id})} target="_blank">
                        {renderFlag('de')}
                        <IconWeb/>
                      </Link>
                    ) : null}
                    {publication.downloads.de?.uuid ? (
                      <button onClick={() => handleDownloadPdf(publication.downloads.de, 'de')}>
                        {renderFlag('de')}
                        <IconDownload/>
                      </button>
                    ) : null}
                    {publication.downloads.en?.uuid ? (
                      <button onClick={() => handleDownloadPdf(publication.downloads.en, 'en')}>
                        {renderFlag('en')}
                        <IconDownload/>
                      </button>
                    ) : null}
                  </div>
                </div>
              </Collapse>
              <div className="bottom-buttons d-lg-none">
                <DemandsButton publication={publication}/>
                <button className="details-toggle d-md-none" onClick={() => setShowDetails(!showDetails)}>
                  <img src={gfx_arrow} alt="" className={showDetails ? 'rotate-180' : ''}/>
                  <Translate id={`publication.${showDetails ? 'hideDetails' : 'showDetails'}`}/>
                </button>
              </div>
            </div>
            <div className="d-none col-description-right d-md-block">
              <div className="description d-flex flex-column">
                <div className="description-border-bottom d-none d-lg-block"/>
                <DemandsButton publication={publication} className="DemandsButton DemandsButton-bottom-right d-none d-lg-flex"/>
                <BorderWithDot borderClass="border-with-dot-darkturkuoise border-with-dot-top"/>
                <div className="title flex-grow-1">
                  {publication.title}
                </div>
                <div className="downloadButtons">
                  {publication.downloads.pdf?.uuid ? (
                    <button onClick={() => handleDownloadPdf(publication.downloads.pdf, 'de')}>
                      {renderFlag('de')}
                      <IconDownload/>
                    </button>
                  ) : null}
                  {publication.downloads.web?.title ? (
                    <Link to={generatePath(ROUTE_PUBLICATION_WEB_VIEW, {id: publication.id})} target="_blank">
                      {renderFlag('de')}
                      <IconWeb/>
                    </Link>
                  ) : null}
                  {publication.downloads.de?.uuid ? (
                    <button onClick={() => handleDownloadPdf(publication.downloads.de, 'de')}>
                      {renderFlag('de')}
                      <IconDownload/>
                    </button>
                  ) : null}
                  {publication.downloads.en?.uuid ? (
                    <button onClick={() => handleDownloadPdf(publication.downloads.en, 'en')}>
                      {renderFlag('en')}
                      <IconDownload/>
                    </button>
                  ) : null}
                </div>
                <BorderWithDot borderClass="border-with-dot-darkturkuoise d-lg-none"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withLocalize(Publication);
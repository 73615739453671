import React from 'react';
import {connect} from "react-redux";
import {authorizationLoginSuccess, authorizationLogout} from "../../../store/authorization/authorizationAction";
import AuthorizationApi from "../../../api/AuthorizationApi";
import history from "../../../history";
import {profileCompaniesLoadSuccess, profileSelectCompany} from "../../../store/profile/profileAction";
import {withLocalize} from "react-localize-redux";

class TokenLogin extends React.Component {

    componentDidMount() {
        const token = this.props.match.params.token;
        this.props.authorizationLogout();
        AuthorizationApi.login(token, 'tokenLogin')
            .then((response) => {
                if (response.status === 200) {
                    let {access_token, username, roles, lang} = response.data;
                    if (roles?.includes('ROLE_USER')) {
                        if (lang) this.props.setActiveLanguage(lang);
                        this.props.profileSelectCompany(null);
                        this.props.profileCompaniesLoadSuccess(null);
                        this.props.authorizationLoginSuccess(access_token, username, roles, false);
                    }
                    history.push("/");
                } else {
                    history.push("/");
                }
            })
            .catch(() => {
                history.push("/");
            });
    }

    render() {
        return (
            <div className="TokenLogin"/>
        )
    }
}

export default connect(null, {
    authorizationLoginSuccess, authorizationLogout, profileSelectCompany, profileCompaniesLoadSuccess
})(withLocalize(TokenLogin));

import React from 'react';

const PrintIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.397" height="23.395" viewBox="0 0 23.397 23.395">
        <g id="qLBdy3" transform="translate(-2838.899 -915.655)">
            <g id="Gruppe_4508" data-name="Gruppe 4508" transform="translate(2838.899 915.655)">
                <path id="Pfad_2045" data-name="Pfad 2045" d="M2858.228,933.887c0,1.01,0,1.984,0,2.958a2.063,2.063,0,0,1-2.194,2.2h-10.871a2.062,2.062,0,0,1-2.195-2.2c0-.974,0-1.948,0-2.959-.224,0-.441.006-.658,0a3.382,3.382,0,0,1-2.976-1.766,3.14,3.14,0,0,1-.431-1.613c0-1.858-.009-3.715,0-5.573a3.422,3.422,0,0,1,3.379-3.382c.219,0,.438,0,.684,0v-.261c0-.731,0-1.462,0-2.193a3.421,3.421,0,0,1,3.438-3.439q4.18-.007,8.359,0a3.421,3.421,0,0,1,3.463,3.461c0,.8,0,1.6,0,2.432.211,0,.413,0,.615,0a3.417,3.417,0,0,1,3.38,2.748,3.572,3.572,0,0,1,.069.725q.009,2.695,0,5.39a3.426,3.426,0,0,1-3.475,3.473Zm.006-1.371c.21,0,.4,0,.588,0a2.053,2.053,0,0,0,2.1-2.092q.007-2.706,0-5.412a2.044,2.044,0,0,0-2.08-2.088q-8.244-.006-16.488,0a2.045,2.045,0,0,0-2.08,2.087c-.005,1.8,0,3.608,0,5.412a2.126,2.126,0,0,0,2.684,2.059v-2.251c-.146,0-.282.005-.417,0a.683.683,0,0,1-.127-1.351,1.516,1.516,0,0,1,.317-.018h13.748c.716,0,1.431,0,2.147,0a.684.684,0,1,1,.009,1.368c-.132,0-.266,0-.4,0Zm-1.377-2.268h-12.518v.242q0,3.185,0,6.37c0,.562.258.818.825.818h10.868c.564,0,.824-.257.824-.82v-6.61Zm0-8.707c0-.813,0-1.6,0-2.395a2.055,2.055,0,0,0-2.121-2.118c-.632,0-1.264,0-1.9,0-2.147,0-4.294,0-6.441,0a1.988,1.988,0,0,0-2.011,1.7,15.38,15.38,0,0,0-.047,1.777c0,.34,0,.68,0,1.03Z" transform="translate(-2838.899 -915.654)" fill="currentColor"/>
                <path id="Pfad_2046" data-name="Pfad 2046" d="M2873.222,1013.538c-.35,0-.7,0-1.049,0a.687.687,0,1,1,.01-1.37q1.037,0,2.074,0a.688.688,0,1,1-.009,1.37C2873.906,1013.54,2873.564,1013.538,2873.222,1013.538Z" transform="translate(-2868.467 -1003.347)" fill="currentColor"/>
                <path id="Pfad_2047" data-name="Pfad 2047" d="M2941.94,1093.543c-.578,0-1.156,0-1.734,0a.689.689,0,1,1-.008-1.371q1.757,0,3.513,0a.688.688,0,1,1,.008,1.37C2943.126,1093.545,2942.533,1093.543,2941.94,1093.543Z" transform="translate(-2930.253 -1076.042)" fill="currentColor"/>
                <path id="Pfad_2048" data-name="Pfad 2048" d="M2941.953,1124.173c.585,0,1.171,0,1.757,0a.688.688,0,1,1,.011,1.37q-1.768,0-3.536,0a.688.688,0,1,1,.012-1.37C2940.782,1124.172,2941.368,1124.173,2941.953,1124.173Z" transform="translate(-2930.253 -1105.12)" fill="currentColor"/>
            </g>
        </g>
    </svg>
);

export default PrintIcon;
import React from 'react';

const GfxBackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.202" height="6.538" viewBox="0 0 29.202 6.538">
    <g id="Gruppe_3681" data-name="Gruppe 3681" transform="translate(-17.298 -17.025)">
      <line id="Linie_285" data-name="Linie 285" x2="26.091" transform="translate(19.408 20.319)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
      <path id="Pfad_1160" data-name="Pfad 1160" d="M1317.063,418.766a3.269,3.269,0,1,0-3.269-3.269,3.246,3.246,0,0,0,3.269,3.269Z" transform="translate(-1296.496 -395.203)" fill="currentColor"/>
    </g>
  </svg>
);

export default GfxBackIcon;
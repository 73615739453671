import React, {Fragment, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import iconTeilnehmer from "../../../gfx/teilnehmer.svg";
import iconTeilnehmerWhite from "../../../gfx/teilnehmer-white.svg";
import iconTeilnehmerTurkuoise from "../../../gfx/teilnehmer-turquoise.svg";
import iconUhrzeit from "../../../gfx/uhrzeit.svg";
import iconUhrzeitWhite from "../../../gfx/uhrzeit-white.svg";
import iconUhrzeitTurkuoise from "../../../gfx/uhrzeit-turquoise.svg";
import iconPosition from "../../../gfx/position.svg";
import iconPositionWhite from "../../../gfx/position-white.svg";
import iconPositionTurkuoise from "../../../gfx/position-turquoise.svg";
import iconGruppentermin from "../../../gfx/gruppentermin.svg";
import iconGruppenterminWhite from "../../../gfx/gruppentermin-white.svg";
import iconGruppenterminTurkuoise from "../../../gfx/gruppentermin-turquoise.svg";
import iconEinzeltermin from "../../../gfx/einzeltermin.svg";
import iconEinzelterminWhite from "../../../gfx/einzeltermin-white.svg";
import iconEinzelterminTurkuoise from "../../../gfx/einzeltermin-turquoise.svg";
import iconWebWhite from "../../../gfx/web_icon_white.svg";
import iconArrow from "../../../gfx/arrow-white.svg";
import iconAuswahlTurquoise from "../../../gfx/auswahl-turquoise.svg";
import iconAuswahlWhite from "../../../gfx/auswahl-white.svg";
import JoinButton from "./JoinButton";
import ReactTooltip from "react-tooltip";
import InfoButton from "./InfoButton";
import FilesButton from "./FilesButton";
import ParticipantsButton from "./ParticipantsButton";
import gfx_logoBackDrop_xs from '../../../gfx/meetings/upcoming/Logobackdrop_Mobile.svg';
import gfx_logoBackDrop_md from '../../../gfx/meetings/upcoming/Logobackdrop_Tablet.svg';
import gfx_logoBackDrop_md_full from '../../../gfx/meetings/upcoming/Logobackdrop_Tablet_LARGE.svg';
import gfx_logoBackDrop_lg from '../../../gfx/meetings/upcoming/Logobackdrop_Desktop.svg';
import gfx_logoBackDrop_xl from '../../../gfx/meetings/upcoming/Logobackdrop_4K.svg';
import gfx_logoBackDrop_active_xs from '../../../gfx/meetings/upcoming/Logobackdrop_ActiveEvent_Mobile.svg';
import gfx_logoBackDrop_active_md from '../../../gfx/meetings/upcoming/Logobackdrop_ActiveEvent_Tablet.svg';
import gfx_logoBackDrop_active_md_full from '../../../gfx/meetings/upcoming/Logobackdrop_ActiveEvent_Tablet_LARGE.svg';
import gfx_logoBackDrop_active_lg from '../../../gfx/meetings/upcoming/Logobackdrop_ActiveEvent_Desktop.svg';
import gfx_logoBackDrop_active_xl from '../../../gfx/meetings/upcoming/Logobackdrop_ActiveEvent_4K.svg';
import gfx_logoBackDrop_past_xs from '../../../gfx/meetings/past/Logobackdrop_mobile.svg';
import gfx_logoBackDrop_past_md from '../../../gfx/meetings/past/Logobackdrop_Tablet.svg';
import gfx_logoBackDrop_past_lg from '../../../gfx/meetings/past/Logobackdrop_Dektop.svg';
import gfx_logoBackDrop_past_xl from '../../../gfx/meetings/past/Logobackdrop_4K.svg';
import useInView from "react-cool-inview";
import DownloadIcsButton from "./DownloadIcsButton";
import NotificationsButton from "./NotificationsButton";
import FeedbackButton from "./FeedbackButton";
import LandingpageButton from "./LandingpageButton";
import ReplayButton from "./ReplayButton";

const Meeting = ({hideDay, event, past}) => {

    const [toggleDetails, setToggleDetails] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [startCount, setStartCount] = useState(false);
    const [currentCount, setCurrentCount] = useState(0);

    const countInterval = useRef(null);

    useEffect(() => {
        if (event && event.date) {
            setIsActive(moment(event.date.start) <= moment() && moment(event.date.end) > moment());
        }
    }, [event])

    const incrementCount = () => {
        setCurrentCount(c => {
            if (c < event.numberOfParticipants) {
                return c+1;
            } else {
                if (countInterval.current) clearInterval(countInterval.current);
                return event.numberOfParticipants;
            }
        });
    }

    useEffect(() => {
        if (startCount && currentCount < event.numberOfParticipants) {
            countInterval.current = setInterval(incrementCount, 1000/event.numberOfParticipants)
        }
    }, [startCount])

    const {observe} = useInView({
        threshold: 0.6,
        onEnter: ({unobserve}) => {
            unobserve();
            if (!past) setStartCount(true);
        },
    });


    return (
        <div
            className={`Meeting ${isActive ? 'is-active' : ''} ${event.userIsParticipating ? 'is-participating' : ''} 
                 ${event.userIsDenied ? 'got-canceled' : ''} ${event.userIsApplying ? 'is-requested' : ''}
                 ${toggleDetails ? 'open' : ''}`}>
            <div className="container">
                <div className="row flex-nowrap">
                    <div className="col-left col-2 col-md-1">
                        <div className="day">
                            {!hideDay &&
                            <Fragment>
                                <div className="top">
                                    {moment(event.date.start).format('dddd')}
                                </div>
                                <div className="middle">{moment(event.date.start).format('DD')}
                                    <BorderWithDot borderClass="border-with-dot-left border-with-dot-black"/>
                                </div>
                                <div className="bottom">
                                    {past ? moment(event.date.start).format('MMMM YYYY') : moment(event.date.start).format('MMMM')}
                                </div>
                            </Fragment>
                            }
                        </div>
                        <div className="info-box">
                            {isActive &&
                            <Fragment>
                                    <span className="text-is-active text-uppercase d-flex">
                                        <img src={iconUhrzeitWhite} alt=""/>
                                        <Translate id="meeting.status.isActive"/>
                                    </span>
                            </Fragment>
                            }
                            {past && event.feedbackLink && <FeedbackButton meeting={event} extraClassName="d-none d-lg-flex mt-2"/>}
                            {past && !!event.zoomMeeting?.downloadLink && <ReplayButton evnt={event} extraClassName="d-none d-lg-flex mt-2"/>}
                            {!past && <JoinButton className="btn-infobox btn-dial d-none d-lg-flex" event={event}/>}
                        </div>
                    </div>
                    <div className="col-container col-container-meeting col-4 col-md-5 col-lg-4 pl-0">
                        <div className="meeting-start d-md-none">
                            {event && event.date && event.date.start ? (
                                <span>{moment(event.date.start).format('LT')}</span>
                            ) : null}
                        </div>
                        <div className="container-card">
                            <div className="col-middle col-middle-width">
                                <div className={`type${past ? ' gray-bg' : ''}`}>
                                    {event.online ? (
                                        <img alt="World-Icon" src={iconWebWhite}/>
                                    ) : null}
                                    <h3><Translate id={`meeting.type.${event.type}`}/></h3>
                                </div>
                                <div className={`logo-container d-flex d-md-flex${((!past && event.type !== 'roadshow') || (past)) ? ' with_button' : ''}${past ? ' past' : ''}`} ref={observe}>
                                    {past ? (
                                        <Fragment>
                                            <img src={gfx_logoBackDrop_past_xs} alt=""
                                                 className="logo-backdrop d-md-none"/>
                                            <img src={gfx_logoBackDrop_past_md} alt=""
                                                 className="logo-backdrop d-none d-md-inline d-lg-none"/>
                                            <img src={gfx_logoBackDrop_past_lg} alt=""
                                                 className="logo-backdrop d-none d-lg-inline d-xl-none"/>
                                            <img src={gfx_logoBackDrop_past_xl} alt=""
                                                 className="logo-backdrop d-none d-xl-inline"/>
                                            {event.company.logo?.file?.id ? (
                                                <img src={`${process.env.REACT_APP_API_URL}image/${event.company.logo.file.id}`}
                                                     alt="" className="company-logo"/>
                                            ) : null}
                                            <div
                                                className={`participantsAmount text-center with-feedback-link`}>
                                                <span className="big">{event.numberOfParticipants}</span>
                                                <div className="d-block"/>
                                                <Translate id="meeting.past.numberOfParticipants"/>
                                            </div>
                                            {past && <ParticipantsButton event={event} disabled={event.numberOfParticipants < 1} past={true} className="d-none d-md-block d-lg-none"/>}
                                            {/*event.feedbackLink && (
                                                <FeedbackButton meeting={event} extraClassName="d-none d-md-block d-lg-none"/>
                                            )*/}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <img src={isActive ? gfx_logoBackDrop_xs : gfx_logoBackDrop_active_xs} alt=""
                                                 className="logo-backdrop d-md-none"/>
                                            <img src={isActive ? (event.type !== 'roadshow' ? gfx_logoBackDrop_md : gfx_logoBackDrop_md_full) : (event.type !== 'roadshow' ? gfx_logoBackDrop_active_md : gfx_logoBackDrop_active_md_full)} alt=""
                                                 className="logo-backdrop d-none d-md-inline d-lg-none"/>
                                            <img src={isActive ? gfx_logoBackDrop_lg : gfx_logoBackDrop_active_lg} alt=""
                                                 className="logo-backdrop d-none d-lg-inline d-xl-none"/>
                                            <img src={isActive ? gfx_logoBackDrop_xl : gfx_logoBackDrop_active_xl} alt=""
                                                 className="logo-backdrop d-none d-xl-inline"/>
                                            {event.company ? (
                                                event.company.logo && event.company.logo.file && event.company.logo.file.id ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_API_URL}image/${event.company.logo.file.id}`}
                                                        alt="" className="company-logo"/>
                                                ) : null
                                            ) : null}
                                            <div
                                                className={`participantsAmount text-center${event.type !== 'roadshow' ? ' with-feedback-link' : ''}`}>
                                                <span className="big">{currentCount}</span>
                                                <div className="d-block"/>
                                                <Translate id={event.numberOfParticipants === 1 ? 'meeting.numberOfParticipantsSingle' : 'meeting.numberOfParticipants'}/>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            <div className="col-right d-flex flex-wrap">
                                <BorderWithDot borderClass="border-with-dot-turkuoiseborder d-none d-md-flex"/>
                                <div className="title-container">
                                    <div className="title">
                                        <h3>{event.title}</h3>
                                    </div>
                                    {!isActive && !past && <div className="notifications-container d-none d-lg-block ml-2">
                                        <NotificationsButton evnt={event}/>
                                    </div>}
                                </div>
                                <div className={`details`}>
                                    <div className="description text-uppercase d-flex flex-column flex-lg-row">
                                        {event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? (
                                            <span className="mySelection">
                                                    <img className="icon d-md-none" src={iconAuswahlTurquoise} alt=""/>
                                                {isActive ? (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconAuswahlWhite} alt=""/>
                                                ) : (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconAuswahlTurquoise} alt=""/>
                                                )}
                                                <Translate
                                                    id={`meeting.mySelection.${event?.slots?.find(slot => (slot.userIsParticipating || slot.userIsApplying))?.groupMeeting ? 'group' : 'single'}`}/>
                                                </span>
                                        ) : (
                                            <Fragment>
                                                {event.hasGroupmeetings ? (
                                                    <span className="d-flex align-items-center mr-lg-4">
                                                    <img className="icon d-md-none" src={iconGruppentermin} alt=""/>
                                                        {isActive ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconGruppenterminWhite} alt=""/>
                                                        ) : event.closed || event.userIsParticipating ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconGruppenterminTurkuoise} alt=""/>
                                                        ) : (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconGruppentermin} alt=""/>
                                                        )}
                                                        <Translate id="meeting.groupMeetings"/>
                                                </span>
                                                ) : null}
                                                {event.hasOneOnOne ? (
                                                    <span>
                                                    <img className="icon d-md-none" src={iconEinzeltermin} alt=""/>
                                                        {isActive ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconEinzelterminWhite} alt=""/>
                                                        ) : event.closed || event.userIsParticipating ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconEinzelterminTurkuoise} alt=""/>
                                                        ) : (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconEinzeltermin} alt=""/>
                                                        )}
                                                        <Translate id="meeting.oneOnOns"/>
                                                </span>
                                                ) : null}
                                            </Fragment>
                                        )}
                                    </div>
                                    <div className="box d-flex flex-column flex-md-row">
                                        <div className="left flex-grow-1 d-flex flex-wrap mt-auto flex-column flex-md-row">
                                            <span className="date mr-4"
                                                  data-tip={event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? 'React-tooltip' : false}
                                                  data-for={event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? `meeting-date-tooltip-${event.itemId}` : null}>
                                                <img className="icon d-md-none" src={iconUhrzeit} alt=""/>
                                                {isActive ? (
                                                  <img className="icon d-none d-md-inline-block"
                                                       src={iconUhrzeitWhite} alt=""/>
                                                ) : event.closed || event.userIsParticipating ? (
                                                  <img className="icon d-none d-md-inline-block"
                                                       src={iconUhrzeitTurkuoise} alt=""/>
                                                ) : (
                                                  <img className="icon d-none d-md-inline-block" src={iconUhrzeit}
                                                       alt=""/>
                                                )}
                                                <Translate
                                                  id="meeting.from"/> {moment(event.date.start).format('LT')} <br/>
                                                <Translate id="meeting.until"/> {moment(event.date.end).format('LT')}
                                                {event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? (
                                                  <ReactTooltip id={`meeting-date-tooltip-${event.itemId}`}>
                                                      <Translate id="meeting.date.tooltip.roadshow"/>
                                                  </ReactTooltip>
                                                ) : null}
                                            </span>
                                            {(event.location && event.location.formatted) || event.online ? (
                                              <span className="location">
                                                <img className="icon d-md-none" src={iconPosition} alt=""/>
                                                  {isActive ? (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconPositionWhite} alt=""/>
                                                  ) : event.closed || event.userIsParticipating ? (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconPositionTurkuoise} alt=""/>
                                                  ) : (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconPosition}
                                                         alt=""/>
                                                  )}
                                                  {event.online ? (
                                                    <Translate id="meeting.location.online"/>
                                                  ) : event.location && event.location.formatted ? (
                                                    event.location.formatted
                                                  ) : null}
                                            </span>
                                            ) : null}
                                            <span className="representatives">
                                                    {event.representatives ? (
                                                      <Fragment>
                                                          <img className="icon d-md-none" src={iconTeilnehmer}
                                                               alt=""/>
                                                          {isActive ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconTeilnehmerWhite} alt=""/>
                                                          ) : event.closed || event.userIsParticipating ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconTeilnehmerTurkuoise} alt=""/>
                                                          ) : (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconTeilnehmer} alt=""/>
                                                          )}
                                                          {event.representatives}
                                                      </Fragment>
                                                    ) : null}
                                                </span>
                                            {(!isActive && !past) && <div className="notifications-download-ics-container d-md-none">
                                                <NotificationsButton evnt={event}/>
                                                <DownloadIcsButton evnt={event}/>
                                                {event.landingPageTokenLink && <LandingpageButton evnt={event}/>}
                                            </div>}
                                        </div>
                                        {(event.files || event.teaser || event.feedbackLink || !past) && (
                                          <div className="d-md-none mobile-details-bottom">
                                              {past ? (
                                                <div className="past-icon-button-container">
                                                    {(event.feedbackLink) &&
                                                      <div
                                                        className={`feedbackLink-container${!event.zoomMeeting?.downloadLink ? ' wider' : ''}`}>
                                                          <FeedbackButton meeting={event} extraClassName="d-md-none"/>
                                                      </div>
                                                    }
                                                    {!!event.zoomMeeting?.downloadLink &&
                                                      <div
                                                        className={`replayButton-container${!event.feedbackLink ? ' wider' : ''}`}>
                                                          <ReplayButton evnt={event} extraClassName="d-md-none"/>
                                                      </div>
                                                    }
                                                </div>
                                              ) : null}
                                              {!past && <JoinButton className="btn-dial" event={event}/>}
                                              <div className="files">
                                                  {(event.uploads?.length > 0 || !past) &&
                                                    <FilesButton evnt={event} past={past}/>}
                                              </div>
                                              <div className="teaser">
                                                  {event.teaser && (
                                                    <InfoButton event={event}/>
                                                  )}
                                              </div>
                                          </div>
                                        )}
                                    </div>
                                </div>
                                <BorderWithDot borderClass="border-with-dot-turkuoise bwd-bottom d-none d-md-flex"/>
                                <div className="d-none d-md-block d-lg-none files-container-md">
                                    {!past && <FilesButton evnt={event} past={past}/>}
                                    {(!isActive && !past) && <div className="notifications-download-ics-container d-inline-flex align-items-center">
                                        <NotificationsButton evnt={event}/>
                                        <DownloadIcsButton evnt={event}/>
                                        {event.landingPageTokenLink && <LandingpageButton evnt={event}/>}
                                    </div>}
                                </div>
                            </div>
                            <ParticipantsButton event={event} className="d-none d-lg-inline-block" disabled={event.numberOfParticipants < 1} past={past}/>
                            <div className={`mobileButtons-container col-middle-width d-lg-none${past ? ' past' : ''}`}>
                                <ParticipantsButton event={event} disabled={event.numberOfParticipants < 1} past={{past}} className={`${past ? 'd-md-none' : ''}`}/>

                                <button onClick={() => setToggleDetails(cv => !cv)}
                                        className="btn-details d-md-none">
                                    <img src={iconArrow} className={toggleDetails ? 'rotate' : ''}
                                         alt=""/>
                                    <Translate id="meeting.details"/>
                                </button>

                                {!past && <JoinButton className="d-none d-md-inline-block btn-dial" event={event}/>}
                                <div className="past-md-bottom-buttons d-none d-md-block">
                                    {(past && event.feedbackLink) && <FeedbackButton meeting={event} extraClassName="d-none d-md-inline-block"/>}
                                    {(past && !!event.zoomMeeting?.downloadLink) && <ReplayButton evnt={event} extraClassName="d-none d-md-inline-block"/>}
                                    {(event.uploads?.length > 0 && past) && <FilesButton className="d-none d-md-inline-block" evnt={event} past={past}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 d-none d-lg-flex pl-0 files-container-lg">
                        {!isActive && !past && <div className="download-ics-container d-none d-lg-inline-block align-self-start">
                            <DownloadIcsButton evnt={event}/>
                            {event.landingPageTokenLink && <LandingpageButton evnt={event}/>}
                        </div>}
                        <div>
                            {(event.uploads?.length > 0 || !past) && <FilesButton evnt={event} past={past}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Meeting;

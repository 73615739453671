import React from 'react';
import {
  Document, Font, Page, StyleSheet, Text,
} from '@react-pdf/renderer';
import moment from 'moment';
import RoadshowSlot from './RoadshowSlot';
import ParticipantsTable from './ParticipantsTable';

Font.register({
  family: 'halyard-text',
  fonts: [
    {
      fontWeight: 500,
      src: 'https://use.typekit.net/af/7c9acc/00000000000000007735adc8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3',
    }, {
      fontWeight: 600,
      src: 'https://use.typekit.net/af/2ed20c/00000000000000007735add1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3',
    }, {
      fontWeight: 900,
      src: 'https://use.typekit.net/af/a6b64c/00000000000000007735adda/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3',
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    fontFamily: 'halyard-text',
    fontSize: 12,
    paddingBottom: 56,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 20,
    right: 55,
    color: '#3ba6af',
  },
  headline: {
    paddingTop: 47,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: 1.2,
    paddingLeft: 55,
    paddingRight: 55,
    marginBottom: 48,
    color: '#3ba6af',
  },
});

function EventSchedule({
  event,
  personsToShow,
  labels,
}) {
  const sortedPersonsToShow = personsToShow.sort((a, b) => a.name?.localeCompare(b.name));
  const isRoadshow = event.type === 'roadshow';
  const sortedSlots = isRoadshow ? event?.slots?.sort((a, b) => (
    moment(a.date.start)
      .isBefore(moment(b.date.start)) ? -1 : 1
  )) : [];
  return (
    <Document title={event.title}>
      <Page
        size="A4"
        style={styles.page}
      >
        <Text fixed style={styles.headline}>
          {event.title}
        </Text>
        {isRoadshow ? sortedSlots.map((slot) => (
          <RoadshowSlot
            key={`slot-${slot.id}`}
            slot={slot}
            sortedPersonsToShow={sortedPersonsToShow}
            labels={labels}
          />
        )) : (
          <ParticipantsTable
            filteredPersonsToShow={sortedPersonsToShow}
            labels={labels}
          />
        )}
        <Text
          style={styles.pageNumber}
          render={({
            pageNumber,
            totalPages,
          }) => (
            `${pageNumber} / ${totalPages}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
}

export default EventSchedule;

import React, { Fragment } from 'react';
import {
  matchPath, Route, Switch, withRouter,
} from 'react-router';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import Unauthorized from './unauthorized';
import Legals from './legals';
import FooterMenu from '../elements/footerMenu';
import {
  ROUTE_CONTACTS_PRINT,
  ROUTE_DATA_PRIVACY,
  ROUTE_ERROR,
  ROUTE_IMPRINT,
  ROUTE_PUBLICATION_LOGIN,
  ROUTE_PUBLICATION_WEB_VIEW,
  ROUTE_TOKEN_LOGIN,
} from '../config/routes';
import globalTranslations from '../translations/global';
import 'react-image-crop/dist/ReactCrop.css';
import '../theme/theme.scss';
import TopBar from '../elements/topBar';
import Authorized from './authorized';
import AxiosHelper from '../config/axiosHelper';
import 'moment/locale/de';
import ChooseCompany from './chooseCompany';
import TokenLogin from './unauthorized/tokenLogin';
import PublicationLogin from './unauthorized/publicationLogin';
import Error from './error';

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

const longDateFormats = {
  de: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    LL: 'DD.MM.YY',
    LLL: 'DD.MM.YYYY',
  },
  en: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    LL: 'DD/MM/YY',
    LLL: 'DD/MM/YYYY',
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);

    const languageFromStore = this.props.localize?.languages?.find((l) => l.active)?.code;
    const userLang = navigator.language || navigator.userLanguage;
    const defaultLanguage = languageFromStore || (userLang === 'de-DE' ? 'de' : 'en');

    this.props.initialize({
      languages: [
        { name: 'German', code: 'de' },
        { name: 'English', code: 'en' },
      ],
      translations: globalTranslations,
      options: { renderToStaticMarkup, onMissingTranslation, defaultLanguage },
    });
    this.props.addTranslation(globalTranslations);
    AxiosHelper.init();
    moment.locale(defaultLanguage, {
      longDateFormat: longDateFormats[defaultLanguage],
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeLanguage?.code !== this.props.activeLanguage?.code) {
      moment.locale(this.props.activeLanguage?.code, {
        longDateFormat: longDateFormats[this.props.activeLanguage?.code],
      });
    }
  }

  isLegalsPage = () => [ROUTE_DATA_PRIVACY, ROUTE_IMPRINT].includes(this.props.location.pathname);

  isPublicationWebView = () => matchPath(this.props.location.pathname, {
    path: ROUTE_PUBLICATION_WEB_VIEW,
    exact: true,
    strict: false,
  });

  isContactsPrint = () => matchPath(this.props.location.pathname, {
    path: ROUTE_CONTACTS_PRINT,
    exact: true,
    strict: false,
  });

  render() {
    const { authorization, profile } = this.props;

    const authorized = !!authorization.bearerToken;
    const legalsPage = this.isLegalsPage();
    const publicationWebView = this.isPublicationWebView();
    const contactsPrint = this.isContactsPrint();
    const companySelected = profile.selectedCompanyId;

    return (
      <IntlProvider locale={this.props.activeLanguage?.code ?? 'en'}>
        <div className="App">
          <Switch>
            <Route exact path={ROUTE_ERROR} component={Error} />
            <Route exact path={ROUTE_TOKEN_LOGIN} component={TokenLogin} />
            <Route exact path={ROUTE_PUBLICATION_LOGIN} component={PublicationLogin} />
            <Route render={() => (
              <>
                {!publicationWebView && !(contactsPrint) && (legalsPage || (authorized && companySelected)) ? (
                  <TopBar />
                ) : null}
                {legalsPage ? (
                  <Legals />
                ) : (
                  authorized ? (
                    companySelected ? (
                      <Authorized />
                    ) : (
                      <ChooseCompany />
                    )
                  ) : (
                    <Unauthorized />
                  )
                )}
              </>
            )}
            />
          </Switch>
          {!contactsPrint ? (
            <FooterMenu companySelected={companySelected} authorized={authorized} />
          ) : null}
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  authorization: state.authorization,
  profile: state.profile,
  localize: state.localize,
});

export default withLocalize(connect(mapStateToProps, null)(withRouter(App)));

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ProfileApi from "../../api/ProfileApi";
import {profileCompaniesLoadSuccess, profileSelectCompany} from "../../store/profile/profileAction";
import {Translate} from "react-localize-redux";

const ChooseCompany = () => {
  const profileState = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const [companiesToSelect, setCompaniesToSelect] = useState(null);

  useEffect(() => {
    if (!profileState.companies) {
      ProfileApi.getCompanies()
        .then(response => {
          dispatch(profileCompaniesLoadSuccess(response.data?.data));
        })
    } else {
      if (!profileState.selectedCompanyId) {
        if (profileState.companies?.length > 0) {
          if (profileState.companies?.length === 1) {
            dispatch(profileSelectCompany(profileState.companies[0]?.company?.id));
          } else {
            setCompaniesToSelect(profileState.companies);
          }
        }
      }
    }
  }, [profileState.companies, profileState.selectedCompanyId]);

  return <div className="ChooseCompany">
    {companiesToSelect ? (
      <div>
        <h4>
          <Translate id="chooseCompany.intro" options={{renderInnerHtml: true}}/>
        </h4>
        {companiesToSelect.sort((a, b) => a.company?.name?.localeCompare(b.company?.name)).map((company, cI) => (
          <div className="companyToSelect" key={cI}>
            <button className="btn btn-primary" onClick={() => dispatch(profileSelectCompany(company.company?.id))}>
              {company.company?.name}
            </button>
          </div>
        ))}
      </div>
    ) : (
      <div className="text-center">Dieser Account ist keinem Emittenten zugeordnet.</div>
    )}
  </div>
}

export default ChooseCompany;
import {CONTACTS_LOAD_SUCCESS} from "../../config/actions";

let defaultState = {
    loaded: false,
    items: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CONTACTS_LOAD_SUCCESS:
            return {
                ...state,
                loaded: true,
                items: action.items
            };
        default:
            return state;
    }
}
import React, {Fragment} from 'react';
import {Translate} from "react-localize-redux";

const ParticipantsNoParticipants = ({hasFilters}) => {
    return (
        <div className="noParticipants">
            {!hasFilters ? (
                <Translate id="meeting.participants.dialog.noParticipants"/>
            ) : (
                <Translate id={`meeting.participants.dialog.noParticipants_filtered`}/>
            )}
        </div>
    )
}

export default ParticipantsNoParticipants;
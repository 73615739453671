import React, {Fragment} from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {authorizationLogout} from "../../../store/authorization/authorizationAction";
import history from '../../../history';
import {ROUTE_FORGOT_PASSWORD} from "../../../config/routes";
import TextInput from "../../../elements/form/TextInput";
import SubmitButton from "../../../elements/form/SubmitButton";
import ProfileApi from "../../../api/ProfileApi";
import BorderWithDotNew from "../../../elements/border/BorderWithDotNew";

class ChangePasswordForm extends React.Component {

    initialState = {
        oldPassword: '',
        password: '',
        password2: '',
        error: false,
        success: false
    };

    state = this.initialState;

    successMessageTimeout = null;

    componentWillUnmount() {
        if(this.successMessageTimeout) clearTimeout(this.successMessageTimeout);
    }

    handleForgotPassword = () => {
        this.props.authorizationLogout();
        history.push(ROUTE_FORGOT_PASSWORD);
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        ProfileApi.updatePassword(this.state.oldPassword, this.state.password, this.state.password2)
            .then((response) => {
                if (response.data.success) {
                    this.setState(this.initialState);
                    this.setState({success: true});
                    let that = this;
                    this.successMessageTimeout = setTimeout(() => {
                        that.setState({success: false})
                    }, 3000);
                } else {
                    this.setState({success: false, error: true});
                }
            })
    };

    render() {
        return (
            <div>
                <BorderWithDotNew/>
                <div className="row">
                    <div className="col-6 col-lg-3">
                        <div className="ChangePasswordForm">
                            <h3><Translate id="profile.changePasswordForm.title"/></h3>
                            <form onSubmit={this.handleFormSubmit}>
                                <div className="form-group">
                                    <div className="label">
                                        <Translate id="profile.changePasswordForm.oldPassword"/>
                                    </div>
                                    <TextInput type="password"
                                               value={this.state.oldPassword}
                                               onChange={(e) => this.setState({oldPassword: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <div className="label">
                                        <Translate id="profile.changePasswordForm.password"/>
                                    </div>
                                    <TextInput type="password"
                                               value={this.state.password}
                                               onChange={(e) => this.setState({password: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <div className="label">
                                        <Translate id="profile.changePasswordForm.password2"/>
                                    </div>
                                    <TextInput type="password"
                                               value={this.state.password2}
                                               onChange={(e) => this.setState({password2: e.target.value})}/>
                                </div>
                                <div className="message">
                                    {this.state.error ? (
                                        <div className="message-error">
                                            <Translate id="profile.changePasswordForm.error"/>
                                        </div>
                                    ) : this.state.success ? (
                                        <div className="message-success">
                                            <Translate id="profile.changePasswordForm.success"/>
                                        </div>
                                    ) : null}
                                </div>
                                <SubmitButton>
                                    <Translate id="profile.changePasswordForm.submit"/>
                                </SubmitButton>
                            </form>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div className="ResetPasswordForm">
                            <h3>
                                <Translate id="profile.resetPasswordForm.title"/>
                            </h3>
                            <div className="c-button submit-button-container text-center">
                                <button onClick={this.handleForgotPassword}>
                                    <Translate id="profile.resetPasswordForm.submit"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, {
    authorizationLogout
})(ChangePasswordForm);

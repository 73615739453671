import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ColorPicker from "../../../elements/form/ColorPicker";
import ProfileApi from "../../../api/ProfileApi";
import {Translate} from "react-localize-redux";
import {profileUpdateCompanyColorSuccess} from "../../../store/profile/profileAction";

const CompanyColor = () => {

    const dispatch = useDispatch();

    const profileState = useSelector(state => state.profile);

    const [color, setColor] = useState('');

    useEffect(() => {
        setColor(profileState.company?.color);
    }, [profileState.company?.color])

    const handleChange = (val) => {
        setColor(val);
        ProfileApi.updateCompany(val).then(() => {
            dispatch(profileUpdateCompanyColorSuccess(val));
        });
    }

    return (
        <div className="CompanyColor">
            <h3>
                <Translate id="profile.companyColor.title"/>
            </h3>
            <p>
                <Translate id="profile.companyColor.text"/>
            </p>
            <ColorPicker value={color} onChange={handleChange}/>
            <div/>
            <button className="set-default-color d-inline-block" onClick={() => handleChange('#055C60')} type="button">
                <Translate id="profile.companyColor.setDefault"/>
            </button>
        </div>
    )


}

export default CompanyColor;
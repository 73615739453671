import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import gfx_copy from '../../../gfx/copy.svg';
import gfx_open from '../../../gfx/openNewTab.svg';
import EventBus from "../../../elements/EventBus";
import IconReplay from "../../../gfx/IconReplay";

const ReplayButton = ({evnt, extraClassName = ''}) => {

    const [open, setOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleCopySuccess = () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000);
    }

    useEffect(() => {
        EventBus.on('brandClick', handleClose);

        return () => {
            EventBus.remove('brandClick', handleClose);
        }
    })

    return (
        <Fragment>
            <button className={`ReplayButton ${extraClassName}`} type="button" onClick={handleOpen}>
                <IconReplay/>
                <span className="svg-container d-none d-lg-flex">
                    <IconReplay/>
                </span>
                <span className="label-container d-none d-md-inline-block">
                    <Translate id="meeting.replayButton"/>
                </span>
            </button>
            <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container replay-overlay-container">
                <div className="replay-dialog-outer">
                    <div className="replay-dialog">
                        <button onClick={handleClose}>
                            <Translate id="meeting.replay.dialog.close"/>
                        </button>
                        <div className="replay-content">
                            <div className="title-bar">
                                <div className="title">
                                    <Translate id="meeting.replay.dialog.title"/> <Translate id={`meeting.typeWithFromArticle.${evnt.type}`}/>:<br/>
                                    {evnt.title}
                                </div>
                                <BorderWithDot/>
                            </div>
                            <div className="text">
                                <Translate id="meeting.replay.dialog.text"/>
                            </div>
                            <div className="input-group">
                                <textarea readOnly={true} value={evnt.zoomMeeting?.downloadLink} onClick={(e) => e.target.select()}/>
                                <div className="cta-button-group">
                                    <CopyToClipboard text={evnt.zoomMeeting?.downloadLink} onCopy={handleCopySuccess}>
                                        <button className={`btn-copy${copySuccess ? ' success' : ''}`}>
                                            <img src={gfx_copy} alt=""/>
                                            <Translate id="meeting.replay.dialog.doCopy"/>
                                        </button>
                                    </CopyToClipboard>
                                    <a href={evnt.zoomMeeting?.downloadLink} target="_blank" rel="noopener noreferrer" className="a-btn btn-open">
                                        <img src={gfx_open} alt=""/>
                                        <Translate id="meeting.replay.dialog.open"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        </Fragment>
    )
}

export default ReplayButton;
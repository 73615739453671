import React from 'react';

const FilterIcon = () => (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         viewBox="0 0 29.4 24" style={{enableBackground: 'new 0 0 29.4 24'}}>
        <g>
            <path fill="currentColor" d="M28.4,11h-5.5c0,0,0,0,0,0c-0.4-1.7-2-3-3.9-3c-1.9,0-3.4,1.3-3.9,3c0,0,0,0,0,0H1c-0.6,0-1,0.4-1,1s0.4,1,1,1
                h14.1c0,0,0,0,0,0c0.4,1.7,2,3,3.9,3c1.9,0,3.4-1.3,3.9-3c0,0,0,0,0,0h5.5c0.6,0,1-0.4,1-1S29,11,28.4,11z M19,14c-1.1,0-2-0.9-2-2
                s0.9-2,2-2s2,0.9,2,2S20.1,14,19,14z"/>
                    <path fill="currentColor" d="M28.4,19H13.9c0,0,0,0,0,0c-0.4-1.7-2-3-3.9-3c-1.9,0-3.4,1.3-3.9,3c0,0,0,0,0,0H1c-0.6,0-1,0.4-1,1s0.4,1,1,1
                h5.1c0,0,0,0,0,0c0.4,1.7,2,3,3.9,3c1.9,0,3.4-1.3,3.9-3c0,0,0,0,0,0h14.5c0.6,0,1-0.4,1-1S29,19,28.4,19z M10,22c-1.1,0-2-0.9-2-2
                s0.9-2,2-2s2,0.9,2,2S11.1,22,10,22z"/>
                    <path fill="currentColor" d="M28.4,3H13.9c0,0,0,0,0,0c-0.4-1.7-2-3-3.9-3C8.1,0,6.6,1.3,6.1,3c0,0,0,0,0,0H1C0.4,3,0,3.4,0,4s0.4,1,1,1
                h5.1c0,0,0,0,0,0c0.4,1.7,2,3,3.9,3c1.9,0,3.4-1.3,3.9-3c0,0,0,0,0,0h14.5c0.6,0,1-0.4,1-1S29,3,28.4,3z M10,6C8.9,6,8,5.1,8,4
                s0.9-2,2-2s2,0.9,2,2S11.1,6,10,6z"/>
        </g>
    </svg>
)

export default FilterIcon;
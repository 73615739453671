import {
    EVENTS_EVENT_UPLOAD_REMOVE_SUCCESS, EVENTS_EVENT_UPLOADS_LOAD_SUCCESS,
    EVENTS_LOAD_SUCCESS,
    EVENTS_UPDATE_EVENT_NOTIFICATIONS
} from "../../config/actions";
import EventsApi from "../../api/EventsApi";

export const eventsLoadSuccess = (items) => {
    return {
        type: EVENTS_LOAD_SUCCESS,
        items
    }
};

export const eventsUpdateEventNotifications = (appId, itemId, notifications) => {
    return {
        type: EVENTS_UPDATE_EVENT_NOTIFICATIONS,
        appId,
        itemId,
        notifications
    }
};

export const eventsEventUploadRemoveSuccess = (itemId) => {
    return {
        type: EVENTS_EVENT_UPLOAD_REMOVE_SUCCESS,
        itemId
    }
};

export const eventsEventUploadsLoadSuccess = (itemId, appId, uploads) => {
    return {
        type: EVENTS_EVENT_UPLOADS_LOAD_SUCCESS,
        itemId,
        appId,
        uploads
    }
};

export const eventsEventUploadsWaitForFile = (itemId, appId, uploadItemId, count = 1) => {
    if (count > 20) return;
    return (dispatch) => {
        EventsApi.loadEvents(false).then(response => {
            if (response.status === 200 && response.data.success) {
                let event = response.data.data.find(e => e.itemId === itemId && e.appId === appId);
                let upload = event?.uploads?.find(u => u.itemId === uploadItemId);
                if (upload?.files?.length > 0) {
                    dispatch(eventsEventUploadsLoadSuccess(itemId, appId, event.uploads));
                } else {
                    setTimeout(() => {
                        dispatch(eventsEventUploadsWaitForFile(itemId, appId, uploadItemId, count + 1));
                    }, 3000);
                }
            }
        });
    }
}

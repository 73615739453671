import React from 'react';
import {Translate} from "react-localize-redux";
import ChangePasswordForm from "./ChangePasswordForm";
import {connect} from "react-redux";
import CompanyColor from "./CompanyColor";
import ProfileImageButton from "./ProfileImageButton";
import BorderWithDotNew from "../../../elements/border/BorderWithDotNew";

class Profile extends React.Component {

    render() {
        const {profile} = this.props;
        return (
            <div className="Profile d-lg-flex align-items-lg-center">
                <div className="container">
                    <div className="row flex-wrap row-profile">
                        <div className="col-6 col-md-1 text-center text-md-right pr-md-0 col-profile-image-button">
                            <ProfileImageButton/>
                        </div>
                        <div className="col-6 col-md-4 col-user-details">
                            <div className="user-details">
                                <BorderWithDotNew/>
                                <div className="row">
                                    <div className="col-6 col-lg-3">
                                        <h3>
                                            <Translate id="profile.userDetails.title"/>
                                        </h3>
                                        <p>
                                            <strong><Translate id="profile.userDetails.name"/></strong><br/>
                                            {profile.name}
                                        </p>
                                        <p>
                                            <strong><Translate id="profile.userDetails.institution"/></strong><br/>
                                            {profile.company?.name}
                                        </p>
                                        <p>
                                            <strong><Translate id="profile.userDetails.email"/></strong><br/>
                                            {profile.email}
                                        </p>
                                    </div>
                                    <div className="col-6 col-lg-3">
                                        <CompanyColor/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 offset-md-1 col-changePw">
                            <ChangePasswordForm/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(Profile);

import React from 'react';

const GfxEventTypeLocal = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.58" height="37.085" viewBox="0 0 27.58 37.085">
    <g id="Gruppe_3682" data-name="Gruppe 3682" transform="translate(-201.064 -167.401)">
      <path id="Differenzmenge_4" data-name="Differenzmenge 4" d="M8.429,30.471v0L.615,11.589a8.428,8.428,0,1,1,15.627,0L8.429,30.469ZM8.563,4.178a4.509,4.509,0,1,0,4.509,4.51A4.515,4.515,0,0,0,8.563,4.178Z" transform="translate(206.395 172.699)" fill="currentColor" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1"/>
      <path id="Pfad_1506" data-name="Pfad 1506" d="M489.259,470.183a12.79,12.79,0,1,1,18.088,0" transform="translate(-283.449 -279.948)" fill="none" stroke="currentColor" strokeWidth="2"/>
    </g>
  </svg>
)

export default GfxEventTypeLocal;
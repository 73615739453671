import React from 'react';

const IconWeb = () => (
  <svg id="Gruppe_4148" data-name="Gruppe 4148" xmlns="http://www.w3.org/2000/svg" width="33.406" height="22.579" viewBox="0 0 33.406 22.579">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rechteck_2121" data-name="Rechteck 2121" width="33.406" height="22.579" fill="none"/>
      </clipPath>
    </defs>
    <g id="Gruppe_3837" data-name="Gruppe 3837" clipPath="url(#clip-path)">
      <path id="Pfad_1667" data-name="Pfad 1667" d="M33.023,1.274a2.661,2.661,0,0,0-.891-.891q-.109-.066-.225-.122a2.626,2.626,0,0,0-.744-.23Q31.1.02,31.03.014A2.685,2.685,0,0,0,30.76,0H2.646a2.654,2.654,0,0,0-.661.083Q1.922.1,1.859.119q-.124.039-.243.089Q1.557.233,1.5.261q-.116.056-.225.122A2.662,2.662,0,0,0,.963.6Q.865.685.775.775A2.638,2.638,0,0,0,0,2.646V19.933a2.646,2.646,0,0,0,2.646,2.646H30.76a2.646,2.646,0,0,0,2.646-2.646V2.646a2.634,2.634,0,0,0-.383-1.372m-29.158.6h0a1.99,1.99,0,1,1-1.99,1.99,1.99,1.99,0,0,1,1.99-1.99M31.53,19.933a.771.771,0,0,1-.77.77H2.646a.771.771,0,0,1-.77-.77V7.656H31.53Z" fill="currentColor"/>
    </g>
  </svg>
);

export default IconWeb;
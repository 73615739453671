import React from 'react';
import {Link} from "react-router-dom";
import {Translate} from "react-localize-redux";

class TextInput extends React.Component {

    state = {
        isActive: false
    };

    inputRef = React.createRef();

    render() {
        const {label, type, value, onChange, internalLink, error, errorPrefix, disabled, placeholder, autoFocus} = this.props;
        return (
            <div className={`c-input text-input-container${this.state.isActive ? ' active' : ''}${error ? ' error' : ''}${disabled ? ' disabled' : ''}`}>
                {internalLink ? (
                    <Link to={internalLink.to} className="internalLink float-right" tabIndex="-1">
                        {internalLink.title}
                    </Link>
                ) : null}
                {label ? <label onClick={() => this.inputRef.current.focus()}>{label}</label> : null}
                <input type={type ? type : 'text'} value={value} onChange={onChange}
                       onFocus={() => this.setState({isActive: true})}
                       onBlur={() => this.setState({isActive: false})}
                       placeholder={placeholder} autoFocus={autoFocus}
                       ref={this.inputRef} disabled={disabled}/>
                {error ? (
                    <div className="error-message">
                        <Translate id={`${errorPrefix ? errorPrefix : 'default.error'}.${error}`}/>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default TextInput;

import React from "react";
import { withLocalize } from "react-localize-redux";

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage, className }) => (
    <div className={`LanguageToggle ${className}`}>
        {languages.map((lang, lI) => (
            <button key={lI} className={`btn default-btn btn-link d-inline${lang.code === activeLanguage?.code ? ' active' : ''}`} onClick={() => setActiveLanguage(lang.code)}>
                {lang.code}
            </button>
        ))}
    </div>
);

export default withLocalize(LanguageToggle);
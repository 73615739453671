import React from 'react';
import {generatePath} from "react-router-dom";
import AuthorizationApi from "../../../api/AuthorizationApi";
import history from "../../../history";
import {ROUTE_PUBLICATIONS_DIRECT_ITEM} from "../../../config/routes";
import ProfileApi from "../../../api/ProfileApi";
import {connect} from "react-redux";
import {authorizationLoginSuccess, authorizationLogout} from "../../../store/authorization/authorizationAction";
import {profileCompaniesLoadSuccess, profileSelectCompany} from "../../../store/profile/profileAction";
import {withLocalize} from "react-localize-redux";

class PublicationLogin extends React.Component {

    componentDidMount() {
        const {companyId, publicationItemId, token} = this.props.match.params;
        this.props.authorizationLogout();
        AuthorizationApi.login(token, 'tokenLogin')
            .then((response) => {
                if (response.status === 200) {
                    let {access_token, username, roles, lang} = response.data;
                    this.props.authorizationLoginSuccess(access_token, username, roles);
                    this.props.profileSelectCompany(parseInt(companyId));
                    if (lang) this.props.setActiveLanguage(lang);
                    if (!this.props.profile.companies) {
                        ProfileApi.getCompanies()
                            .then(response => {
                                this.props.profileCompaniesLoadSuccess(response.data?.data);
                                history.push(generatePath(ROUTE_PUBLICATIONS_DIRECT_ITEM, {publicationItemId: publicationItemId}));
                            })
                    } else {
                        history.push(generatePath(ROUTE_PUBLICATIONS_DIRECT_ITEM, {publicationItemId: publicationItemId}));
                    }
                } else {
                    history.push("/");
                }
            })
            .catch(() => {
                history.push("/");
            });

    }

    render() {
        return (
            <div className="PublicationLogin"/>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, {
    authorizationLogout, authorizationLoginSuccess, profileCompaniesLoadSuccess, profileSelectCompany
})(withLocalize(PublicationLogin));
import React, {Fragment, useState} from 'react';
import ParticipantsPersonRow from "./ParticipantsPersonRow";
import moment from 'moment';
import gfx_arrowDown from "../../../gfx/contacts/contacts_arrow-down.svg";
import gfx_arrowRight from "../../../gfx/contacts/contacts_arrow-right.svg";
import AnimateHeight from 'react-animate-height';
import JoinButton from "./JoinButton";
import ParticipantsNoParticipants from "./ParticipantsNoParticipants";
import ParticipantsRoadshowSlot from "./ParticipantsRoadshowSlot";

function ParticipantsRoadshow({personsToShow, event, hasFilters, past}) {

    const [openedSlots, setOpenedSlots] = useState([]);

    const toggleSlot = (slotIdentifier) => {
        if (openedSlots.includes(slotIdentifier)) {
            setOpenedSlots(openedSlots.filter(os => os !== slotIdentifier));
        } else {
            setOpenedSlots([...openedSlots, slotIdentifier]);
        }
    }

    const slotIsOpen = (slotIdentifier) => {
        return openedSlots.includes(slotIdentifier);
    }

    return (
        <div className="ParticipantsRoadshow">
            {event.slots.sort((a, b) => moment(a.date.start).isBefore(moment(b.date.start)) ? -1 : 1).map((slot, sI) => (
                <Fragment key={sI}>
                    <div className={`participants-group-header${slotIsOpen(slot.itemId) ? ' open' : ''}`}
                         onClick={() => toggleSlot(slot.itemId)}>
                        <div className="arrow">
                            <div className="image-container">
                                <img src={slotIsOpen(slot.itemId) ? gfx_arrowDown : gfx_arrowRight} alt=""/>
                            </div>
                        </div>
                        <div className="label">
                            <div className="label-time">
                                {moment(slot.date.start).format('LT')}
                            </div>
                            <div className="label-title">
                                {slot.title}
                            </div>
                        </div>
                        <div className="button" onClick={e => {
                            e.stopPropagation();
                        }}>
                            { !past ? <JoinButton className="btn-infobox btn-dial" event={slot} withTextSpan hardHidden={true}/> : null}
                        </div>
                    </div>
                    <AnimateHeight height={slotIsOpen(slot.itemId) ? 'auto' : 0}>
                        <ParticipantsRoadshowSlot slot={slot} persons={personsToShow} hasFilters={hasFilters}/>
                    </AnimateHeight>
                </Fragment>
            ))}
        </div>
    )
}

export default ParticipantsRoadshow;

import axios from 'axios';
import {store} from '../store';

class EventsApi {

    static loadEvents = (onlyFuture = true) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/event/filtered`, onlyFuture ? {
            breakpoint: 'future',
            company: store.getState()?.profile?.selectedCompanyId
        } : {
            company: store.getState()?.profile?.selectedCompanyId
        })
    };

    static participate = (appId, itemId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}event/apply`, {
            appId,
            itemId
        })
    };

    static cancel = (appId, itemId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}event/cancel`, {
            appId,
            itemId
        })
    }

    static getIcs = (appId, itemId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/ics/`, {
            appId,
            itemId
        }, {
            responseType: 'blob'
        });
    }

    static setNotification = (appId, itemId, intervalInMinutes) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/notification`, {
            appId,
            itemId,
            intervalInMinutes,
            company: store.getState()?.profile?.selectedCompanyId
        });
    }

    static upload = (data) => {
        return axios.put(`${process.env.REACT_APP_API_URL}upload/`, data);
    }

    static deleteFile = (uuid) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}upload/${uuid}`);
    }
}

export default EventsApi;

export default () => (
  <svg id="Gruppe_4540" data-name="Gruppe 4540" xmlns="http://www.w3.org/2000/svg" width="26.331" height="20.775" viewBox="0 0 26.331 20.775">
    <path id="Pfad_2052" data-name="Pfad 2052" d="M16.638,19.026l-.84-.616-3.546,2.7v6.668h4.387Z" transform="translate(-4.261 -7)" fill="currentColor"/>
    <path id="Pfad_2053" data-name="Pfad 2053" d="M22.232,19.069l-2.765,2.178-1.621-1.189v7.688h4.387Z" transform="translate(-4.022 -6.972)" fill="currentColor"/>
    <path id="Pfad_2054" data-name="Pfad 2054" d="M23.44,27.936h4.387V14.661L23.44,18.118Z" transform="translate(-3.783 -7.16)" fill="currentColor"/>
    <path id="Pfad_2055" data-name="Pfad 2055" d="M11.169,27.624V21.956L6.782,25.293v2.331Z" transform="translate(-4.495 -6.849)" fill="currentColor"/>
    <path id="Pfad_2056" data-name="Pfad 2056" d="M6.827,22.69a1.043,1.043,0,0,1-.632-1.873l9.249-7.034a1.04,1.04,0,0,1,1.248-.011l3.269,2.4,7.975-6.285a1.043,1.043,0,0,1,1.291,1.638l-8.6,6.775a1.043,1.043,0,0,1-1.263.022l-3.279-2.407-8.63,6.564a1.032,1.032,0,0,1-.63.213" transform="translate(-4.538 -8.417)" fill="currentColor"/>
    <path id="Pfad_2057" data-name="Pfad 2057" d="M9.163,21.25a2.287,2.287,0,1,1-2.287-2.287A2.287,2.287,0,0,1,9.163,21.25" transform="translate(-4.589 -8.019)" fill="currentColor"/>
    <path id="Pfad_2058" data-name="Pfad 2058" d="M30.028,10.755a2.287,2.287,0,1,1-2.287-2.287,2.287,2.287,0,0,1,2.287,2.287" transform="translate(-3.697 -8.468)" fill="currentColor"/>
  </svg>
);
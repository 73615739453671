import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import gfx_profileUpload from '../../../gfx/profile_upload.svg';
import gfx_profileUploadMo from '../../../gfx/profile_upload_mo.svg';
import gfx_profileUploadLight from '../../../gfx/profile_upload_light.svg';
import gfx_profileUploadLightMo from '../../../gfx/profile_upload_light_mo.svg';
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import gfx_imageUploadPlaceholder from '../../../gfx/image_upload_placeholder.svg';
import gfx_upload from '../../../gfx/upload.svg';
import gfx_delete from '../../../gfx/delete.svg';
import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import ProfileApi from "../../../api/ProfileApi";
import {
    profileDeleteImageSuccess,
    profileUploadWaitForImage
} from "../../../store/profile/profileAction";
import EditIcon from "../../../gfx/EditIcon";

const ProfileImageButton = () => {

    const dispatch = useDispatch();

    const profileState = useSelector(state => state.profile);

    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [tempImage, setTempImage] = useState(null);
    const [crop, setCrop] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [hasFileError, setHasFileError] = useState(false);

    useEffect(() => {
        if(profileState.image?.uuid) {
            setTempImage(null);
        }
    }, [profileState.image?.uuid]);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
        setHasFileError(false);
        setSelectedFile(null);
        setPreviewImage(null);
    };

    const handleSelectFile = () => {
        setHasFileError(false);
        fileInputRef.current?.click();
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreviewImage(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreviewImage(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile])

    const handleUseFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            setTimeout(doSizeCalculations, 500);
            return;
        }
        if (e.target.files[0].size > 10000000) {
            setHasFileError(true);
            setSelectedFile(undefined);
            doSizeCalculations();
            return;
        }
        setSelectedFile(e.target.files[0])
    }

    const handleSaveCrop = () => {
        if (imageToCropRef.current && typeof crop === 'object') {
            setIsUploading(true);
            const data = new FormData();
            data.append('file', selectedFile);
            data.append('fileName', selectedFile.name);
            data.append('cropX', Math.round(crop.x * 0.01 * imageToCropRef.current.naturalWidth));
            data.append('cropY', Math.round(crop.y * 0.01 * imageToCropRef.current.naturalHeight));
            data.append('cropW', Math.round(crop.width * 0.01 * imageToCropRef.current.naturalWidth));
            data.append('cropH', Math.round(crop.height * 0.01 * imageToCropRef.current.naturalHeight));
            fileInputRef.current.value = null;
            ProfileApi.uploadImage(data)
                .then(response => {
                    if (response.status === 200) {
                        setIsUploading(false);
                        setTempImage(URL.createObjectURL(response.data));
                        setSelectedFile(null);
                        setPreviewImage(null);
                        dispatch(profileDeleteImageSuccess());
                        setTimeout(() => dispatch(profileUploadWaitForImage()), 10000);
                        doSizeCalculations();
                    }
                })
        }
    }

    const handleDeleteFile = () => {
        if (profileState.image.uuid) {
            setIsDeleting(true);
            ProfileApi.deleteImage(profileState.image.uuid)
                .then((response) => {
                    if (response.status === 200) {
                        setIsDeleting(false);
                        setTempImage(null);
                        dispatch(profileDeleteImageSuccess());
                    }
                })
        }
    }

    const imagePlaceholderForName = (name) => {
        if (!name || name.length < 2) return '';
        const parts = name.split(' ');
        if (parts.length >= 2) {
            return `${parts[0][0]}${parts[parts.length - 1][0]}`
        } else {
            return `${parts[0][0]}${parts[0][1]}`
        }
    }

    const profileImageRef = useRef();
    const replaceImageIconButtonRef = useRef();
    const imageContainerRef = useRef();
    const imageToCropRef = useRef();
    const doSizeCalculations = () => {
        if (profileImageRef.current) {
            profileImageRef.current.style.width = profileImageRef.current.getBoundingClientRect().height + 'px';
            if (replaceImageIconButtonRef.current) {
                replaceImageIconButtonRef.current.style.right = `calc((100% - ${profileImageRef.current.getBoundingClientRect().height}px) / 2)`;
            }
        }
        if (imageContainerRef.current && imageToCropRef.current) {
            imageToCropRef.current.style.height = `${imageContainerRef.current.getBoundingClientRect().height}px`;
        }
    }

    useEffect(() => {
        window.addEventListener('resize', doSizeCalculations);

        return function cleanup() {
            window.removeEventListener('resize', doSizeCalculations);
        }
    })

    useEffect(() => {
        if (open) doSizeCalculations();
    }, [open, profileState.image?.uuid]);

    const fileInputRef = useRef();

    const onImageLoad = (e) => {
        doSizeCalculations();
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                1,
                width,
                height
            ),
            width,
            height
        )
        setCrop(crop)
    }

    return (
        <Fragment>
            <input type="file" ref={fileInputRef} style={{width: 0, height: 0, overflow: 'hidden'}} accept="image/png, image/jpeg, image/jpg" onChange={handleUseFile}/>
            <button className="ProfileImageButton" type="button" onClick={openDialog}>
                {profileState.image?.uuid || tempImage ? (
                    <Fragment>
                        <img src={tempImage ? tempImage : `${process.env.REACT_APP_API_URL}image/${profileState.image.uuid}`} alt="" className="img-preview"/>
                        <div className="EditIconContainer">
                            <EditIcon/>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <img src={gfx_profileUpload} alt="" className="img-upload img-upload-default"/>
                        <img src={gfx_profileUploadMo} alt="" className="img-upload img-upload-mo"/>
                    </Fragment>
                )}
            </button>
            <Overlay open={open} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} onClose={closeDialog} className="overlay-container">
                <div className="profileImage-dialog-outer">
                    <div className="profileImage-dialog">
                        <button onClick={closeDialog}>
                            <Translate id="profile.profileImage.dialog.cancel"/>
                        </button>
                        <div className="profileImage-content">
                            <div className="title">
                                <Translate id="profile.profileImage.dialog.title"/>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="main-container">
                                    <div className="image-outer-container">
                                        <div className="image-container" ref={imageContainerRef}>
                                            {!profileState.image?.uuid && !tempImage && !selectedFile ? (
                                                <img src={gfx_imageUploadPlaceholder} alt="" className="placeholder" key={1} onClick={handleSelectFile}/>
                                            ) : previewImage ? (
                                                <div className="crop-outer" key={2}>
                                                    <ReactCrop onChange={(c, pc) => setCrop(pc)} crop={crop} className="crop-container" aspect={1}>
                                                        <img src={previewImage} alt="" className="crop-image" onLoad={onImageLoad} ref={imageToCropRef}/>
                                                    </ReactCrop>
                                                </div>
                                            ) : (profileState.image?.uuid || tempImage) ? (
                                                <Fragment key={3}>
                                                    <div className="profileImage" style={{backgroundImage: tempImage ? `url(${tempImage})` : `url(${process.env.REACT_APP_API_URL}image/${profileState.image.uuid})`}} ref={profileImageRef} onLoad={doSizeCalculations}/>
                                                    <button className="replace-image-icon-button" type="button" onClick={handleSelectFile} ref={replaceImageIconButtonRef}>
                                                        <img src={gfx_profileUploadLight} alt="" className="img-replace-icon img-replace-icon-default"/>
                                                        <img src={gfx_profileUploadLightMo} alt="" className="img-replace-icon img-replace-icon-mo"/>
                                                    </button>
                                                </Fragment>
                                            ) : null}
                                        </div>
                                    </div>
                                    {previewImage ? (
                                        <button className={`save-crop-button d-none d-md-inline-block${isUploading ? ' loading' : ''}`} type="button" onClick={handleSaveCrop} disabled={isUploading}>
                                            <Translate id="profile.profileImage.dialog.saveCrop"/>
                                        </button>
                                    ) : null}
                                </div>
                                <div className="buttons-container d-md-flex flex-md-column">
                                    <div className="d-inline-flex flex-column buttons-inner">
                                        {previewImage ? (
                                            <div className="d-inline-block d-md-none">
                                                <button className={`save-crop-button${isUploading ? ' loading' : ''}`} type="button" onClick={handleSaveCrop} disabled={isUploading}>
                                                    <Translate id="profile.profileImage.dialog.saveCrop"/>
                                                </button>
                                            </div>
                                        ) : null}
                                        <div className="d-inline-block d-md-flex flex-md-column mb-lg-2">
                                            <button className="upload-image-button" type="button" onClick={handleSelectFile}>
                                                <img src={gfx_upload} alt=""/>
                                                {profileState.image?.uuid ? (
                                                    <Translate id="profile.profileImage.dialog.replace"/>
                                                ) : (
                                                    <Translate id="profile.profileImage.dialog.upload"/>
                                                )}
                                            </button>
                                            <button className={`delete-image-button${isDeleting ? ' loading' : ''}`} type="button" onClick={handleDeleteFile} disabled={isDeleting || !profileState.image?.uuid}>
                                                <img src={gfx_delete} alt=""/>
                                                <Translate id="profile.profileImage.dialog.delete"/>
                                            </button>
                                        </div>
                                    </div>
                                    {hasFileError && (
                                        <div className="text-file-error d-md-none d-lg-block mb-lg-2">
                                            <Translate id="profile.profileImage.dialog.fileError"/>
                                        </div>
                                    )}
                                    <div className="text-image-info d-md-none d-lg-block mt-lg-auto">
                                        <Translate id="profile.profileImage.dialog.infoText"/>
                                    </div>
                                    <div className="preview-info d-none d-lg-block">
                                        <div>
                                            <Translate id="profile.profileImage.dialog.previewText"/>
                                        </div>
                                        {profileState.image?.uuid || tempImage ? (
                                            <img src={tempImage ? tempImage : `${process.env.REACT_APP_API_URL}image/${profileState.image.uuid}`} alt="" className="image"/>
                                        ) : (
                                            <div className="imagePlaceholder">
                                                {imagePlaceholderForName(profileState.name)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {hasFileError && (
                                <div className="text-file-error d-none d-md-block d-lg-none">
                                    <Translate id="profile.profileImage.dialog.fileError"/>
                                </div>
                            )}
                            <div className="text-image-info d-none d-md-block d-lg-none">
                                <Translate id="profile.profileImage.dialog.infoText"/>
                            </div>
                            <div className="preview-info d-lg-none">
                                <div>
                                    <Translate id="profile.profileImage.dialog.previewText"/>
                                </div>
                                {profileState.image?.uuid || tempImage ? (
                                    <img src={tempImage ? tempImage : `${process.env.REACT_APP_API_URL}image/${profileState.image.uuid}`} alt="" className="image"/>
                                ) : (
                                    <div className="imagePlaceholder">
                                        {imagePlaceholderForName(profileState.name)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        </Fragment>
    )
}

export default ProfileImageButton;
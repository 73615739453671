import axios from 'axios';

class AuthorizationApi {
    static login = (username, password) => {
        return axios.post(`${process.env.REACT_APP_API_URL}login`, {
            username,
            password
        });
    };

    static refreshToken = (refreshToken) => {
        return axios.post(`${process.env.REACT_APP_API_URL}oauth/access_token`, null,{
            params: {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }
        })
    };

    static forgotPassword = (email) => {
        return axios.post(`${process.env.REACT_APP_API_URL}profile/forgotPassword`, {
            email
        });
    };

    static resetPassword = (token, password, password2) => {
        return axios.post(`${process.env.REACT_APP_API_URL}profile/resetPassword`, {
            token, password, password2
        });
    };
}

export default AuthorizationApi;

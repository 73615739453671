import React from 'react';

const GfxNewEvent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34.103" height="34.103" viewBox="0 0 34.103 34.103">
    <g id="Gruppe_3650" data-name="Gruppe 3650" transform="translate(34.103 34.103) rotate(180)">
      <g id="Gruppe_3617" data-name="Gruppe 3617">
        <g id="Gruppe_2749" data-name="Gruppe 2749">
          <path id="Pfad_1408" data-name="Pfad 1408" d="M75.81,97.667A17.052,17.052,0,1,1,92.861,80.616,17.071,17.071,0,0,1,75.81,97.667Zm0-30.226A13.174,13.174,0,1,0,88.984,80.616,13.19,13.19,0,0,0,75.81,67.441Z" transform="translate(-58.758 -63.564)" fill="currentColor"/>
        </g>
      </g>
      <path id="Pfad_1496" data-name="Pfad 1496" d="M4.06,0V4.06H0V7.939H4.06V12H7.939V7.939H12V4.06H7.939V0Z" transform="translate(11.052 11.053)" fill="currentColor"/>
    </g>
  </svg>
)

export default GfxNewEvent;
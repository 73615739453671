import React, {Fragment} from 'react';
import infoButtonDefaultGfx from '../../../gfx/info_button.svg';
import infoButtonMouseoverGfx from '../../../gfx/info_button_mouseover.svg';
import {Overlay} from "react-portal-overlay";
import {Translate} from "react-localize-redux";
import EventBus from "../../../elements/EventBus";

class InfoButton extends React.Component {

    state = {
        dialogOpen: false
    }

    componentDidMount() {
        EventBus.on('brandClick', this.closeDialog);
    }

    componentWillUnmount() {
        EventBus.remove('brandClick', this.closeDialog);
    }

    openDialog = () => {
        this.setState({dialogOpen: true});
    };

    closeDialog = () => {
        this.setState({dialogOpen: false});
    };

    render() {
        const {event} = this.props;
        return (
            <Fragment>
                <button className="InfoButton" onClick={this.openDialog}>
                    <img src={infoButtonDefaultGfx} alt="" className="img img-default"/>
                    <img src={infoButtonMouseoverGfx} alt="" className="img img-mouseover"/>
                </button>
                <Overlay open={this.state.dialogOpen} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} closeOnClick={true} onClose={this.closeDialog} className="overlay-container">
                    <div className="info-dialog-outer">
                        <div className="info-dialog">
                            <button onClick={this.closeDialog}>
                                <Translate id="meeting.info.dialog.cancel"/>
                            </button>
                            <div className="info-content">
                                <h3>
                                    {event.title}
                                </h3>
                                <div className="infoText always-margin-top">
                                    <p dangerouslySetInnerHTML={{__html: event.teaser}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Overlay>
            </Fragment>
        )
    }
}

export default InfoButton;
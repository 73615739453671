import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import ContactWidgetLink from "../meetings/ContactWidgetLink";
import Publication from "./Publication";
import CompanyChooser from "../../../elements/topBar/CompanyChooser";
import gfx_search from '../../../gfx/publications/search.svg';
import EntriesPerPageDropdown from "./EntriesPerPageDropdown";
import gfx_arrowLeft from '../../../gfx/publications/arrow_left.svg';
import gfx_arrowRight from '../../../gfx/publications/arrow_right.svg';
import {Translate} from "react-localize-redux";
import moment from "moment";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {useParams} from "react-router";

const Publications = () => {
  const publicationsState = useSelector(state => state.publications);

  const [publications, setPublications] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [didScrollToPublication, setDidScrollToPublication] = useState(false);

  let {publicationItemId} = useParams();

  useEffect(() => {
    if (publicationsState.loaded && publicationsState.items) {
      new Promise((resolve) => {
        let publicationsToShow = publicationsState.items;
        publicationsToShow = publicationsToShow.sort((a, b) => new Date(b.releaseDate?.start)?.getTime() - new Date(a.releaseDate?.start));
        if (searchString && searchString.length > 0) publicationsToShow = publicationsToShow.filter(p => p.title?.toLowerCase()?.includes(searchString?.toLowerCase()));
        resolve(publicationsToShow);
      }).then(publicationsToShow => {
        setPublications(publicationsToShow);
      })
    }
  }, [publicationsState, searchString]);

  useEffect(() => {
    setCurrentPage(0);
  }, [entriesPerPage, searchString]);

  useEffect(() => {
    if (!publicationItemId || didScrollToPublication) {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!didScrollToPublication && publicationItemId && publications?.length > 0) {
      setDidScrollToPublication(true);
      scrollToPublication(publicationItemId);
    }
  }, [didScrollToPublication, publicationItemId, publications]);

  const scrollToPublication = (publicationItemId) => {
    let publicationIndex = publications.findIndex(p => p.itemId === parseInt(publicationItemId));
    if (publicationIndex > -1) {
      let publicationPage = Math.floor(publicationIndex / entriesPerPage);
      if (publicationPage !== currentPage) {
        setCurrentPage(publicationPage);
        setTimeout(() => doScrollToPublication(publicationItemId), 500);
      } else {
        doScrollToPublication(publicationItemId);
      }
    }
  }

  const doScrollToPublication = (publicationItemId) => {
    let element = document.getElementsByClassName(`publication-item-${publicationItemId}`);
    if (element && element.length > 0) {
      element = element[0];
      window.scroll({
        left: 0,
        top: element.getBoundingClientRect().top -120,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className="Publications">
      <ContactWidgetLink/>
      <div className="container-top-line container d-none d-md-block">
        <div className="row">
          <div className="col-3 d-lg-none">
            <CompanyChooser/>
          </div>
          <div className="col-3 col-lg-5 text-right">
            <input type="text" value={searchString} onChange={e => setSearchString(e.target.value)}
                   className="search-input" style={{backgroundImage: `url(${gfx_search})`}}/>
          </div>
        </div>
      </div>
      {publications.length > 0 ? (
          <Fragment>
            <div className="container-publications">
              {publications.slice(currentPage * entriesPerPage, (currentPage + 1) * entriesPerPage).map((publication, pI) => (
                  <Publication key={currentPage * entriesPerPage + pI} publication={publication}
                               hideYear={pI !== 0 && moment(publications[currentPage * entriesPerPage + pI - 1].releaseDate.start).isSame(publication.releaseDate.start, 'year')}/>
              ))}
            </div>
            <div className="container-paginate container">
              <div className="row">
                <div className="col-6 col-lg-2 offset-lg-1 pl-lg-0">
                  <EntriesPerPageDropdown value={entriesPerPage} onChange={val => setEntriesPerPage(val)}/>
                </div>
                <div className="col-6 col-lg-2">
                  <div className="d-lg-none select-page-small">
                    <button className="page-btn page-btn-left" onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage <= 0}>
                      <img src={gfx_arrowLeft} alt=""/>
                    </button>
                    <Translate id="publication.page"/>: {currentPage + 1}
                    <button className="page-btn page-btn-right" onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage >= Math.ceil(publicationsState.items?.length / entriesPerPage) - 1}>
                      <img src={gfx_arrowRight} alt=""/>
                    </button>
                  </div>
                  <div className="d-none d-lg-block select-page-big text-right">
                    <div className="pages-row d-flex">
                      <div className="pages-row-inner d-flex">
                        <span className="page-label"><Translate id="publication.page"/>:</span>
                        {publicationsState.items?.length > 1 && Array.from(Array(Math.ceil(publicationsState.items?.length / entriesPerPage)).keys()).map(page => (
                            <button key={page} className={page === currentPage ? 'active' : ''} onClick={() => setCurrentPage(page)}>
                              <BorderWithDot borderClass="border-with-dot-darkturkuoise"/>
                              {page + 1}
                            </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
      ) : (
          <div className="no-publications container mt-5 mt-lg-0">
            <div className="row">
              <div className="col-4 col-md-5 col-lg-4 offset-lg-1 pl-lg-0">
                <h3 className="ml-1 mt-md-5">
                  <Translate id={`publication.noPublication.title`}/>
                </h3>
                <p className="ml-1">
                  <Translate id={`publication.noPublication.text`}/>
                </p>
              </div>
            </div>
          </div>
      )}

    </div>
  )
}

export default Publications;

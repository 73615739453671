import React from 'react';
import ReactDOM from 'react-dom';
import iconContactMobile from "../../../gfx/contact-mobile.svg";
import iconContact from "../../../gfx/contact.svg";
import iconPhoneMobile from "../../../gfx/phone-mobile.svg";
import iconPhone from "../../../gfx/phone.svg";
import iconMailMobile from "../../../gfx/mail-mobile.svg";
import iconMail from "../../../gfx/mail.svg";

class ContactWidgetLink extends React.Component {

    state = {
        openContact: false
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                openContact: false
            });
        }
    };

    render() {
        return (
            <div className={`contact-widget-link d-flex ${this.state.openContact ? "show" : "" }`}
                 onMouseEnter={() => this.setState({openContact: true})}
                 onMouseLeave={() => this.setState({openContact: false})}
                 onClick={() => this.setState({openContact: true})}
            >
                <img className="icon-open d-inline-block d-lg-none" src={iconContactMobile} alt=""/>
                <img className="icon-open d-none d-lg-inline-block" src={iconContact} alt=""/>
                <img className="icon d-inline-block d-lg-none" src={iconPhoneMobile} alt=""/>
                <img className="icon d-none d-lg-inline-block" src={iconPhone} alt=""/>
                <button className="button" onClick={() => {navigator.clipboard.writeText("+49 (0)40 411 11 37 - 80")}}>
                    <a href="tel:+4940411113780">+49 (0)40 411 11 37 - 80</a>
                </button>
                <img className="icon d-inline-block d-lg-none" src={iconMailMobile} alt=""/>
                <img className="icon d-none d-lg-inline-block" src={iconMail} alt=""/>
                <button className="button" onClick={() => {navigator.clipboard.writeText("info@montega.de")}}>
                    <a href="mailto:info@montega.de">info@montega.de</a>
                </button>
            </div>
        )
    }
}

export default ContactWidgetLink;

import React, {Fragment, useEffect, useState} from 'react';
import ParticipantsPersonRow from "./ParticipantsPersonRow";
import gfx_arrowDown from "../../../gfx/contacts/contacts_arrow-down.svg";
import gfx_arrowRight from "../../../gfx/contacts/contacts_arrow-right.svg";
import {Translate} from "react-localize-redux";
import {Collapse} from "react-collapse/lib/Collapse";
import ParticipantsNoParticipants from "./ParticipantsNoParticipants";

function ParticipantsConference({personsToShow, event, hasFilters}) {

    const [groupMeetingIsOpen, setGroupMeetingIsOpen] = useState(false);
    const [appliersIsOpen, setAppliersIsOpen] = useState(false);
    const [groupMeetingParticipants, setGroupMeetingParticipants] = useState([]);
    const [slotAppliers, setSlotAppliers] = useState([]);

    useEffect(() => {
        setGroupMeetingParticipants(personsToShow.filter(p => event.participants.find(ep => ep.appId === p.appId && ep.itemId === p.itemId)));
        setSlotAppliers(personsToShow.filter(p => event.appliers.find(ep => ep.appId === p.appId && ep.itemId === p.itemId)));
    }, [personsToShow, event]);

    const [sortColumnParticipants, setSortColumnParticipants] = useState('name');
    const [sortDirParticipants, setSortDirParticipants] = useState('asc');
    const [sortedPersonsToShowParticipants, setSortedPersonsToShowParticipants] = useState([]);

    useEffect(() => {
        switch (sortColumnParticipants) {
            case "name":
                setSortedPersonsToShowParticipants([...(sortDirParticipants === 'asc' ? groupMeetingParticipants.sort((a, b) => a.name?.localeCompare(b.name)) : groupMeetingParticipants.sort((a, b) => b.name?.localeCompare(a.name)))]);
                break;
            case "institution":
                setSortedPersonsToShowParticipants([...(sortDirParticipants === 'asc' ? groupMeetingParticipants.sort((a, b) => (a.institution ? a.institution.name : '').localeCompare(b.institution ? b.institution.name : '')) : groupMeetingParticipants.sort((a, b) => (b.institution ? b.institution.name : '').localeCompare(a.institution ? a.institution.name : '')))]);
                break;
            default:
                break;
        }
    }, [sortColumnParticipants, sortDirParticipants, groupMeetingParticipants]);

    const toggleSortParticipants = (column) => {
        if (sortColumnParticipants === column) {
            setSortDirParticipants(sortDirParticipants === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumnParticipants(column);
            setSortDirParticipants('asc');
        }
    }

    const [sortColumnAppliers, setSortColumnAppliers] = useState('name');
    const [sortDirAppliers, setSortDirAppliers] = useState('asc');
    const [sortedPersonsToShowAppliers, setSortedPersonsToShowAppliers] = useState([]);

    useEffect(() => {
        switch (sortColumnAppliers) {
            case "name":
                setSortedPersonsToShowAppliers([...(sortDirAppliers === 'asc' ? slotAppliers.sort((a, b) => a.name?.localeCompare(b.name)) : slotAppliers.sort((a, b) => b.name?.localeCompare(a.name)))]);
                break;
            case "institution":
                setSortedPersonsToShowAppliers([...(sortDirAppliers === 'asc' ? slotAppliers.sort((a, b) => (a.institution ? a.institution.name : '').localeCompare(b.institution ? b.institution.name : '')) : slotAppliers.sort((a, b) => (b.institution ? b.institution.name : '').localeCompare(a.institution ? a.institution.name : '')))]);
                break;
            default:
                break;
        }
    }, [sortColumnAppliers, sortDirAppliers, slotAppliers]);

    const toggleSortAppliers = (column) => {
        if (sortColumnAppliers === column) {
            setSortDirAppliers(sortDirAppliers === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumnAppliers(column);
            setSortDirAppliers('asc');
        }
    }

    return (
        <div className="ParticipantsConference">
            <div className="groupMeetingParticipants">
                <div className={`participants-group-header${groupMeetingIsOpen ? ' open' : ''}`}
                     onClick={() => setGroupMeetingIsOpen(!groupMeetingIsOpen)}>
                    <div className="arrow">
                        <div className="image-container">
                            <img src={groupMeetingIsOpen ? gfx_arrowDown : gfx_arrowRight} alt=""/>
                        </div>
                    </div>
                    <div className="label">
                        <Translate id="meeting.participants.dialog.groupMeetingParticipants"/>
                    </div>
                </div>
                <Collapse isOpened={groupMeetingIsOpen}>
                    {sortedPersonsToShowParticipants && sortedPersonsToShowParticipants.length > 0 ? (
                        <Fragment>
                            <div className="participants-list-header space-top d-none d-md-flex">
                                <div className="col-name">
                                    <div className="d-inline-block d-lg-none"> onClick={() => toggleSortParticipants('name')}
                                        <Translate id="meeting.participants.dialog.tableHeader.nameAndInstitute"/>
                                    </div>
                                    <div className="d-none d-lg-inline-block" onClick={() => toggleSortParticipants('name')}>
                                        <Translate id="meeting.participants.dialog.tableHeader.name"/>
                                    </div>
                                </div>
                                <div className="col-institute d-none d-lg-block"
                                     onClick={() => toggleSortParticipants('institution')}>
                                    <div className="d-inline-block">
                                        <Translate id="meeting.participants.dialog.tableHeader.institute"/>
                                    </div>
                                </div>
                            </div>
                            {sortedPersonsToShowParticipants.map((pts) => (
                                <ParticipantsPersonRow person={pts} key={pts.itemId}/>
                            ))}
                        </Fragment>
                    ) : (
                      <ParticipantsNoParticipants hasFilters={hasFilters}/>
                    )}
                </Collapse>
            </div>
            {slotAppliers.length > 0 ? (
                <div className="appliers">
                    <div className={`participants-group-header${appliersIsOpen ? ' open' : ''}`}
                         onClick={() => setAppliersIsOpen(!appliersIsOpen)}>
                        <div className="arrow">
                            <div className="image-container">
                                <img src={appliersIsOpen ? gfx_arrowDown : gfx_arrowRight} alt=""/>
                            </div>
                        </div>
                        <div className="label">
                            <Translate id="meeting.participants.dialog.slotAppliers"/>
                        </div>
                    </div>
                    <Collapse isOpened={appliersIsOpen}>
                        {sortedPersonsToShowAppliers && sortedPersonsToShowAppliers.length > 0 ? (
                              <Fragment>
                                  <div className="participants-list-header space-top d-none d-md-flex">
                                      <div className="col-name">
                                          <div className="d-inline-block d-lg-none"> onClick={() => toggleSortAppliers('name')}
                                              <Translate id="meeting.participants.dialog.tableHeader.nameAndInstitute"/>
                                          </div>
                                          <div className="d-none d-lg-inline-block" onClick={() => toggleSortAppliers('name')}>
                                              <Translate id="meeting.participants.dialog.tableHeader.name"/>
                                          </div>
                                      </div>
                                      <div className="col-institute d-none d-lg-block"
                                           onClick={() => toggleSortAppliers('institution')}>
                                          <div className="d-inline-block">
                                              <Translate id="meeting.participants.dialog.tableHeader.institute"/>
                                          </div>
                                      </div>
                                  </div>
                                  {sortedPersonsToShowAppliers.map((pts) => (
                                      <ParticipantsPersonRow person={pts} key={pts.itemId}/>
                                  ))}
                              </Fragment>
                        ) : (
                            <ParticipantsNoParticipants hasFilters={hasFilters}/>
                        )}
                    </Collapse>
                </div>
            ) : null
            }
        </div>
    )
}

export default ParticipantsConference;

import React from 'react';
import {Route, Switch} from "react-router";
import {ROUTE_DATA_PRIVACY, ROUTE_IMPRINT} from "../../config/routes";
import DataPrivacy from "./dataPrivacy";
import Imprint from "./imprint";
import LanguageToggle from "../../elements/LanguageToggle";

class Legals extends React.Component {
    render() {
        return (
            <div className="Legals">
                <LanguageToggle className="d-none d-lg-block"/>
                <Switch>
                    <Route exact path={ROUTE_DATA_PRIVACY} component={DataPrivacy}/>
                    <Route exact path={ROUTE_IMPRINT} component={Imprint}/>
                </Switch>
            </div>
        )
    }
}

export default Legals;

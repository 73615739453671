import React, {Fragment, useState} from 'react';
import gfx_contactLink from "../../../gfx/contacts/contacts_contact-link.svg";
import gfx_contactLink_md from "../../../gfx/contacts/contacts_contact-link-md.svg";
import gfx_contactLink_md_en from "../../../gfx/contacts/contacts_contact-link-md_en.svg";
import gfx_dummy from "../../../gfx/Dummy.jpg"
import {Translate, withLocalize} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import ContactsApi from "../../../api/ContactsApi";
import BorderWithDot from "../../../elements/border/borderWithDot";
import Linkify from 'react-linkify';

function ProfileButton({appId, itemId, notHardHidden, activeLanguage }) {

    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({});

    const handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
        ContactsApi.loadContactProfile(appId, itemId)
            .then(response => {
                if (response.data.success) {
                    setData(response.data.data);
                    setLoaded(true);
                } else {
                    handleClose();
                }
            })
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Fragment>
            <button className="ProfileButton" onClick={handleOpen} type="button">
                <img src={gfx_contactLink} alt="" className="d-md-none"/>
                <img src={activeLanguage?.code === 'de' ? gfx_contactLink_md : gfx_contactLink_md_en} alt="" className="d-none d-md-inline"/>
            </button>
            {(open || notHardHidden) && <Overlay open={open} animation={{duration: 100, easing: 'ease'}} closeOnClick={false} className="overlay-container">
                <div className="profileButton-dialog-outer" onClick={e => e.stopPropagation()}>
                    <div className="profileButton-dialog">
                        <button onClick={handleClose}>
                            <Translate id="contacts.profile.dialog.back"/>
                        </button>
                        {loaded && (
                            <div className="profileButton-dialog-content">
                                <div className="title-bar">
                                    {['investor', 'multiplier'].includes(data.type) && (
                                        <div className="title">
                                            {data.institution?.name}
                                        </div>
                                    )}
                                    <div className="subtitle">
                                        {['investor', 'multiplier'].includes(data.type) && (
                                            <Translate id={`contacts.profile.dialog.subtitle.${data.type}`}/>
                                        )}
                                        {['earningsCallParticipant'].includes(data.type) && (
                                            <span><Translate id="contacts.profile.dialog.title.notRegistered"/></span>
                                        )}
                                    </div>
                                    <BorderWithDot/>
                                </div>
                                <div className="main-bar">
                                    {(
                                        <div className="image">
                                            <img src= {data.image?.file ? `${process.env.REACT_APP_API_URL}image/${data.image.file.id}` : gfx_dummy} alt=""/>
                                        </div>
                                    )}
                                    <div className="contact">
                                        {['investor', 'multiplier'].includes(data.type) && (
                                            <Fragment>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.contactPerson"/>
                                                    </div>
                                                    <div className="content">
                                                        {data.name}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.position"/>
                                                    </div>
                                                    <div className="content breaker">
                                                        {data.position}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.phone"/>
                                                    </div>
                                                    <div className="content breaker">
                                                        {data.phone}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.email"/>
                                                    </div>
                                                    <div className="content breaker ">
                                                        {data.email}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                        {['earningsCallParticipant'].includes(data.type) && (
                                            <Fragment>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.name"/>
                                                    </div>
                                                    <div className="content">
                                                        {data.name}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.institution"/>
                                                    </div>
                                                    <div className="content">
                                                        {data.institution?.name}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.position"/>
                                                    </div>
                                                    <div className="content breaker">
                                                        {data.position}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.email"/>
                                                    </div>
                                                    <div className="content breaker">
                                                        {data.email}
                                                    </div>
                                                </div>
                                                <div className="entry">
                                                    <div className="label">
                                                        <Translate id="contacts.profile.dialog.contact.phone"/>
                                                    </div>
                                                    <div className="content">
                                                        {data.phone}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                    {['investor'].includes(data.type) && (
                                        <div className="institution-info">
                                            <BorderWithDot borderClass="d-lg-none"/>
                                            <div className="entry">
                                                <div className="label">
                                                    <Translate id="contacts.profile.dialog.institutionInfo.type"/>
                                                </div>
                                                <div className="content breaker">
                                                    {data.institution?.institutionalType}
                                                </div>
                                            </div>
                                            <div className="entry">
                                                <div className="label">
                                                    <Translate id="contacts.profile.dialog.institutionInfo.assetsUnderManagement"/>
                                                </div>
                                                <div className="content">
                                                    {data.assetsUnderManagement}
                                                </div>
                                            </div>
                                            <div className="entry">
                                                <div className="label">
                                                    <Translate id="contacts.profile.dialog.institutionInfo.focus"/>
                                                </div>
                                                <div className="content breaker">
                                                    {data.focus}
                                                </div>
                                            </div>
                                            <div className="entry">
                                                <div className="label">
                                                    <Translate id="contacts.profile.dialog.institutionInfo.assetClasses"/>
                                                </div>
                                                <div className="content">
                                                    {data.assetClasses}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {['investor', 'multiplier'].includes(data.type) && (
                                    <Linkify properties={{target: '_blank', rel: 'noopener noreferrer'}}>
                                        <div className="description" dangerouslySetInnerHTML={{__html: data.institution?.overview}}/>
                                    </Linkify>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Overlay>}
        </Fragment>
    )
}

export default withLocalize(ProfileButton);

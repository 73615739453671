import axios from 'axios';
import {store} from '../store';

class OrderApi {
  static create = (contact, online, city, location, type, targetGroup, startDate, startTime, endTime, representatives, language, releaseImmediately, releaseDate, releaseTime, noRelease, color, disclaimerAccepted) => {
    return axios.post(`${process.env.REACT_APP_API_URL}order`, {
      contact,
      online,
      city,
      location,
      type,
      targetGroup,
      startDate,
      startTime,
      endTime,
      representatives,
      language,
      releaseImmediately,
      releaseDate,
      releaseTime,
      noRelease,
      color,
      disclaimerAccepted,
      company: store.getState()?.profile?.selectedCompanyId,
      //devMode: true
    })
  };
}

export default OrderApi;
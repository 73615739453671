import React, {Fragment, useState, useTransition} from 'react';
import {Overlay} from "react-portal-overlay";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import OrderApi from "../../api/OrderApi";
import moment from "moment";
import FieldError from "./FieldError";
import GfxBackIcon from "../../gfx/order/GfxBackIcon";
import GfxEventVirtual from "../../gfx/order/GfxEventVirtual";
import GfxEventLocal from "../../gfx/order/GfxEventLocal";
import BorderWithDotNew from "../border/BorderWithDotNew";
import GfxEventTypeVirtual from "../../gfx/order/GfxEventTypeVirtual";
import GfxEventTypeLocal from "../../gfx/order/GfxEventTypeLocal";
import GfxArrowDown from "../../gfx/order/GfxArrowDown";
import gfx_hasErrors from '../../gfx/order/hasErrors.png';
import MaskedInput from "react-maskedinput";
import GfxNewEvent from "../../gfx/order/GfxNewEvent";
import CompanyChooser from "../topBar/CompanyChooser";
import ColorPicker from "../form/ColorPicker";
import {profileUpdateCompanyColorSuccess} from "../../store/profile/profileAction";


const Order = ({activeLanguage}) => {

  const dispatch = useDispatch();

  // disable feature for now
  const fieldTripsEnabled = false;

  const profileState = useSelector(state => state.profile);

  const defaultFormValues = {
    contact: profileState.name,
    online: true,
    location: '',
    city: null,
    type: null,
    targetGroupInvestor: false,
    targetGroupMultiplier: false,
    startDate: '',
    startTime: '',
    endTime: '',
    representativesCeo: false,
    representativesCfo: false,
    representativesIr: false,
    representativesOther: false,
    representativesOtherText: '',
    language: 'de',
    releaseImmediately: true,
    releaseDate: '',
    releaseTime: '',
    noRelease: false,
    color: profileState.company?.color,
    disclaimerAccepted: false,
  }

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showSelectCity, setShowSelectCity] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [color, setColor] = useState(profileState.company?.color);

  const handleOpen = () => {
    setFormValues(defaultFormValues);
    setColor(profileState.company?.color);
    setErrors({});
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  }

  const setFormValue = (key, value) => {
    setFormValues(fv => ({
      ...fv,
      [key]: value
    }));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    OrderApi.create(
      formValues.contact,
      formValues.online,
      formValues.type !== 'fieldTrip' ? formValues.city : null,
      formValues.type === 'fieldTrip' ? formValues.location : null,
      formValues.type,
      (formValues.targetGroupInvestor ? ['investor'] : []).concat(formValues.targetGroupMultiplier ? ['multiplier'] : '')?.join(','),
      formValues.startDate ? moment(formValues.startDate, 'LLL').format('YYYY-MM-DD') : null,
      formValues.startTime,
      formValues.endTime,
      (formValues.representativesCeo ? ['CEO'] : []).concat(formValues.representativesCfo ? ['CFO'] : []).concat(formValues.representativesIr ? ['IR'] : []).concat(formValues.representativesOther ? [formValues.representativesOtherText] : [])?.join(','),
      formValues.language,
      formValues.releaseImmediately,
      formValues.releaseDate ? moment(formValues.releaseDate, 'LLL').format('YYYY-MM-DD') : null,
      formValues.releaseTime,
      formValues.noRelease,
      color,
      formValues.disclaimerAccepted
    ).then(response => {
      setLoading(false);
      if (response.data.success) {
        handleClose();
        dispatch(profileUpdateCompanyColorSuccess(color));
        setSuccessOpen(true);
      } else {
        setErrors(response.data.errors);
      }
    })
  }

  return (
    <Fragment>
      <div className="Meeting order-meeting-helper">
        <div className="container">
          <div className="row flex-nowrap">
            <div className="col-left col-2 d-md-none d-lg-block col-lg-1"/>
            <div className="col-container col-4 col-md-6 col-lg-4 pl-0">
              <div className="company-chooser-container d-none d-md-flex d-lg-none">
                <CompanyChooser/>
              </div>
              <button onClick={handleOpen} type="button" className="order-openButton">
                <GfxNewEvent/>
                <span className="d-lg-none"><Translate id="order.button"/></span>
                <span className="d-none d-lg-inline"><Translate id="order.buttonLg"/></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Overlay open={open || successOpen} animation={{duration: 100, easing: 'ease'}} className={`${successOpen ? 'overlay-order-success-outer' : 'overlay-order-outer'} overlay-container`}>
        {successOpen ? (
            <div className="overlay-order-success">
              <div className="text-right">
                <button onClick={handleSuccessClose} type="button" className="button-close">
                  <GfxBackIcon/>
                  <span><Translate id="order.overlay.success.close"/></span>
                </button>
              </div>
              <div className="content-container">
                <h4>
                  <Translate id="order.overlay.success.title"/>
                </h4>
                <p>
                  <Translate id="order.overlay.success.text"/>
                </p>
              </div>
            </div>
        ) : (
            <div className="overlay-order">
              <div className="text-right d-md-none">
                <button onClick={handleClose} type="button" className="button-close">
                  <GfxBackIcon/>
                  <span><Translate id="order.overlay.cancel"/></span>
                </button>
              </div>
              <div className="tab-container-outer d-block d-md-flex">
                <div className="tab-container">
                  <button className={`tab-button${formValues.online ? ' active' : ''}`}
                          onClick={() => setFormValue('online', true)}>
                    <GfxEventVirtual/>
                    <span><Translate id="order.overlay.onlineEvent" options={{renderInnerHtml: true}}/></span>
                  </button>
                  <button className={`tab-button${formValues.online ? '' : ' active'}`}
                          onClick={() => setFormValue('online', false)}>
                    <GfxEventLocal/>
                    <span><Translate id="order.overlay.localEvent" options={{renderInnerHtml: true}}/></span>
                  </button>
                </div>
                <div className="buttonClose-outer d-none d-md-block">
                  <button onClick={handleClose} type="button" className="button-close">
                    <GfxBackIcon/>
                    <span><Translate id="order.overlay.cancel"/></span>
                  </button>
                </div>
              </div>
              <div className="content-container">
                <FieldError errors={errors} fieldKey="online"/>
                <form onSubmit={onSubmit}>
                  <h4>
                    <Translate id={`order.overlay.title${formValues.online ? 'Online' : ''}`}/>
                  </h4>
                  <BorderWithDotNew/>
                  <div className="form-group fg-company">
                    <label className="fg-label"><Translate id="order.overlay.label.institute"/></label>
                    <div className="value">
                      {profileState.company?.name}
                    </div>
                  </div>
                  <div className="form-group fg-contact">
                    <label className="fg-label"><Translate id="order.overlay.label.contact"/></label>
                    <FieldError errors={errors} fieldKey="contact" showEmpty/>
                    <input type="text" className="input-text" value={formValues.contact}
                           onChange={e => setFormValue('contact', e.target.value)}/>
                  </div>
                  <div className="group g-type">
                    <FieldError errors={errors} fieldKey="type"/>
                    <input type="radio" name="type" value="roundtable" id="radioRoundtable" checked={formValues.type === 'roundtable'}
                           onChange={e => setFormValue('type', e.target.value)}/>
                    <label className={`type-radio-outer${!!profileState.coverage?.find(c => c.text === 'Round Tables') ? ' isKmb' : ''}`} htmlFor="radioRoundtable">
                      {formValues.online ? <GfxEventTypeVirtual/> : <GfxEventTypeLocal/>}
                      <Translate id={`order.overlay.value.type.roundTable${formValues.online ? 'Online' : ''}`}
                                 options={{renderInnerHtml: true}}/>
                      <div className="kmb-info">
                        <Translate id="order.overlay.isKmb"/>
                      </div>
                    </label>
                    {formValues.online && (
                        <div className="earningsCall">
                          <input type="radio" name="type" value="earningsCall" id="radioEarningsCall" checked={formValues.type === 'earningsCall'}
                                 onChange={e => setFormValue('type', e.target.value)}/>
                          <label className={`type-radio-outer${!!profileState.coverage?.find(c => c.text === 'Earnings Calls') ? ' isKmb' : ''}`} htmlFor="radioEarningsCall">
                            <GfxEventTypeVirtual/>
                            <Translate id="order.overlay.value.type.earningsCall" options={{renderInnerHtml: true}}/>
                            <div className="kmb-info">
                              <Translate id="order.overlay.isKmb"/>
                            </div>
                          </label>
                        </div>
                    )}
                    <input type="radio" name="type" value="roadshow" id="radioRoadshow" checked={formValues.type === 'roadshow'}
                           onChange={e => setFormValue('type', e.target.value)}/>
                    <label className={`type-radio-outer${!!profileState.coverage?.find(c => c.text === 'Roadshows') ? ' isKmb' : ''}${formValues.online ? ' last' : ''}`} htmlFor="radioRoadshow">
                      {formValues.online ? <GfxEventTypeVirtual/> : <GfxEventTypeLocal/>}
                      <Translate id={`order.overlay.value.type.roadshow${formValues.online ? 'Online' : ''}`}
                                 options={{renderInnerHtml: true}}/>
                      <div className="kmb-info">
                        <Translate id="order.overlay.isKmb"/>
                      </div>
                    </label>
                    {!formValues.online && fieldTripsEnabled && (
                      <Fragment>
                        <input type="radio" name="type" value="fieldTrip" id="radioFieldTrip" checked={formValues.type === 'fieldTrip'}
                               onChange={e => setFormValue('type', e.target.value)}/>
                        <label className={`type-radio-outer${!!profileState.coverage?.find(c => c.text === 'Field Trips') ? ' isKmb' : ''} last`} htmlFor="radioFieldTrip">
                          <GfxEventTypeLocal/>
                          <Translate id="order.overlay.value.type.fieldTrip" options={{renderInnerHtml: true}}/>
                          <div className="kmb-info">
                            <Translate id="order.overlay.isKmb"/>
                          </div>
                        </label>
                      </Fragment>
                    )}
                  </div>
                  {!formValues.online && (
                    <div>
                      <div className="form-group fg-city">
                        <label className="fg-label"><Translate id="order.overlay.label.city"/></label>
                        <FieldError errors={errors} fieldKey="city" showEmpty/>
                        {formValues.type === 'fieldTrip' && fieldTripsEnabled ? (
                          <input type="text" className="input-text" value={formValues.location}
                                 onChange={e => setFormValue('location', e.target.value)}/>
                        ) : (
                          <div className="dropdown">
                            <button className={`dropdown-toggle${showSelectCity ? ' active' : ''}`} type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-expanded="false" onClick={() => setShowSelectCity(!showSelectCity)}>
                              <span>{formValues.city ? <Translate id={`order.overlay.value.city.${formValues.city}`}/> : <Translate id="order.overlay.value.city.pleaseSelect"/>}</span>
                              <GfxArrowDown/>
                            </button>
                            <div className={`dropdown-menu${showSelectCity ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                              <Translate>
                                {({ translate }) =>
                                  ['Düsseldorf', 'Frankfurt', 'Hamburg', 'München', 'Köln', 'Luxemburg', 'Schweiz', 'Österreich'].sort((a, b) => translate(`order.overlay.value.city.${a}`).localeCompare(translate(`order.overlay.value.city.${b}`))).filter(c => c !== formValues.city).map((city, cI) => (
                                    <button className="dropdown-item" key={cI} onClick={() => {setShowSelectCity(false); setFormValue('city', city)}} type="button">
                                      <Translate id={`order.overlay.value.city.${city}`}/>
                                    </button>
                                  ))
                                }
                              </Translate>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group fg-representatives">
                    <label className="fg-label"><Translate id="order.overlay.label.representatives"/></label>
                    <FieldError errors={errors} fieldKey="representatives" showEmpty/>
                    <div className="checkbox-outer short d-inline-flex">
                      <input type="checkbox" checked={formValues.representativesCeo} id="checkboxCeo"
                             onChange={e => setFormValue('representativesCeo', e.target.checked)}/>
                      <label htmlFor="checkboxCeo"><Translate id="order.overlay.value.representatives.ceo"/></label>
                    </div>
                    <div className="checkbox-outer short d-inline-flex">
                      <input type="checkbox" checked={formValues.representativesCfo} id="checkboxCfo"
                             onChange={e => setFormValue('representativesCfo', e.target.checked)}/>
                      <label htmlFor="checkboxCfo"><Translate id="order.overlay.value.representatives.cfo"/></label>
                    </div>
                    <div className="checkbox-outer short d-inline-flex">
                      <input type="checkbox" checked={formValues.representativesIr} id="checkboxIr"
                             onChange={e => setFormValue('representativesIr', e.target.checked)}/>
                      <label htmlFor="checkboxIr"><Translate id="order.overlay.value.representatives.ir"/></label>
                    </div>
                    <div className="checkbox-outer representatives-other-container">
                      <input type="checkbox" checked={formValues.representativesOther} id="checkboxOther"
                             onChange={e => setFormValue('representativesOther', e.target.checked)}/>
                      <label htmlFor="checkboxOther">&nbsp;</label>
                      {/* ToDo placeholder should rather be translated with key "order.overlay.value.representatives.other.placeholder" } */}
                      <input type="text" disabled={!formValues.representativesOther} placeholder="Position"
                             value={formValues.representativesOtherText} className="input-text"
                             onChange={e => setFormValue('representativesOtherText', e.target.value)}/>
                    </div>
                  </div>
                  <div className="form-group fg-targetGroup">
                    <label className="fg-label"><Translate id="order.overlay.label.targetGroup"/></label>
                    <FieldError errors={errors} fieldKey="targetGroup" showEmpty/>
                    <div className="checkbox-outer cbo-investor">
                      <input type="checkbox" checked={formValues.targetGroupInvestor} id="checkboxInvestor"
                             onChange={e => setFormValue('targetGroupInvestor', e.target.checked)}/>
                      <label htmlFor="checkboxInvestor"><Translate id="order.overlay.value.targetGroup.investor"/></label>
                    </div>
                    <div className="checkbox-outer">
                      <input type="checkbox" checked={formValues.targetGroupMultiplier} id="checkboxMultiplier"
                             onChange={e => setFormValue('targetGroupMultiplier', e.target.checked)}/>
                      <label htmlFor="checkboxMultiplier"><Translate id="order.overlay.value.targetGroup.multiplier"/></label>
                    </div>
                  </div>
                  <div className="form-group fg-date">
                    <FieldError errors={errors} fieldKey="startDate"/>
                    <FieldError errors={errors} fieldKey="startTime"/>
                    <FieldError errors={errors} fieldKey="endTime"/>
                    <div className="sub-form-group sfg-startDate">
                      <label className="sfg-label"><Translate id="order.overlay.label.startDate"/></label>
                      <MaskedInput mask={activeLanguage?.code === 'de' ? "11.11.1111" : "11/11/1111"} placeholder={activeLanguage?.code === 'de' ? "DD.MM.YYYY" : "DD/MM/YYYY"} className="input-text" value={formValues.startDate}
                                   onChange={e => setFormValue('startDate', e.target.value)}/>
                    </div>
                    <div className="d-flex start-end-time-outer">
                      <div className="sub-form-group sfg-startTime">
                        <label className="sfg-label"><Translate id="order.overlay.label.startTime"/></label>
                        <MaskedInput mask="11:11" placeholder="HH:mm" className="input-text" value={formValues.startTime}
                                     onChange={e => setFormValue('startTime', e.target.value)}/>
                      </div>
                      <div className="sub-form-group sfg-endTime">
                        <label className="sfg-label"><Translate id="order.overlay.label.endTime"/></label>
                        <MaskedInput mask="11:11" placeholder="HH:mm" className="input-text" value={formValues.endTime}
                                     onChange={e => setFormValue('endTime', e.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className="hr-break-line"/>
                  <div className="form-group fg-language">
                    <label className="fg-label"><Translate id="order.overlay.label.language"/></label>
                    <div className="language-switch">
                      <div className={`ls-label${formValues.language === 'de' ? ' active' : ''}`}
                           onClick={() => setFormValue('language', 'de')}>
                        <Translate id="order.overlay.value.language.de"/>
                      </div>
                      <div className={`switch${formValues.language === 'en' ? ' right' : ''}`}
                           onClick={() => setFormValue('language', formValues.language === 'de' ? 'en' : 'de')}>
                        <div className="switch-inner"/>
                      </div>
                      <div className={`ls-label${formValues.language === 'en' ? ' active' : ''}`}
                           onClick={() => setFormValue('language', 'en')}>
                        <Translate id="order.overlay.value.language.en"/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group fg-release">
                    <label className="fg-label"><Translate id="order.overlay.label.release"/></label>
                    <FieldError errors={errors} fieldKey="release" showEmpty/>
                    <div className="checkbox-outer cbo-immediately">
                      <input type="checkbox" checked={!formValues.noRelease && formValues.releaseImmediately} id="checkboxImmediately"
                             onChange={e => { setFormValue('releaseImmediately', true); setFormValue('noRelease', false) }}/>
                      <label htmlFor="checkboxImmediately"><Translate id="order.overlay.value.release.immediately"/></label>
                    </div>
                    <div className="checkbox-outer cbo-notImmediately">
                      <input type="checkbox" checked={!formValues.noRelease && !formValues.releaseImmediately} id="checkboxNotImmediately"
                             onChange={e => { setFormValue('releaseImmediately', false); setFormValue('noRelease', false) }}/>
                      <label htmlFor="checkboxNotImmediately"><Translate id="order.overlay.value.release.specific"/></label>
                    </div>
                    <div className="d-flex d-md-inline-flex">
                      <div className="sub-form-group sfg-releaseDate">
                        <MaskedInput mask={activeLanguage?.code === 'de' ? "11.11.1111" : "11/11/1111"} placeholder={activeLanguage?.code === 'de' ? "DD.MM.YYYY" : "DD/MM/YYYY"} className="input-text" value={formValues.releaseDate}
                                     onChange={e => setFormValue('releaseDate', e.target.value)} disabled={formValues.noRelease || formValues.releaseImmediately}/>
                        <label className="sfg-label"><Translate id="order.overlay.label.releaseDate"/></label>
                      </div>
                      <div className="sub-form-group sfg-releaseTime">
                        <MaskedInput mask="11:11" placeholder="HH:mm" className="input-text" value={formValues.releaseTime}
                                     onChange={e => setFormValue('releaseTime', e.target.value)} disabled={formValues.noRelease || formValues.releaseImmediately}/>
                        <label className="sfg-label"><Translate id="order.overlay.label.releaseTime"/></label>
                      </div>
                    </div>
                    <div className="checkbox-outer cbo-noRelease">
                      <input type="checkbox" checked={formValues.noRelease} id="checkboxNoRelease"
                             onChange={e => { setFormValue('noRelease', true); setFormValue('releaseImmediately', false) }}/>
                      <label htmlFor="checkboxNoRelease"><Translate id="order.overlay.value.release.noRelease"/></label>
                    </div>
                    <FieldError errors={errors} fieldKey="releaseDate"/>
                    <FieldError errors={errors} fieldKey="releaseTime"/>
                  </div>
                  <div className="hr-break-line"/>
                  <div className="form-group fg-color">
                    <label className="fg-label d-none d-lg-block"><Translate id="order.overlay.label.color"/></label>
                    <div className="picker-helper d-flex flex-column flex-md-row align-items-lg-center">
                      <ColorPicker value={color} onChange={val => setColor(val)}/>
                      <div>
                        <p>
                          <Translate id="order.overlay.infoColor"/>
                          <button type="button" onClick={() => setColor('#055C60')} className="set-default-color d-none d-lg-inline pl-2">
                            <Translate id="order.overlay.colorSetDefault"/>
                          </button>
                        </p>
                        <button type="button" onClick={() => setColor('#055C60')} className="set-default-color d-lg-none">
                          <Translate id="order.overlay.colorSetDefault"/>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hr-break-line"/>
                  <div className="info-text">
                    <Translate id={`order.overlay.infoText${formValues.online ? 'Online' : ''}`}/>
                  </div>
                  <div className="form-group fg-disclaimerAccepted">
                    <div className="checkbox-outer">
                      <input type="checkbox" checked={formValues.disclaimerAccepted} id="checkboxDisclaimerAccepted"
                             onChange={e => setFormValue('disclaimerAccepted', e.target.checked)}/>
                      <label htmlFor="checkboxDisclaimerAccepted"><Translate id="order.overlay.value.disclaimer" options={{renderInnerHtml: true}}/></label>
                    </div>
                  </div>
                  <FieldError errors={errors} fieldKey="disclaimerAccepted"/>
                  <div className="d-flex justify-content-end align-items-center submit-container">
                    {Object.getOwnPropertyNames(errors)?.length > 0 && <img src={gfx_hasErrors} alt="" className="img-hasErrors"/>}
                    <button type="submit" className={`button-submit${loading ? ' loading' : ''}`} disabled={loading}>
                      <Translate id="order.overlay.submit"/>
                    </button>
                  </div>
                </form>
              </div>
              <div className="text-right d-md-none">
                <button onClick={handleClose} type="button" className="button-close button-close-bottom">
                  <GfxBackIcon/>
                  <span><Translate id="order.overlay.cancel"/></span>
                </button>
              </div>
            </div>
        )}
      </Overlay>
    </Fragment>
  )
}

export default withLocalize(Order);

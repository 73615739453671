import {
    AUTHORIZATION_LOGIN_SUCCESS,
    AUTHORIZATION_LOGOUT, AUTHORIZATION_SET_ACCESS_TOKEN
} from "../../config/actions";

export const authorizationLoginSuccess = (bearerToken, username, roles, refreshToken) => {
    return {
        type: AUTHORIZATION_LOGIN_SUCCESS,
        bearerToken,
        username,
        roles,
        refreshToken
    }
};

export const authorizationSetAccessToken = (bearerToken) => {
    return {
        type: AUTHORIZATION_SET_ACCESS_TOKEN,
        bearerToken
    }
};

export const authorizationLogout = () => {
    return {
        type: AUTHORIZATION_LOGOUT
    }
};

import React, { useEffect, useState } from 'react';
import { Overlay } from 'react-portal-overlay';
import { getTranslate, Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { connect } from 'react-redux';
import moment from 'moment';
import PrintArrow from '../../../gfx/PrintArrow';
import { ROUTE_CONTACTS_PRINT } from '../../../config/routes';
import PrintIcon from '../../../gfx/PrintIcon';
import FilterIcon from '../../../gfx/FilterIcon';
import ContactsTextSearch from '../contacts/ContactsTextSearch';
import CheckButton from '../../../elements/form/CheckButton';
import ParticipantsDefault from './ParticipantsDefault';
import ParticipantsConference from './ParticipantsConference';
import ParticipantsRoadshow from './ParticipantsRoadshow';
import EventSchedule from '../../../elements/pdf/roadshowSchedule/EventSchedule';

function ParticipantsOverlay({
  translate,
  event,
  isOpen,
  onClose,
  past,
}) {
  const [allPersons, setAllPersons] = useState([]);
  const [personsToShow, setPersonsToShow] = useState([]);

  const [textSearchIsOpen, setTextSearchIsOpen] = useState(false);
  const [textSearch, setTextSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [availableFocuses, setAvailableFocuses] = useState([]);
  const [availableAums, setAvailableAums] = useState([]);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedFocuses, setSelectedFocuses] = useState([]);
  const [selectedAums, setSelectedAums] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [showPrintDropdown, setShowPrintDropdown] = useState(false);

  const onChangeTextSearch = (newTextSearchIsOpen) => {
    setTextSearchIsOpen(newTextSearchIsOpen);
  };
  useEffect(() => {
    let pts = [];
    if (event.participants) {
      pts = pts.concat(event.participants);
    }
    if (event.appliers) {
      pts = pts.concat(event.appliers);
    }
    if (pts.length === 0 && event.type === 'roadshow') {
      event.slots.forEach((es) => {
        pts = pts.concat(es.participants, es.appliers);
      });
    }

    setAllPersons(pts);

    // get available filters
    setAvailableTypes([...new Set(pts.map((i) => i.userType))]);
    setAvailableFocuses([...new Set(pts.filter((i) => !!i.focus)
      ?.map((i) => i.focus?.split(', '))
      ?.flat())]?.filter((i) => i !== 'Sondersituationen')
      ?.map((i) => i.replaceAll(' ', '')));
    setAvailableAums([...new Set(pts.filter((i) => !!i.aum)
      ?.map((i) => i.aum?.split(', '))
      ?.flat())]);
    setAvailableRegions([...new Set(pts.filter((i) => !!i.region)
      ?.map((i) => i.region?.split(', '))
      ?.flat())]);

    // filter by type
    if (selectedTypes && selectedTypes.length > 0) pts = pts.filter((item) => selectedTypes.includes(item.userType));

    // filter by focus
    if (selectedFocuses && selectedFocuses.length > 0) {
      pts = pts.filter((item) => item.focus && item.focus?.split(',')
        .map((i) => i.replaceAll(' ', ''))
        .find((f) => selectedFocuses.includes(f)));
    }

    // filter by aum
    if (selectedAums && selectedAums.length > 0) {
      pts = pts.filter((item) => item.aum && item.aum?.split(',')
        .find((a) => selectedAums.includes(a)));
    }

    // filter by region
    if (selectedRegions && selectedRegions.length > 0) {
      pts = pts.filter((item) => item.region && item.region?.split(',')
        .find((r) => selectedRegions.includes(r)));
    }

    // filter by search string
    if (textSearch && textSearch.length > 0) {
      pts = pts.filter((item) => !textSearch.split(' ')
        .find((textSearchItem) => !`${item.name}${item.institution ? item.institution.name : ''}`.toLowerCase()
          .includes(textSearchItem.toLowerCase())));
    }

    // unique filter
    pts = [...new Map(pts.map((item) => [`${item.appId}-${item.itemId}`, item])).values()];

    setPersonsToShow(pts);
  }, [event, selectedTypes, selectedFocuses, selectedAums, selectedRegions, textSearch]);

  const toggleFilter = (filterName, item) => {
    switch (filterName) {
      case 'type':
        if (!selectedTypes.includes(item)) {
          setSelectedTypes([...selectedTypes, item]);
        } else {
          setSelectedTypes(selectedTypes.filter((fi) => fi !== item));
        }
        break;
      case 'focus':
        if (!selectedFocuses.includes(item)) {
          setSelectedFocuses([...selectedFocuses, item]);
        } else {
          setSelectedFocuses(selectedFocuses.filter((fi) => fi !== item));
        }
        break;
      case 'aum':
        if (!selectedAums.includes(item)) {
          setSelectedAums([...selectedAums, item]);
        } else {
          setSelectedAums(selectedAums.filter((fi) => fi !== item));
        }
        break;
      case 'region':
        if (!selectedRegions.includes(item)) {
          setSelectedRegions([...selectedRegions, item]);
        } else {
          setSelectedRegions(selectedRegions.filter((fi) => fi !== item));
        }
        break;
      default:
        break;
    }
  };

  const hasFilters = selectedTypes?.length > 0 || selectedFocuses?.length > 0 || selectedAums?.length > 0 || selectedRegions?.length > 0;

  return (
    <Overlay
      open={isOpen}
      animation={{
        duration: 100,
        easing: 'ease',
      }}
      className="overlay-container"
    >
      <div className="participants-dialog-outer">
        <div className="participants-dialog">
          <button onClick={onClose}>
            <Translate id="meeting.participants.dialog.back" />
          </button>
          <div className="join-content">
            <div className="d-flex">
              <div className="title">
                {event.title}
              </div>
              <div className="ml-auto d-none d-md-flex">
                {event.type === 'roadshow' && (
                  <div className="print-dropdown dropdown mr-1">
                    <PDFDownloadLink
                      document={(
                        <EventSchedule
                          event={event}
                          personsToShow={personsToShow}
                          labels={{
                            'header.name': translate('meeting.participants.dialog.tableHeader.name'),
                            'header.institute': translate('meeting.participants.dialog.tableHeader.institute'),
                          }}
                        />
                      )}
                      fileName={moment(event.date?.start)
                        .format('L')
                        .concat(' - ', event.title, '.pdf')}
                    >
                      <button
                        className={'dropdown-toggle active\''}
                        type="button"
                        id="printSchedule"
                      >
                        <div className="mr-2">
                          <PrintIcon />
                        </div>
                        <Translate id="meeting.participants.dialog.printSchedule" />
                      </button>
                    </PDFDownloadLink>
                  </div>
                )}
                <div className="print-dropdown dropdown">
                  <button
                    className={`dropdown-toggle${showPrintDropdown ? ' active' : ''}`}
                    type="button"
                    id="printDropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => setShowPrintDropdown(!showPrintDropdown)}
                  >
                    <div className="arrow-container">
                      <PrintArrow />
                    </div>
                    <span>
                      <Translate id="meeting.participants.dialog.printDropdown.title" />
                    </span>
                  </button>
                  <div
                    className={`dropdown-menu${showPrintDropdown ? ' show' : ''}`}
                    aria-labelledby="printDropdownMenuButton"
                  >
                    <Link
                      to={allPersons?.length > 0 ? generatePath(ROUTE_CONTACTS_PRINT, {
                        eventAppId: event.appId,
                        eventItemId: event.itemId,
                        itemIds: allPersons?.map((p) => p.itemId)
                          ?.join(','),
                      }) : ''}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                      onClick={() => setShowPrintDropdown(false)}
                    >
                      <PrintIcon />
                      <Translate id="meeting.participants.dialog.printDropdown.printAll" />
                    </Link>
                    <Link
                      to={personsToShow?.length > 0 ? generatePath(ROUTE_CONTACTS_PRINT, {
                        eventAppId: event.appId,
                        eventItemId: event.itemId,
                        itemIds: personsToShow?.map((p) => p.itemId)
                          ?.join(','),
                      }) : ''}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                      onClick={() => setShowPrintDropdown(false)}
                    >
                      <PrintIcon />
                      <Translate id="meeting.participants.dialog.printDropdown.printFiltered" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-filter-row-top">
              <div className="filter-toggle-container">
                <button
                  className={`btn-filter-toggle${showFilters ? ' active' : ''}`}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <FilterIcon />
                  {showFilters ? (
                    <Translate id="contacts.filters.hideFilters" />
                  ) : (
                    <Translate id="contacts.filters.showFilters" />
                  )}
                </button>
              </div>
              <ContactsTextSearch
                textSearchIsOpen={textSearchIsOpen}
                onNameChange={onChangeTextSearch}
                onTextSearch={(val) => setTextSearch(val)}
              />
            </div>
            <div className={`filters-container${!showFilters ? ' d-none' : ''}`}>
              <div className="filter-container">
                <h5>
                  <Translate id="contacts.filters.type.label" />
                  :
                </h5>
                {availableTypes?.sort((a, b) => (a === 'investor' ? 1 : a === 'multiplier' ? 2 : a === 'earningsCallParticipant' ? 3 : 4) - (b === 'investor' ? 1 : b === 'multiplier' ? 2 : b === 'earningsCallParticipant' ? 3 : 4))
                  ?.map((item, iI) => (
                    <CheckButton
                      label={<Translate id={`contacts.filters.type.value.${item}`} />}
                      checked={selectedTypes.includes(item)}
                      key={iI}
                      onChange={() => toggleFilter('type', item)}
                    />
                  ))}
              </div>
              <div className="filter-container">
                <h5>
                  <Translate id="contacts.filters.focus.label" />
                  :
                </h5>
                {availableFocuses?.sort((a, b) => (a === 'MicroCaps' ? 1 : a === 'SmallCaps' ? 2 : a === 'MidCaps' ? 3 : a === 'LargeCaps' ? 4 : 5) - (b === 'MicroCaps' ? 1 : b === 'SmallCaps' ? 2 : b === 'MidCaps' ? 3 : b === 'LargeCaps' ? 4 : 5))
                  ?.map((item, iI) => (
                    <CheckButton
                      label={item}
                      checked={selectedFocuses.includes(item)}
                      key={iI}
                      onChange={() => toggleFilter('focus', item)}
                    />
                  ))}
              </div>
              <div className="filter-container">
                <h5>
                  <Translate id="contacts.filters.aum.label" />
                  :
                </h5>
                {availableAums?.sort((a, b) => (['< 50 Mio. Euro', '< 50m Euro'].includes(a) ? 1 : ['50 - 500 Mio. Euro', '50 - 500m Euro'].includes(a) ? 2 : ['> 500 Mio. Euro', '> 500m Euro'].includes(a) ? 3 : 4) - (['< 50 Mio. Euro', '< 50m Euro'].includes(b) ? 1 : ['50 - 500 Mio. Euro', '50 - 500m Euro'].includes(b) ? 2 : ['> 500 Mio. Euro', '> 500m Euro'].includes(b) ? 3 : 4))
                  ?.map((item, iI) => (
                    <CheckButton
                      label={item}
                      checked={selectedAums.includes(item)}
                      key={iI}
                      onChange={() => toggleFilter('aum', item)}
                    />
                  ))}
              </div>
              <div className="filter-container">
                <h5>
                  <Translate id="contacts.filters.region.label" />
                  :
                </h5>
                {availableRegions?.sort((a, b) => (['Andere', 'Sonstige', 'Other'].includes(a) ? 1 : ['Andere', 'Sonstige', 'Other'].includes(b) ? -1 : (a.localeCompare(b))))
                  ?.map((item, iI) => (
                    <CheckButton
                      label={item}
                      checked={selectedRegions.includes(item)}
                      key={iI}
                      onChange={() => toggleFilter('region', item)}
                    />
                  ))}
              </div>
            </div>
            {!['conference', 'roadshow'].includes(event.type) && (
              <ParticipantsDefault personsToShow={personsToShow} />
            )}
            {event.type === 'conference' && (
              <ParticipantsConference
                personsToShow={personsToShow}
                event={event}
                hasFilters={hasFilters}
              />
            )}
            {event.type === 'roadshow' && (
              <ParticipantsRoadshow
                personsToShow={personsToShow}
                event={event}
                hasFilters={hasFilters}
                past={past}
              />
            )}
          </div>
        </div>
      </div>
    </Overlay>
  );
}

const mapStateToProps = (state) => ({ translate: getTranslate(state.localize) });
export default connect(mapStateToProps)(ParticipantsOverlay);

import React, {useEffect, useState, Fragment} from 'react';
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {Translate} from "react-localize-redux";
import gfx_logo from "../../../../gfx/logo_connect.svg";
import BorderWithDotNew from "../../../../elements/border/BorderWithDotNew";
import gfx_dummy from "../../../../gfx/Dummy.jpg";
import ContactsApi from "../../../../api/ContactsApi";

const ContactsPrint = () => {

    const eventsState = useSelector(state => state.events);

    const {eventAppId, eventItemId, itemIds} = useParams();

    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (eventAppId && eventItemId) {
            setEvent(eventsState.items?.find(e => e.appId === parseInt(eventAppId) && e.itemId === parseInt(eventItemId)));
        } else {
            setEvent(null);
        }
    }, [eventAppId, eventItemId, eventsState.items]);



    useEffect(() => {
        if (event && itemIds) {
            ContactsApi.loadContactsProfile(itemIds?.split(',')?.map(id => parseInt(id)))
                .then(response => {
                    if (response.status === 200) {
                        setContacts(response.data.data);
                        setLoading(false);
                        setTimeout(() => {
                            window.print();
                        }, 500)
                        setTimeout(() => {
                            window.close();
                        }, 1000);
                    }
                })
        }
    }, [event, itemIds]);

    return loading ? null : (
        <div className="ContactsPrint">
            {contacts?.map((contact, cI) => (
                <div className="contact-page" key={cI}>
                    <div className="contact-page-inner">
                        <div className="title-container d-flex">
                            <h5 className="event-title mr-2">
                                <Translate id="contactsPrint.event.participants"/>{" "}
                                <Translate id={`contactsPrint.event.type.${event.type}`}/>:<br/>
                                {event.title}
                            </h5>
                            <img src={gfx_logo} alt="" className="logo ml-auto"/>
                        </div>
                        <div className="line-container">
                            <BorderWithDotNew/>
                        </div>
                        <div className="company-container">
                            <h2>
                                {contact.institution?.name}
                            </h2>
                            <h4>
                                <Translate id={`contactsPrint.company.type.${contact.type}`}/>
                            </h4>
                        </div>
                        <div className="line-container">
                            <BorderWithDotNew/>
                        </div>
                        <div className="profile-main-container d-flex">
                            <img src={contact?.image?.file?.id ? `${process.env.REACT_APP_API_URL}image/${contact.image.file.id}` : gfx_dummy} alt=""/>
                            <div className="profile profile-1">
                                {['investor', 'multiplier'].includes(contact.type) ? (
                                    <Fragment>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.contactPerson"/>
                                            </div>
                                            <div className="content">
                                                {contact.name}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.position"/>
                                            </div>
                                            <div className="content breaker">
                                                {contact.position}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.phone"/>
                                            </div>
                                            <div className="content breaker">
                                                {contact.phone}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.email"/>
                                            </div>
                                            <div className="content breaker ">
                                                {contact.email}
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : null}
                                {['earningsCallParticipant'].includes(contact.type) && (
                                    <Fragment>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.name"/>
                                            </div>
                                            <div className="content">
                                                {contact.name}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.institution"/>
                                            </div>
                                            <div className="content">
                                                {contact.institution?.name}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.position"/>
                                            </div>
                                            <div className={`content ${['investor'].includes(contact.type) ? 'breaker' : ''}`}>
                                                {contact.position}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.email"/>
                                            </div>
                                            <div className={`content ${['investor'].includes(contact.type) ? 'breaker' : ''}`}>
                                                {contact.email}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="label">
                                                <Translate id="contactsPrint.person.phone"/>
                                            </div>
                                            <div className="content">
                                                {contact.phone}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                            {['investor'].includes(contact.type) && (
                                <div className="profile profile-2">
                                    <div className="entry">
                                        <div className="label">
                                            <Translate id="contactsPrint.person.institutionInfo.type"/>
                                        </div>
                                        <div className="content breaker">
                                            {contact.institution?.institutionalType}
                                        </div>
                                    </div>
                                    <div className="entry">
                                        <div className="label">
                                            <Translate id="contactsPrint.person.institutionInfo.assetsUnderManagement"/>
                                        </div>
                                        <div className="content">
                                            {contact.assetsUnderManagement}
                                        </div>
                                    </div>
                                    <div className="entry">
                                        <div className="label">
                                            <Translate id="contactsPrint.person.institutionInfo.focus"/>
                                        </div>
                                        <div className="content breaker">
                                            {contact.focus}
                                        </div>
                                    </div>
                                    <div className="entry">
                                        <div className="label">
                                            <Translate id="contactsPrint.person.institutionInfo.assetClasses"/>
                                        </div>
                                        <div className="content">
                                            {contact.assetClasses}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="info-text" dangerouslySetInnerHTML={{__html: contact?.institution?.overview}}/>
                        <div className="line-container">
                            <BorderWithDotNew/>
                        </div>
                        <div className="footer">
                            <Translate id="contactsPrint.footer"/>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
};

export default ContactsPrint;

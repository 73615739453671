import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Overlay} from "react-portal-overlay";
import {Translate} from "react-localize-redux";
import moment from "moment";
import BorderWithDot from "../../../elements/border/borderWithDot";
import FileUploadButton from "./FileUploadButton";
import FileDeleteButton from "./FileDeleteButton";
import gfx_download from '../../../gfx/download.svg';
import EventBus from "../../../elements/EventBus";


const FilesButton = ({evnt, past, className = ''}) => {

    const [open, setOpen] = useState(false);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        EventBus.on('brandClick', closeDialog);

        return () => {
            EventBus.remove('brandClick', closeDialog);
        }
    })


    return (
        <Fragment>
            <button className={`FilesButton ${className}`} onClick={openDialog}>
                <Translate id="meeting.files.button"/>
            </button>
            <Overlay open={open} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} onClose={closeDialog} className="overlay-container">
                <div className="files-dialog-outer">
                    <div className="files-dialog">
                        <button onClick={closeDialog}>
                            <Translate id="meeting.files.dialog.cancel"/>
                        </button>
                        <div className="files-content">
                            <div className="title-bar">
                                <div className="d-flex flex-column flex-md-row align-items-center title-button-container">
                                    <div className="title">
                                        <Translate id="meeting.files.dialog.title"/> <Translate id={`meeting.typeWithArticleSimple.${evnt?.type}`}/>:&nbsp;
                                        {evnt?.title}
                                    </div>
                                    <FileUploadButton evnt={evnt}/>
                                </div>
                                <BorderWithDot/>
                            </div>
                            <div className="files">
                                {evnt?.uploads && evnt?.uploads?.filter(u => u.title)?.map((upload, uI) => (
                                    <Fragment key={uI}>
                                        {uI !== 0 && <BorderWithDot/>}
                                        <div className="file">
                                            <div className="lastUpdate">
                                                <b>
                                                    <Translate id="meeting.files.dialog.lastUpdateAt"/>&nbsp;
                                                </b>
                                                {moment(upload.files?.length > 0 ? upload.files[0].updatedAt : new Date()).format('LL - LT')} <Translate id="meeting.files.dialog.dateH"/>
                                            </div>
                                            <h4>{upload.title}</h4>
                                            <div className="buttons">
                                                <FileDeleteButton evnt={evnt} uuid={upload.files?.length > 0 ? upload.files[0].uuid : null} disabled={upload.files?.length < 1 || past || upload.readOnly} itemId={upload.itemId}/>
                                                <FileUploadButton evnt={evnt} isReplace={true} itemId={upload.itemId} disabled={past || upload.readOnly}/>
                                                <a href={`${process.env.REACT_APP_API_URL}file/${upload.files?.length > 0 ? upload.files[0].uuid : ''}`} download target="_blank" rel="noopener noreferrer" className={`a-btn${upload.files?.length < 1 ? ' disabled' : ''}`}>
                                                    <img src={gfx_download} alt=""/>
                                                    <Translate id="meeting.files.dialog.download"/>
                                                </a>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        </Fragment>
    )
}

export default FilesButton;
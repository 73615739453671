import React from 'react';

const GfxArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="14" viewBox="0 0 25 14">
    <g id="Polygon_2" data-name="Polygon 2" transform="translate(25 14) rotate(180)" fill="none">
      <path d="M11.008,1.671a2,2,0,0,1,2.984,0l8.033,9A2,2,0,0,1,20.533,14H4.467a2,2,0,0,1-1.492-3.332Z" stroke="none"/>
      <path d="M 12.49989700317383 3.003052711486816 L 12.50000858306885 3.002927780151367 L 4.466909408569336 11.99999809265137 C 4.466909408569336 11.99999809265137 4.466909408569336 11.99999809265137 4.466899871826172 11.99999809265137 L 20.53308868408203 11.99999809265137 L 12.49989700317383 3.003052711486816 M 12.50000381469727 1.002932548522949 C 13.04714107513428 1.002932548522949 13.59427928924561 1.225587844848633 13.9918794631958 1.670897483825684 L 22.02496910095215 10.66796779632568 C 23.17570877075195 11.95679759979248 22.26088905334473 13.99999809265137 20.53310012817383 13.99999809265137 L 4.466899871826172 13.99999809265137 C 2.739109039306641 13.99999809265137 1.824289321899414 11.95679759979248 2.975028991699219 10.66796779632568 L 11.00812911987305 1.670897483825684 C 11.40572929382324 1.225587844848633 11.95286655426025 1.002932548522949 12.50000381469727 1.002932548522949 Z" stroke="none" fill="currentColor"/>
    </g>
  </svg>

)

export default GfxArrowDown;
import React, {useEffect, useState} from 'react';
import ParticipantsPersonRow from "./ParticipantsPersonRow";
import {Translate} from "react-localize-redux";

function ParticipantsDefault({personsToShow}) {

    const [sortColumn, setSortColumn] = useState('name');
    const [sortDir, setSortDir] = useState('asc');
    const [sortedPersonsToShow, setSortedPersonsToShow] = useState([]);

    useEffect(() => {
        switch (sortColumn) {
            case "name":
                setSortedPersonsToShow([...(sortDir === 'asc' ? personsToShow.sort((a, b) => a.name?.localeCompare(b.name)) : personsToShow.sort((a, b) => b.name?.localeCompare(a.name)))]);
                break;
            case "institution":
                setSortedPersonsToShow([...(sortDir === 'asc' ? personsToShow.sort((a, b) => (a.institution ? a.institution.name : '').localeCompare(b.institution ? b.institution.name : '')) : personsToShow.sort((a, b) => (b.institution ? b.institution.name : '').localeCompare(a.institution ? a.institution.name : '')))]);
                break;
            default:
                break;
        }
    }, [sortColumn, sortDir, personsToShow]);

    const toggleSort = (column) => {
        if (sortColumn === column) {
            setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDir('asc');
        }
    }

    return (
        <div className="ParticipantsDefault">
            <div className="participants-list-header d-none d-md-flex">
                <div className="col-name">
                    <div className="d-inline-block d-lg-none" onClick={() => toggleSort('name')}>
                        <Translate id="meeting.participants.dialog.tableHeader.nameAndInstitute"/>
                    </div>
                    <div className="d-none d-lg-inline-block" onClick={() => toggleSort('name')}>
                        <Translate id="meeting.participants.dialog.tableHeader.name"/>
                    </div>
                </div>
                <div className="col-institute d-none d-lg-block"
                     onClick={() => toggleSort('institution')}>
                    <div className="d-inline-block">
                        <Translate id="meeting.participants.dialog.tableHeader.institute"/>
                    </div>
                </div>
            </div>
            {sortedPersonsToShow.map((pts) => (
                <ParticipantsPersonRow person={pts} key={pts.itemId}/>
            ))}
        </div>
    )
}

export default ParticipantsDefault;

import gfx_logo from '../../gfx/logo_connect.svg';
import gfx_wrench from '../../gfx/error.svg';
import {Translate} from "react-localize-redux";
import LanguageToggle from "../../elements/LanguageToggle";
import {Link} from "react-router-dom";

const Error = () => {
  return (
    <div className="Error">
      <LanguageToggle className="d-none d-lg-block"/>
      <Link to="/" className="logo-container">
        <img src={gfx_logo} alt=""/>
      </Link>
      <div className="content-container">
        <div className="row-1">
          <div className="image-container text-center">
            <img src={gfx_wrench} alt=""/>
          </div>
          <div className="headline-container">
            <h2 className="text-center text-lg-left">
              <Translate id="error.title"/>
            </h2>
          </div>
        </div>
        <div className="row-2">
          <div className="text-container text-center text-lg-left">
            <Translate id="error.text"/>
          </div>
        </div>
      </div>
      <LanguageToggle className="d-lg-none"/>
    </div>
  )
}

export default Error;
import React, {useEffect, useState} from 'react';
import CompanyChooserArrow from "../../gfx/CompanyChooserArrow";
import {useDispatch, useSelector} from "react-redux";
import {profileSelectCompany} from "../../store/profile/profileAction";

const CompanyChooser = () => {

  const profileState = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (profileState.selectedCompanyId && (!selectedCompany || selectedCompany.id !== profileState.selectedCompanyId)) {
      setSelectedCompany(profileState.companies?.find(c => c.company?.id === profileState.selectedCompanyId));
    }
  }, [profileState, selectedCompany]);

  return profileState?.companies?.length > 1 ? (
    <div className="CompanyChooser dropdown">
      <button className={`dropdown-toggle${show ? ' active' : ''}`} type="button" id="companyDropdownMenuButton"
              data-toggle="dropdown" aria-expanded="false" onClick={() => setShow(!show)}>
        <span>{selectedCompany?.company?.shortName}</span>
        <CompanyChooserArrow/>
      </button>
      <div className={`dropdown-menu${show ? ' show' : ''}`} aria-labelledby="companyDropdownMenuButton">
        {profileState.companies.filter(c => c.company?.id !== profileState.selectedCompanyId).map((company, cI) => (
          <button className="dropdown-item" key={cI} onClick={() => {setShow(false); dispatch(profileSelectCompany(company.company?.id))}} type="button">
            {company.company?.shortName}
          </button>
        ))}
      </div>
    </div>
  ) : null;
}

export default CompanyChooser;
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import EventBus from "../../../elements/EventBus";
import IconFeedback from "../../../gfx/IconFeedback";

const FeedbackButton = ({meeting, extraClassName}) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        EventBus.on('brandClick', handleClose);

        return () => {
            EventBus.remove('brandClick', handleClose);
        }
    })

    return (
        <Fragment>
            <button className={`feedback-link ${extraClassName}`} onClick={handleOpen}>
                <IconFeedback/>
                <span className="svg-container d-none d-lg-flex">
                    <IconFeedback/>
                </span>
                <span className="label-container d-none d-md-inline-block">
                    <Translate id="meeting.feedbackLink"/>
                </span>
            </button>
            <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
                <div className="feedback-dialog-outer">
                    <div className="feedback-dialog">
                        <button onClick={handleClose}>
                            <Translate id="meeting.feedback.dialog.close"/>
                        </button>
                        <div className="feedback-content">
                            <div className="title">
                                <Translate id="meeting.feedback.dialog.title"/> <Translate id={`meeting.typeWithArticle.${meeting.type}`}/>:&nbsp;
                                {meeting.title}
                            </div>
                            {open ? (
                                <div className="iframe-container">
                                    <iframe src={meeting.feedbackLink}/>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Overlay>
        </Fragment>
    );
}

export default FeedbackButton;
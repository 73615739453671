import React from 'react';
import {withLocalize} from "react-localize-redux";

class Imprint extends React.Component {
  render() {
    return (<div className="Imprint">
      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-md-6 col-lg-5">
            {this.props.activeLanguage?.code === 'de' ? (<div className="content">
              <h3>
                Impressum
              </h3>
              <p>
                Diese Internetpräsenz wird bereitgestellt durch:
              </p>
              <p>
                <strong>Airtime Software AG</strong>
                Schauenburgerstraße 10<br/>
                20095 Hamburg<br/>
                Handelsregister: HRB 186873<br/>
                Registergericht: Hamburg
              </p>
              <p>
                <strong>Vertreten durch den Vorstand:</strong>
                Alexander Braun<br/>
                Philipp Saile
              </p>
              <p>
                <strong>Vorsitzender des Aufsichtsrats:</strong>
                Matthias-Christian Kurzrock
              </p>
              <p>
                <strong>Kontakt</strong>
                Telefon: +49 40 573 093 730<br/>
                E-Mail: info@airtime-software.com
              </p>
              <p>
                <strong>EU-Streitschlichtung</strong>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/>
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
              <p>
                <strong>Verbraucherstreitbeilegung/Universalschlichtungsstelle</strong>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
              </p>
              <p>
                <strong>Rechte</strong>
                Alle Rechte vorbehalten. Nachdruck (auch auszugsweise), kommerzielle Weiterverbreitung und Aufnahme
                in kommerzielle Datenbanken nur mit schriftlicher Genehmigung der Airtime Software AG.
              </p>
              <p>
                <strong>Google Analytics</strong>
                Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google
                Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die
                eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten
                Informationen über Ihre Benutzung dieser Webseite (einschließlich Ihrer IP-Adresse) werden an einen
                Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen
                benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten für die
                Webseitenbetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
                verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
                Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag
                von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in
                Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung
                Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können. Durch die Nutzung
                dieser Webseite erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der
                zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
              </p>
              <p>
                Der Erhebung und Nutzung Ihrer IP-Adresse durch Google Analytics können Sie jederzeit mit Wirkung
                für die Zukunft widersprechen. Nähere Informationen hierzu finden Sie unter <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>. Wir weisen Sie darauf
                hin, dass auf dieser Webseite Google Analytics um den Code „gat._anonymizeIp();“ erweitert wurde, um
                eine anonymisierte Erfassung von IP-Adressen zu gewährleisten.
              </p>
            </div>) : (<div className="content">
              <h3>
                Legal Notice
              </h3>
              <p>
                This website is provided by:
              </p>
              <p>
                Airtime Software AG<br/>
                Schauenburgerstraße 10<br/>
                20095 Hamburg<br/>
                Germany<br/>
                <br/>
                Airtime Software AG is registered at the district court of Hamburg under the registration number
                HRB 111052.<br/>
              </p>
              <p>
                <strong>Board of directors</strong>
                Alexander Braun<br/>
                Philipp Saile
              </p>
              <p>
                <strong>Supervisory board</strong>
                Matthias-Christian Kurzrock (Chairman)<br/>
              </p>
              <p>
                <strong>Supervisory authority</strong>
                Airtime Software AG is supervised by BaFin with regard to compliance with the provisions of Art. 34b, 34c of
                the Securities Trading Act and the Financial Analysis Regulations applicable to companies active in
                financial analysis.
              </p>
              <p>
                <strong>Copyright</strong>
                All rights reserved. Reprinting (including excerpts), commercial distribution, and inclusion in
                commercial databases only with the written permission of Airtime Software AG.
              </p>
              <p>
                <strong>Google Analytics</strong>
                This website uses Google Analytics, a web analytics service provided by Google Inc. ("Google").
                Google Analytics uses so-called "cookies", text files that are stored on your computer and allow for
                an analysis of your website use. The information generated by the cookie about your use of this
                website (including your IP address) is transmitted to a Google server in the USA and stored there.
                Google will use this information to evaluate your use of the website, compile website activity
                reports for website operators, and provide other services related to website activity and internet
                usage. Google may also transfer this information to third parties if required by law or if third
                parties process this data on Google's behalf. Under no circumstances will Google associate your IP
                address with other Google data. You can prevent the installation of cookies by setting your browser
                software accordingly; however, we would like to point out that you may not be able to use all
                functions of this website in full. By using this website, you consent to the processing of data
                about you by Google in the manner and for the purposes set out above.
              </p>
              <p>
                You can object to the collection and use of your IP address by Google Analytics at any time with
                future effect. You can find more information on this at <a
                href="https://tools.google.com/dlpage/gaoptout" target="_blank"
                rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>. We would like to point out
                that on this website, Google Analytics has been expanded to include the code "gat._anonymizeIp();"
                to ensure that IP addresses are recorded anonymously.
              </p>
            </div>)}
          </div>
        </div>
      </div>
    </div>)
  }
}

export default withLocalize(Imprint);

import React, {useEffect, useState} from 'react';
import ContactWidgetLink from "../meetings/ContactWidgetLink";
import {useSelector} from "react-redux";
import ContactRow from "./ContactRow";
import {Translate} from "react-localize-redux";
import ContactsTextSearch from "./ContactsTextSearch";
import CheckButton from "../../../elements/form/CheckButton";
import moment from "moment";
import FilterIcon from "../../../gfx/FilterIcon";
import RangeSlider from "react-range-slider-input";

function Contacts() {
    const contacts = useSelector(store => store.contacts);

    const [filteredContacts, setFilteredContacts] = useState([]);

    const [textSearchIsOpen, setTextSearchIsOpen] = useState(false);
    const [textSearch, setTextSearch] = useState(null);
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDir, setSortDir] = useState('asc');
    const [showFilters, setShowFilters] = useState(false);
    const [availableTypes, setAvailableTypes] = useState([]);
    const [availableFocuses, setAvailableFocuses] = useState([]);
    const [availableAums, setAvailableAums] = useState([]);
    const [availableRegions, setAvailableRegions] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedFocuses, setSelectedFocuses] = useState([]);
    const [selectedAums, setSelectedAums] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedEventsAmount, setSelectedEventsAmount] = useState([0, 11]);
    const [selectedMonths, setSelectedMonths] = useState([0, 16]);

    const [maxEventsAmount, setMaxEventsAmount] = useState(100);
    const [maxMonthsAgo, setMaxMonthsAgo] = useState(12);

    const onChangeTextSearch = (newTextSearchIsOpen) => {
        setTextSearchIsOpen(newTextSearchIsOpen);
    };

    const getMonthInPastForStep = (step) => {
        if (step > 14) {
            return -1200;
        } else if (step >= 12) {
            return 0;
        } else if (step >= 6) {
            return 12 - step;
        } else if (step === 5) {
            return 9;
        } else if (step === 4) {
            return 12;
        } else if (step === 0) {
            return maxMonthsAgo;
        } else {
            return 12 + Math.floor((maxMonthsAgo - 12) - step * (maxMonthsAgo - 12) / 4)
        }
    }

    const getEventsAmountForStep = (step) => {
        if (step <= 5) {
            return step;
        } else if (step === Math.min(11, maxEventsAmount)) {
            return maxEventsAmount;
        } else {
            return Math.floor((maxEventsAmount - 5) / (Math.min(11, maxEventsAmount) - 5)) * (step - 5) + 5;
        }
    }

    const getLastEventForContact = contact => {
        if (contact.events && contact.events.length > 0) {
            let pastEvents = contact.events.filter(el => moment(el.date.start).isSameOrBefore(moment()));
            if (pastEvents && pastEvents.length > 0) {
                return pastEvents.sort((a, b) => moment(a.date.start).isBefore(moment(b.date.start)) ? 1 : -1)[0];
            }
        }
        return null;
    }

    useEffect(() => {
        if (contacts.loaded && contacts.items.length > 0) {
            let items = contacts.items;

            setMaxEventsAmount(Math.max(...items.map(c => c.events?.length ?? 0)));
            setMaxMonthsAgo((moment(Math.min(...items.map(c => Math.min(...c.events?.map(e => moment(e.date?.start).toDate()))))).diff(moment(), 'month') * -1) + 1);

            // get available filters
            setAvailableTypes([... new Set(items.map(i => i.userType))]);
            setAvailableFocuses([... new Set(items.filter(i => !!i.focus)?.map(i => i.focus?.split(', '))?.flat())]?.filter(i => i !== 'Sondersituationen')?.map(i => i.replaceAll(' ', '')));
            setAvailableAums([... new Set(items.filter(i => !!i.aum)?.map(i => i.aum?.split(', '))?.flat())]);
            setAvailableRegions([... new Set(items.filter(i => !!i.region)?.map(i => i.region?.split(', '))?.flat())]);

            // filter by monthsAgo
            if (selectedMonths[0] !== 0 || selectedMonths[1] !== 16) {
                let t_minDate = moment().subtract(getMonthInPastForStep(selectedMonths[0]), 'months');
                let t_maxDate = moment().subtract(getMonthInPastForStep(selectedMonths[1]), 'months');
                items = items.map(item => ({
                    ...item,
                    events: item.events?.map(e => ({
                        ...e,
                        matchesFilter: moment(e.date?.start).isSameOrAfter(t_minDate) && moment(e.date?.start).isSameOrBefore(t_maxDate)
                    }))
                }));
            } else {
                items = items.map(item => ({
                    ...item,
                    events: item.events?.map(e => ({
                        ...e,
                        matchesFilter: true
                    }))
                }));
            }

            // filter by eventsAmount
            if (selectedEventsAmount[0] !== 0 || selectedEventsAmount[1] !== 11) {
                let t_minEventsAmount = getEventsAmountForStep(selectedEventsAmount[0]);
                let t_maxEventsAmount = getEventsAmountForStep(selectedEventsAmount[1]);
                items = items.filter(item => item.events?.filter(e => e.matchesFilter)?.length >= t_minEventsAmount && item.events?.filter(e => e.matchesFilter)?.length <= t_maxEventsAmount);
            }

            // filter by type
            if (selectedTypes && selectedTypes.length > 0) items = items.filter(item => selectedTypes.includes(item.userType));

            // filter by focus
            if (selectedFocuses && selectedFocuses.length > 0 && (selectedTypes.length === 0 || selectedTypes?.includes('investor'))) items = items.filter(item => item.focus && item.focus?.split(', ').map(i => i.replaceAll(' ', '')).find(f => selectedFocuses.includes(f)));

            // filter by aum
            if (selectedAums && selectedAums.length > 0 && (selectedTypes.length === 0 || selectedTypes?.includes('investor'))) items = items.filter(item => item.aum && item.aum?.split(', ').find(a => selectedAums.includes(a)));

            // filter by region
            if (selectedRegions && selectedRegions.length > 0 && (selectedTypes.length !== 1 || !selectedTypes?.includes('earningsCallParticipant'))) items = items.filter(item => item.region && item.region?.split(', ').find(r => selectedRegions.includes(r)));

            // filter by search string
            if (textSearch && textSearch.length > 0) items = items.filter(item => !textSearch.split(" ").find(textSearchItem => !`${item.name}${item.institution ? item.institution.name : ''}`.toLowerCase().includes(textSearchItem.toLowerCase())));

            switch (sortColumn) {
                case "name":
                    items = sortDir === 'asc' ? items.sort((a, b) => a.name?.localeCompare(b.name)) : items.sort((a, b) => b.name?.localeCompare(a.name))
                    break
                case "institution":
                    items = sortDir === 'asc' ? items.sort((a, b) => (a.institution ? a.institution.name : '').localeCompare(b.institution ? b.institution.name : '')) : items.sort((a, b) => (b.institution ? b.institution.name : '').localeCompare(a.institution ? a.institution.name : ''))
                    break
                case "eventsAmount":
                    items = sortDir === 'asc' ? items.sort((a, b) => a.events?.length - b.events?.length) : items.sort((a, b) => b.events?.length - a.events?.length)
                    break
                case "lastEvent":
                    items = sortDir === 'asc' ? items.sort((a, b) => moment(getLastEventForContact(a)?.date?.start).diff(moment(getLastEventForContact(b)?.date?.start))) : items.sort((a, b) => moment(getLastEventForContact(b)?.date?.start).diff(moment(getLastEventForContact(a)?.date?.start)))
                    break
                default:
                    break
            }

            setFilteredContacts([...items]);
        }
    }, [contacts.loaded, contacts.items, selectedEventsAmount, selectedMonths, selectedTypes, selectedFocuses, selectedAums, selectedRegions, textSearch, sortColumn, sortDir]);

    const toggleFilter = (filterName, item) => {
        switch (filterName) {
            case 'type':
                if (!selectedTypes.includes(item)) {
                    setSelectedTypes([...selectedTypes, item]);
                } else {
                    setSelectedTypes(selectedTypes.filter(fi => fi !== item));
                }
                break;
            case 'focus':
                if (!selectedFocuses.includes(item)) {
                    setSelectedFocuses([...selectedFocuses, item]);
                } else {
                    setSelectedFocuses(selectedFocuses.filter(fi => fi !== item));
                }
                break;
            case 'aum':
                if (!selectedAums.includes(item)) {
                    setSelectedAums([...selectedAums, item]);
                } else {
                    setSelectedAums(selectedAums.filter(fi => fi !== item));
                }
                break;
            case 'region':
                if (!selectedRegions.includes(item)) {
                    setSelectedRegions([...selectedRegions, item]);
                } else {
                    setSelectedRegions(selectedRegions.filter(fi => fi !== item));
                }
                break;
        }
    }

    const toggleSort = (column) => {
        if (sortColumn === column) {
            setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDir('asc');
        }
    }

    return (
        <div className="Contacts">
            <ContactWidgetLink/>
            <div className="container container-filter-row-top">
                <div className="filter-toggle-container">
                    <button className={`btn-filter-toggle${showFilters ? ' active' : ''}`} onClick={() => setShowFilters(!showFilters)}>
                        <FilterIcon/>
                        {showFilters ? (
                            <Translate id="contacts.filters.hideFilters"/>
                        ) : (
                            <Translate id="contacts.filters.showFilters"/>
                        )}
                    </button>
                </div>
                <ContactsTextSearch textSearchIsOpen={textSearchIsOpen}
                                    onNameChange={onChangeTextSearch} extraClass="on-contacts-page"
                                    onTextSearch={val => setTextSearch(val)}/>
            </div>
            <div className={`filters-container${!showFilters ? ' d-none' : ''}`}>
                <div className="filter-container">
                    <h5>
                        <Translate id="contacts.filters.type.label"/>:
                    </h5>
                    {availableTypes?.sort((a, b) => (a === 'investor' ? 1 : a === 'multiplier' ? 2 : a === 'earningsCallParticipant' ? 3 : 4) - (b === 'investor' ? 1 : b === 'multiplier' ? 2 : b === 'earningsCallParticipant' ? 3 : 4))?.map((item, iI) => (
                        <CheckButton label={<Translate id={`contacts.filters.type.value.${item}`}/>}
                                     checked={selectedTypes.includes(item)} key={iI}
                                     onChange={() => toggleFilter('type', item)}/>
                    ))}
                </div>
                <div className={`filter-container${selectedTypes.length > 0 && !selectedTypes?.includes('investor') ? ' d-none' : ''}`}>
                    <h5>
                        <Translate id="contacts.filters.focus.label"/>:
                    </h5>
                    {availableFocuses?.sort((a, b) => (a === 'MicroCaps' ? 1 : a === 'SmallCaps' ? 2 : a === 'MidCaps' ? 3 : a === 'LargeCaps' ? 4 : 5) - (b === 'MicroCaps' ? 1 : b === 'SmallCaps' ? 2 : b === 'MidCaps' ? 3 : b === 'LargeCaps' ? 4 : 5))?.map((item, iI) => (
                        <CheckButton label={item}
                                     checked={selectedFocuses.includes(item)} key={iI}
                                     onChange={() => toggleFilter('focus', item)}/>
                    ))}
                </div>
                <div className={`filter-container${selectedTypes.length > 0 && !selectedTypes?.includes('investor') ? ' d-none' : ''}`}>
                    <h5>
                        <Translate id="contacts.filters.aum.label"/>:
                    </h5>
                    {availableAums?.sort((a, b) => (['< 50 Mio. Euro', '< 50m Euro'].includes(a)  ? 1 : ['50 - 500 Mio. Euro', '50 - 500m Euro'].includes(a) ? 2 : ['> 500 Mio. Euro', '> 500m Euro'].includes(a)  ? 3 : 4) - (['< 50 Mio. Euro', '< 50m Euro'].includes(b) ? 1 : ['50 - 500 Mio. Euro', '50 - 500m Euro'].includes(b) ? 2 : ['> 500 Mio. Euro', '> 500m Euro'].includes(b) ? 3 : 4))?.map((item, iI) => (
                        <CheckButton label={item}
                                     checked={selectedAums.includes(item)} key={iI}
                                     onChange={() => toggleFilter('aum', item)}/>
                    ))}
                </div>
                <div className={`filter-container${selectedTypes.length === 1 && selectedTypes?.includes('earningsCallParticipant') ? ' d-none' : ''}`}>
                    <h5>
                        <Translate id="contacts.filters.region.label"/>:
                    </h5>
                    {availableRegions?.sort((a, b) => ['Andere', 'Sonstige', 'Other'].includes(a) ? 1 : ['Andere', 'Sonstige', 'Other'].includes(b) ? -1 : (a.localeCompare(b)))?.map((item, iI) => (
                        <CheckButton label={item}
                                     checked={selectedRegions.includes(item)} key={iI}
                                     onChange={() => toggleFilter('region', item)}/>
                    ))}
                </div>
                <div className="break"/>
                <div className="filter-container">
                    <h5>
                        <Translate id="contacts.filters.eventsAmount.label"/>:
                    </h5>
                    <div className="slider-container">
                        <RangeSlider min={0} max={Math.min(11, maxEventsAmount)} step={1} value={selectedEventsAmount} onInput={(val) => val[1] > val[0] ? setSelectedEventsAmount([val[0], val[1]]) : null}/>
                        <div className="label-container">
                            <span className="left" style={{left: `${100 * selectedEventsAmount[0]/Math.min(11, maxEventsAmount)}%`}}>
                                {getEventsAmountForStep(selectedEventsAmount[0])}
                            </span>
                            <span className="right" style={{left: `${100 * selectedEventsAmount[1]/Math.min(11, maxEventsAmount)}%`}}>
                                {getEventsAmountForStep(selectedEventsAmount[1])}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="filter-container">
                    <h5>
                        <Translate id="contacts.filters.monthsAgo.label"/>:
                    </h5>
                    <div className="slider-container">
                        <RangeSlider min={0} max={16} step={1} value={selectedMonths} onInput={(val) => (val[1] <= 12 ? val[1] : (val[1] < 15 ? 12 : 16)) > Math.min(val[0], 12) ?
                            setSelectedMonths([Math.min(val[0], 12), val[1] <= 12 ? val[1] : (val[1] < 15 ? 12 : 16)]) : null}/>
                        <div className={`today-step${selectedMonths[1] > 12 ? ' active' : ''}`}/>
                        <div className="future-step"/>
                        <div className="rs-white rs-white-1"/>
                        <div className="rs-white rs-white-2"/>
                        <div className="rs-white rs-white-3"/>
                        <div className="label-container">
                            <span className="left" style={{left: `${100 * selectedMonths[0]/16}%`}}>
                                {
                                    selectedMonths[0] < 12
                                    ? getMonthInPastForStep(selectedMonths[0])
                                    : <Translate id="contacts.filters.monthsAgo.today"/>
                                }
                            </span>
                            <span className={`right${selectedMonths[0] === 11 && selectedMonths[1] === 12 ? ' to-right' : ''}`} style={{left: `${100 * selectedMonths[1]/16}%`}}>
                                {selectedMonths[1] < 12 ? getMonthInPastForStep(selectedMonths[1]) : (
                                    selectedMonths[1] === 12 ? <Translate id="contacts.filters.monthsAgo.today"/> : <Translate id="contacts.filters.monthsAgo.future"/>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {contacts.loaded && (
                    <div className="contacts-list-container">
                        <div className="contacts-list">
                            <div className="contacts-list-header d-none d-md-flex">
                                <div className="col-name" onClick={() => toggleSort('name')}>
                                    <div className="d-lg-none">
                                        <Translate id="contacts.contactsHeader.nameAndInstitute"/>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <Translate id="contacts.contactsHeader.name"/>
                                    </div>
                                </div>
                                <div className="col-institute d-none d-lg-block"
                                     onClick={() => toggleSort('institution')}>
                                    <Translate id="contacts.contactsHeader.institute"/>
                                </div>
                                <div className="col-eventsAmount d-none d-lg-block"
                                     onClick={() => toggleSort('eventsAmount')}>
                                    <Translate id="contacts.contactsHeader.eventsAmount"/>
                                </div>
                                <div className="col-lastEvent" onClick={() => toggleSort('lastEvent')}>
                                    <Translate id="contacts.contactsHeader.lastEvent"/>
                                </div>
                            </div>
                            {filteredContacts.map(contact => (
                                <ContactRow contact={contact} key={contact.itemId}/>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Contacts;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import {Router} from "react-router";
import history from "./history";
import {LocalizeProvider} from "react-localize-redux";
import {Provider} from "react-redux";
import {store} from "./store";
import ScrollToTop from "./elements/scrollToTop";

ReactDOM.render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <Router history={history}>
                <ScrollToTop>
                    <App/>
                </ScrollToTop>
            </Router>
        </LocalizeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

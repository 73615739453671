import React from 'react';
import {
  G, Path, StyleSheet, Svg, Text, View,
} from '@react-pdf/renderer';
import moment from 'moment/moment';
import ParticipantsTable from './ParticipantsTable';

const styles = StyleSheet.create({
  slotHeadline: {
    backgroundColor: '#5db6c1',
    alignItems: 'center',
    paddingLeft: 22,
    paddingTop: 10,
    paddingBottom: 14,
    color: 'white',
    fontWeight: 600,
    fontSize: 15,
    lineHeight: 1.25,
    flexDirection: 'row',
  },
  slotHeadlineLabel: {},
  arrow: {
    paddingTop: 7,
    marginRight: 10,
  },
  slotHeadlineTime: {
    width: 60,
  },
  slotHeadlineTitle: {
    flex: 1,
  },
});

function RoadshowSlot({
  slot,
  sortedPersonsToShow,
  labels,
}) {
  const participantCollection = [].concat(slot.participants)
    .concat(slot.appliers);
  const filteredPersonsToShow = sortedPersonsToShow
    .filter((personToShow) => participantCollection
      .find((participant) => (
        personToShow.appId === participant.appId && personToShow.itemId === participant.itemId
      )));
  return (
    filteredPersonsToShow.length > 0 && (
      <View wrap={false} style={styles.slot} key={slot.itemId}>
        <View style={styles.slotHeadline}>
          <View style={styles.arrow}>
            <Svg
              width="24.658"
              height="17.392"
              viewBox="0 0 24.658 17.392"
            >
              <G
                id="Gruppe_3186"
                data-name="Gruppe 3186"
                transform="translate(24.658) rotate(90)"
              >
                <Path
                  id="Pfad_1418"
                  data-name="Pfad 1418"
                  d="M5.064,24.658,0,19.594l7.265-7.265L0,5.064,5.064,0,17.392,12.329Z"
                  transform="translate(0 0)"
                  fill="#fff"
                />
              </G>
            </Svg>
          </View>
          <Text style={styles.slotHeadlineTime}>
            {moment(slot.date.start)
              .format('LT')}
          </Text>
          <Text style={styles.slotHeadlineTitle}>
            {slot.title}
          </Text>
        </View>
        <ParticipantsTable filteredPersonsToShow={filteredPersonsToShow} labels={labels} />
      </View>
    ));
}

export default RoadshowSlot;

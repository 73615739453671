import axios from 'axios';
import {store} from '../store';

class ContactsApi {

    static loadContacts = () => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/contact/list`, {
            company: store.getState()?.profile?.selectedCompanyId
        });
    }

    static loadContactProfile = (appId, itemId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/contact/profile`, {
            appId,
            itemId
        });
    }

    static loadContactsProfile = (itemIds) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/contact/profiles`, {
            company: store.getState()?.profile?.selectedCompanyId,
            itemIds
        });
    }
}

export default ContactsApi;
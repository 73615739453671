import montegaConnect from './store/rootReducer';
import persistState from 'redux-localstorage';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";

const enhancer = compose(
    applyMiddleware(thunk),
    persistState(['authorization', 'events', 'profile', 'publications', 'localize'], {key: 'montega-connect-emt'})
);

const store = createStore(montegaConnect, enhancer);
export {store};

import React from 'react';

const EditIcon = () => (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         viewBox="0 0 78 78" style={{enableBackground: 'new 0 0 78 78'}}>
        <g id="Rechteck_2449" transform="translate(4 4)">
            <path fill="currentColor" d="M5,0h60c2.8,0,5,2.2,5,5v60c0,2.8-2.2,5-5,5H5c-2.8,0-5-2.2-5-5V5C0,2.2,2.2,0,5,0z"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4"
                  d="M5-2h60c3.9,0,7,3.1,7,7v60c0,3.9-3.1,7-7,7H5c-3.9,0-7-3.1-7-7V5C-2,1.1,1.1-2,5-2z"/>
        </g>
        <g id="vGFeMp" transform="translate(16.981 16.697)">
            <g id="Gruppe_4361" transform="translate(-0.001)">
                <path id="Pfad_1948" fill="#FFFFFF" d="M44.4,7.9c-0.3,0.9-0.8,1.7-1.5,2.3c-1.2,1.2-2.4,2.4-3.6,3.5c-0.1,0.1-0.2,0.2-0.3,0.3l-9-9
                    c0.1-0.1,0.1-0.2,0.2-0.3c1.1-1.1,2.3-2.3,3.4-3.4c1.5-1.6,4-1.8,5.6-0.3c0.1,0.1,0.2,0.2,0.3,0.3c1.1,1.1,2.2,2.2,3.3,3.3
                    c0.7,0.6,1.2,1.4,1.5,2.3L44.4,7.9z"/>
                <path id="Pfad_1949" fill="#FFFFFF"
                      d="M27.6,7.5l8.9,8.9L13.8,39.1c-2.9-2.9-5.9-5.9-8.9-8.9L27.6,7.5z"/>
                <path id="Pfad_1950" fill="#FFFFFF" d="M11.2,41.7c-1.4,0.4-2.9,0.7-4.3,1.1c-1.8,0.4-3.6,0.9-5.3,1.3c-0.2,0.1-0.4,0.1-0.6,0.1
                    c-0.6,0-1-0.5-0.9-1.1c0,0,0-0.1,0-0.1c0.7-3.4,1.5-6.7,2.2-10.1c0,0,0,0,0,0L11.2,41.7z"/>
            </g>
        </g>
    </svg>
)

export default EditIcon;
import React, {useEffect, useState} from 'react';

import gfx_arrowDown from '../../../gfx/contacts/contacts_arrow-down.svg';
import gfx_arrowRight from '../../../gfx/contacts/contacts_arrow-right.svg';
import {Collapse} from "react-collapse/lib/Collapse";
import {Translate} from "react-localize-redux";
import moment from "moment";
import ProfileButton from "./ProfileButton";
import Arrow from "../../../gfx/publications/Arrow";

function ContactRow({contact}) {

    const [isOpen, setIsOpen] = useState(false);
    const [lastEvent, setLastEvent] = useState(null);
    const [shownEventsAmount, setShownEventsAmount] = useState(8);

    useEffect(() => {
        if(contact.events && contact.events.length > 0) {
            setShownEventsAmount(sea => Math.min(sea, contact.events.length));
            let pastEvents = contact.events.filter(el => moment(el.date.start).isSameOrBefore(moment()));
            if (pastEvents && pastEvents.length > 0) {
                setLastEvent(pastEvents.sort((a, b) => moment(a.date.start).isBefore(moment(b.date.start)) ? 1 : -1)[0]);
            } else {
                let upcomingEvents = contact.events.filter(el => moment(el.date.start).isAfter(moment()));
                if (upcomingEvents && upcomingEvents.length > 0) {
                    setLastEvent(upcomingEvents.sort((a, b) => moment(a.date.start).isAfter(moment(b.date.start)) ? 1 : -1)[0]);
                }
            }
        } else {
            setShownEventsAmount(0);
        }
    }, [contact.events]);

    const increaseShownEventsAmount = () => {
        setShownEventsAmount(sea => Math.min(sea + 8, contact.events ? contact.events.length : 0));
    }

    const decreaseShownEventsAmount = () => {
        setShownEventsAmount(sea => Math.max(sea - 8, contact.events.length ? 8 : 0));
    }

    return (
        <div className="ContactRow">
            <div className={`contact-row-header${isOpen ? ' open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                <div className="arrow">
                    <div className="image-container">
                        <img src={isOpen ? gfx_arrowDown : gfx_arrowRight} alt=""/>
                    </div>
                </div>
                <div className="title">
                    <div className="d-block col-name">
                        <div className="person-name">
                            {contact.name}
                        </div>
                        <div className="institution-name d-lg-none">
                            {contact.institution?.name}
                        </div>
                    </div>
                    <div className="institution-name col-institute d-none d-lg-block">
                        {contact.institution?.name}
                    </div>
                    <div className="eventsAmount-number col-eventsAmount d-none d-lg-block">
                        {contact.events?.length ?? 0}
                    </div>
                    <div className="lastEvent-date col-lastEvent d-none d-md-block">
                        {lastEvent ? moment(lastEvent.date.start).format('LLL') : ''}
                    </div>
                </div>
                <div className="contact-link">
                    <ProfileButton appId={contact.appId} itemId={contact.itemId} notHardHidden={true}/>
                </div>
            </div>
            <Collapse isOpened={isOpen}>
                <div className="contact-row-content">
                    <div className="intro">
                        <Translate id="contacts.contactRow.yourMeetingsWith"/>&nbsp;
                        {contact.name}
                    </div>
                    <div className="events">
                        {contact.events?.slice(0, shownEventsAmount)?.map((event, eI) => (
                            <div className="event" key={eI}>
                                <div className="info">
                                    <div className="date">
                                        {moment(event.date?.start).format('LLL')}
                                    </div>
                                    <div className="type">
                                        <Translate id={`contacts.contactRow.type.${event.type}`}/>
                                    </div>
                                    <div className="title d-none d-lg-inline-block">
                                        {event.title}
                                    </div>
                                </div>
                                <div className="title d-lg-none">
                                    {event.title}
                                </div>
                            </div>
                        ))}
                        {shownEventsAmount < contact.events?.length ? (
                            <button onClick={increaseShownEventsAmount} type="button" className="btn-showMore">
                                <Arrow/>
                                <Translate id="contacts.contactRow.showMore"/>
                            </button>
                        ) : null}
                        {shownEventsAmount <= contact.events?.length && shownEventsAmount > 8 ? (
                            <button onClick={decreaseShownEventsAmount} type="button" className="btn-showMore rotate">
                                <Arrow className="rotate"/>
                                <Translate id="contacts.contactRow.showLess"/>
                            </button>
                        ) : null}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default ContactRow;

import axios from 'axios';
import {store} from '../store';

class PublicationsApi {
  static get = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}issuer/publication/list?company=${store.getState()?.profile?.selectedCompanyId}`)
  };

  static getPdf = (uuid, lang) => {
    return axios.get(`${process.env.REACT_APP_API_URL}issuer/publication/pdf/${uuid}?lang=${lang ?? 'de'}`, {
      responseType: 'blob'
    });
  };

  static getDemands = (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}issuer/publication/demands?publication=${id}`);
  };
}

export default PublicationsApi;

import React from 'react';
import Select from 'react-select';
import {Translate} from "react-localize-redux";

class MeetingsPaginateRangeSelect extends React.Component {

    availableEntries = [
        {value: 10, label: <Translate id="meetings.paginateRangeSelect.10"/>},
        {value: 25, label: <Translate id="meetings.paginateRangeSelect.25"/>},
        {value: 50, label: <Translate id="meetings.paginateRangeSelect.50"/>},
        {value: 0, label: <Translate id="meetings.paginateRangeSelect.all"/>}
    ];

    setValue = (obj) => {
        this.props.onChange(obj.value);
    };

    render() {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: '0',
                color: state.isSelected ? '#000000' : '#3BA6AF',
                background: state.isFocused ? "#7AA3A816" : 'white',
                padding: 10,
            }),
            control: () => ({
                cursor: 'pointer',
                display: 'flex',
                height: 40,
                width: 145,
                border: '2px solid #5DB6C1',
                borderRadius: '10px 10px 0 10px',
            }),
            menu: () => ({
                border: '2px solid #5DB6C1',
                borderTop: '0',
                borderBottom: '2px solid #3BA6AF',
                borderRadius: '0 0 10px 0',
                background: "#ffffff"
            }),
            indicatorSeparator: () => ({
                display: "none"
            })
        };
        return (
            <div className="MeetingsPaginateRangeSelect">
                <Select className="c-select-container" styles={customStyles} classNamePrefix="c-select" options={this.availableEntries}
                        value={this.availableEntries.find(el => el.value === this.props.value)} onChange={this.setValue} isSearchable={false}/>
                <div className="select-helper">
                    <Translate id="meetings.paginateRangeSelect.perPage"/>
                </div>
            </div>
        )
    }
}

export default MeetingsPaginateRangeSelect;

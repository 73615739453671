import {
    AUTHORIZATION_LOGIN_SUCCESS,
    AUTHORIZATION_LOGOUT, AUTHORIZATION_SET_ACCESS_TOKEN
} from "../../config/actions";

let defaultState = {
    bearerToken: null,
    refreshToken: null,
    username: null,
    roles: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case AUTHORIZATION_LOGIN_SUCCESS:
            return {
                ...state,
                bearerToken: action.bearerToken,
                username: action.username,
                roles: action.roles,
                refreshToken: action.refreshToken
            };
        case AUTHORIZATION_LOGOUT:
            return {
                ...defaultState
            };
        case AUTHORIZATION_SET_ACCESS_TOKEN:
            return {
                ...state,
                bearerToken: action.bearerToken
            }
        default:
            return state;
    }
}

import React from 'react';

const PrintArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.042" height="12.819" viewBox="0 0 9.042 12.819">
        <g id="Gruppe_4506" data-name="Gruppe 4506" transform="translate(-349.53 -197.702)">
            <path id="Pfad_1418" data-name="Pfad 1418" d="M352.162,210.521l-2.632-2.632,3.777-3.777-3.777-3.777,2.632-2.632,6.409,6.41Z" transform="translate(0 0)" fill="currentColor"/>
        </g>
    </svg>
);

export default PrintArrow;
import React, {useState} from 'react';
import {Translate} from "react-localize-redux";
import Arrow from "../../../gfx/publications/Arrow";

const EntriesPerPageDropdown = ({value, onChange}) => {

  const options = [10, 25, 50, 100];
  const [open, setOpen] = useState(false);

  return (
    <div className="EntriesPerPageDropdown dropdown">
      <div className="info-right">
        <Translate id="publication.perPage"/>
      </div>
      <button className={`dropdown-toggle${open ? ' active' : ''}`} type="button" id="entriesPerPageDropdownButton" data-toggle="dropdown" aria-expanded="false" onClick={() => setOpen(!open)}>
        <span>
          {value} <Translate id="publication.entries"/>
        </span>
        <Arrow/>
      </button>
      <div className={`dropdown-menu${open ? ' show' : ''}`} aria-labelledby="entriesPerPageDropdownButton">
        {options.filter(o => o !== value).map((o, oI) => (
          <button className="dropdown-item" key={oI} onClick={() => {setOpen(false); onChange(o)}} type="button">
            {o} <Translate id="publication.entries"/>
          </button>
        ))}
      </div>
    </div>
  )
}

export default EntriesPerPageDropdown;
import React, {Fragment, useRef, useState} from 'react';
import gfx_upload from '../../../gfx/upload.svg';
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import EventsApi from "../../../api/EventsApi";
import {eventsEventUploadsLoadSuccess, eventsEventUploadsWaitForFile} from "../../../store/events/eventsAction";

const FileUploadButton = ({evnt, isReplace = false, itemId, disabled}) => {

    const dispatch = useDispatch();

    const profileState = useSelector(state => state.profile);

    const [isUploading, setIsUploading] = useState(false);

    const fileInputRef = useRef();

    const handleFileChange = (e) => {
        setIsUploading(true);
        if (e.target?.files?.length < 1) return;
        const fileName = e.target.files[0].name;
        const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('fileName', fileName);
        data.append('event', evnt.itemId);
        data.append('company', profileState.selectedCompanyId);
        if (isReplace) data.append('upload', itemId);
        data.append('title', fileName);
        e.target.value = null;
        EventsApi.upload(data)
            .then(response => {
                if (response.data.success) {
                    setIsUploading(false);
                    dispatch(eventsEventUploadsLoadSuccess(evnt.itemId, evnt.appId, response.data.data));
                    dispatch(eventsEventUploadsWaitForFile(evnt.itemId, evnt.appId, response.data.data.find(u => u.title === fileName)?.itemId));
                }
            })
    }

    return (
        <Fragment>
            <input type="file" ref={fileInputRef} className="FileUploadButtonInput" accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, image/png, image/jpeg, image/jpg" onChange={handleFileChange}/>
            <button className={`FileUploadButton${isUploading ? ' loading' : ''}`} disabled={disabled || isUploading} onClick={() => fileInputRef.current?.click()}>
                <img src={gfx_upload} alt=""/>
                {isReplace ? (
                    <Translate id="meeting.files.dialog.replace.button"/>
                ) : (
                    <Translate id="meeting.files.dialog.upload.button"/>
                )}
            </button>
        </Fragment>
    )
}

export default FileUploadButton;
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import EventsApi from "../../../api/EventsApi";
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {Overlay} from "react-portal-overlay";
import gfx_notificationsOn from '../../../gfx/meetings/upcoming/notificationsOn.svg';
import gfx_notificationsOff from '../../../gfx/meetings/upcoming/notificationsOff.svg';
import {useDispatch} from "react-redux";
import {eventsUpdateEventNotifications} from "../../../store/events/eventsAction";
import EventBus from "../../../elements/EventBus";

const NotificationsButton = ({evnt}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [intervalInMinutes, setIntervalInMinutes] = useState(typeof evnt.notifications?.intervalInMinutes === 'number' ? evnt.notifications?.intervalInMinutes : -1);

  const availableNotifications = [
    {
      value: 0,
      key: 'immediately'
    },
    {
      value: 60,
      key: 'hourly'
    },
    {
      value: 24 * 60,
      key: 'daily'
    },
    {
      value: -1,
      key: 'never'
    }
  ];

  const handleSave = () => {
    setLoading(true);
    EventsApi.setNotification(evnt.appId, evnt.itemId, intervalInMinutes)
      .then(response => {
        setLoading(false);
        if (response.status === 200 && response.data.success) {
          dispatch(eventsUpdateEventNotifications(evnt.appId, evnt.itemId, response.data.data));
          handleClose();
        }
      })
  }

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    EventBus.on('brandClick', handleClose);

    return () => {
      EventBus.remove('brandClick', handleClose);
    }
  })

  return (
    <Fragment>
      <button className="btn-notifications" onClick={() => setOpen(true)}>
        {typeof evnt.notifications?.intervalInMinutes === 'number' && evnt.notifications?.intervalInMinutes !== -1 ? (
          <img src={gfx_notificationsOn} alt=""/>
        ) : (
          <img src={gfx_notificationsOff} alt=""/>
        )}
      </button>
      <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
        <div className="notifications-dialog-outer">
          <div className="notifications-dialog">
            <button onClick={handleClose} type="button">
              <Translate id="meetings.notifications.dialog.back"/>
            </button>
            <div className="notifications-dialog-content">
              <div className="title-bar">
                <div className="title">
                  <Translate id="meetings.notifications.dialog.title"/>
                </div>
                <BorderWithDot/>
              </div>
              <div className="notifications">
                {availableNotifications?.map((notification, nI) => (
                  <div className="notification" key={nI}>
                    <div className="checkbox-outer short d-inline-flex">
                      <input type="checkbox" checked={intervalInMinutes === notification.value} id={`checkbox${notification.key}${nI}`}
                             onChange={e => e.target.checked ? setIntervalInMinutes(notification.value) : null}/>
                      <label htmlFor={`checkbox${notification.key}${nI}`}>
                        <Translate id={`meetings.notifications.interval.${notification.key}`}/>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <button onClick={handleSave} disabled={loading} className="submit-btn">
                <Translate id="meetings.notifications.dialog.submit"/>
              </button>
            </div>
          </div>
        </div>
      </Overlay>
    </Fragment>
  )
};

export default NotificationsButton;
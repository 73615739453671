import React, {useState} from 'react';
import gfx_delete from '../../../gfx/delete.svg';
import {Translate} from "react-localize-redux";
import EventsApi from "../../../api/EventsApi";
import {useDispatch} from "react-redux";
import {eventsEventUploadRemoveSuccess} from "../../../store/events/eventsAction";

const FileDeleteButton = ({evnt, uuid, itemId, disabled}) => {

    const dispatch = useDispatch();

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = () => {
        setIsDeleting(true);
        EventsApi.deleteFile(uuid)
            .then(response => {
                if (response.status === 200) {
                    setIsDeleting(false);
                    dispatch(eventsEventUploadRemoveSuccess(itemId))
                }
            })
    }

    return (
        <button className={`FileDeleteButton${isDeleting ? ' loading' : ''}`} disabled={disabled || isDeleting} onClick={handleDelete}>
            <img src={gfx_delete} alt=""/>
            <Translate id="meeting.files.dialog.delete.button"/>
        </button>
    )
}

export default FileDeleteButton;
import React from 'react';

const GfxEventTypeVirtual = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27">
    <g id="Gruppe_3667" data-name="Gruppe 3667" transform="translate(0.396 0.753)">
      <ellipse id="Ellipse_90" data-name="Ellipse 90" cx="12" cy="12.5" rx="12" ry="12.5" transform="translate(0.604 0.247)" fill="none" stroke="currentColor" strokeWidth="2"/>
      <line id="Linie_244" data-name="Linie 244" x2="24.349" transform="translate(0.559 12.941)" fill="none" stroke="currentColor" strokeWidth="2"/>
      <line id="Linie_245" data-name="Linie 245" y2="24.349" transform="translate(12.733 0.766)" fill="none" stroke="currentColor" strokeWidth="2"/>
      <ellipse id="Ellipse_91" data-name="Ellipse 91" cx="6.297" cy="12.174" rx="6.297" ry="12.174" transform="translate(6.437 0.766)" fill="none" stroke="currentColor" strokeWidth="2"/>
      <path id="Pfad_1087" data-name="Pfad 1087" d="M625.235,335.09a34.039,34.039,0,0,0,20.148.02" transform="translate(-622.6 -328.952)" fill="none" stroke="currentColor" strokeWidth="2"/>
      <path id="Pfad_1088" data-name="Pfad 1088" d="M645.44,389.049a33.983,33.983,0,0,0-20.193,0" transform="translate(-622.609 -369.305)" fill="none" stroke="currentColor" strokeWidth="2"/>
    </g>
  </svg>
)

export default GfxEventTypeVirtual;
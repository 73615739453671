import React, {useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";

const FieldError = ({errors, fieldKey, showEmpty}) => {

  const [error, setError] = useState(false);

  useEffect(() => {
    if (errors && errors[fieldKey]) {
      setError(`${fieldKey}.${errors[fieldKey]}`);
    } else {
      setError(false);
    }
  }, [errors, fieldKey]);

  return error || showEmpty ? (
    <div className="field-error">
      {error ? <Translate id={`order.error.${error}`}/> : <span>&nbsp;</span>}
    </div>
  ) : null
}

export default FieldError;
import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {ROUTE_LOGIN} from "../../../config/routes";
import {Translate} from "react-localize-redux";
import TextInput from "../../../elements/form/TextInput";
import SubmitButton from "../../../elements/form/SubmitButton";
import iconCancel from "../../../gfx/cancel.svg";
import iconCancel_hover from "../../../gfx/cancel_hover.svg";
import AuthorizationApi from "../../../api/AuthorizationApi";

class ResetPassword extends React.Component {

    state = {
        password: '',
        password2: '',
        errors: {},
        success: false,
        isLoading: false
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        AuthorizationApi.resetPassword(this.props.match.params.token, this.state.password, this.state.password2)
            .then(response => {
                this.setState({isLoading: false});
                if (response.status === 200 && response.data.success === true) {
                    this.setState({success: true});
                } else {
                    this.setState({errors: response.data.errors});
                }
            });
    };

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <div className="ResetPassword unauthorized-block">
                        {this.state.success ? (
                            <Fragment>
                                <h3><Translate id="resetPassword.title"/></h3>
                                <div className="successText">
                                    <Translate id="resetPassword.successText"/>
                                </div>
                                <div className="btn-forgot-back">
                                    <Link to={ROUTE_LOGIN}><Translate id="resetPassword.backToLogin"/></Link>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h3><Translate id="resetPassword.title"/></h3>
                                {this.state.errors.user ? (
                                    <div className="tokenInvalid">
                                        <Translate id="resetPassword.error.token.tokenInvalid"/>
                                    </div>
                                ) : (
                                    <div className="helpText">
                                        <Translate id="resetPassword.helpText"/>
                                    </div>
                                )}
                                <form onSubmit={this.handleFormSubmit}>
                                    <TextInput placeholder={translate("resetPassword.password")} value={this.state.password}
                                               type="password" error={this.state.errors.password} errorPrefix="resetPassword.error.password"
                                               onChange={(e) => this.setState({password: e.target.value})}/>
                                    <TextInput placeholder={translate("resetPassword.password2")} value={this.state.password2}
                                               type="password" error={this.state.errors.password2} errorPrefix="resetPassword.error.password2"
                                               onChange={(e) => this.setState({password2: e.target.value})}/>
                                    <div className="btn-container d-flex">
                                        <Link to={ROUTE_LOGIN} className="d-flex btn-cancel">
                                            <img alt="" className="iconCancel icon" src={iconCancel}/>
                                            <img alt="" className="iconCancel icon icon-hover" src={iconCancel_hover}/>
                                            <Translate id="forgotPassword.cancel"/>
                                        </Link>
                                        <SubmitButton btnExtraClassName={this.state.isLoading ? 'loading' : ''} disabled={this.state.isLoading}>
                                            <Translate id="resetPassword.submit"/>
                                        </SubmitButton>
                                    </div>
                                </form>
                            </Fragment>
                        )}
                    </div>
                )}
            </Translate>
        )
    }
}

export default ResetPassword;

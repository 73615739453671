import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {SketchPicker} from "react-color";

const ColorPicker = ({value, onChange, defaultColor = '#055C60', activeLanguage}) => {

    const [color, setColor] = useState('');
    const [open, setOpen] = useState(false);

    let example = document.querySelector('.sketch-picker > div:nth-of-type(2) > div:first-of-type > div:first-of-type > div > div > div');
    let pseudoElementVar = document.querySelector('.sketch-picker > div:nth-of-type(2) > div:first-of-type > div:first-of-type > div > div');

    useEffect(() => {
        if (example) {
            let observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    let pseudoLeftPosition = example.offsetLeft / pseudoElementVar.offsetWidth * -1 * 18 + 2;
                    pseudoElementVar.style.setProperty('--pseudoLeft', pseudoLeftPosition + 'px');
                });
            });
            observer.observe(example, {attributes: true, attributeFilter: ['style']});
        }
    }, [example])

    useEffect(() => {
        if (!value || value.length < 1) {
            setColor(defaultColor);
        } else {
            setColor(value);
        }
    }, [value]);

    const handleBlur = () => {
        if (typeof onChange === "function") {
            let newValue = color;
            if (newValue !== value) {
                onChange(color);
            }
        }
    }

    const toggleOpen = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        handleBlur();
        setOpen(false);
    }

    const handleChange = value => {
        setColor(value.hex);
    }

    return (
        <Fragment>
            {open ? (
                <div className={`SketchPicker-container lang-${activeLanguage?.code}`}>
                    <div onClick={handleClose} className="picker-cover"/>
                    <SketchPicker color={color} onChange={handleChange} disableAlpha={true} presetColors={[]} width={300}/>
                </div>
            ) : null}
            <label className="ColorPicker">
                <div className="color-preview" style={{backgroundColor: color}} onClick={toggleOpen}/>
                <div className="label" onClick={toggleOpen}>
                    <Translate id="colorPicker.label"/>
                </div>
            </label>
        </Fragment>
    )
}

export default withLocalize(ColorPicker);
import axios from 'axios';
import {store} from '../store';

class ProfileApi {

    static get = () => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/profile/get`, {
            company: store.getState()?.profile?.selectedCompanyId
        })
    };

    static updatePassword = (oldPassword, password, password2) => {
        return axios.put(`${process.env.REACT_APP_API_URL}profile/password`, {
            oldPassword,
            password,
            password2
        });
    };

    static getCompanies = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}issuer/profile/companies`);
    };

    static updateCompany = (color) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/profile/companySettings`, {
            company: store.getState()?.profile?.selectedCompanyId,
            color
        });
    };

    static uploadImage = (data) => {
        return axios.post(`${process.env.REACT_APP_API_URL}issuer/profile/image`, data, {
            responseType: "blob"
        });
    };

    static deleteImage = (uuid) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}issuer/profile/image/${uuid}`);
    };
}

export default ProfileApi;

import React, {Fragment} from 'react';
import iconWebTurquoise from "../../../gfx/web_icon_turquoise.svg";
import {Translate} from "react-localize-redux";
import GoogleMapsHelper from "../../../config/GoogleMapsHelper";
import {Overlay} from "react-portal-overlay";
import iconPositionTurkuoise from "../../../gfx/position-turquoise.svg";
import {connect} from "react-redux";
import moment from "moment";
import EventBus from "../../../elements/EventBus";

class JoinButton extends React.Component {
  state = {
    dialogOpen: false
  };

  componentDidMount() {
    EventBus.on('brandClick', this.closeDialog);
  }

  componentWillUnmount() {
    EventBus.remove('brandClick', this.closeDialog);
  }

  showMapsLink = (event) => {
    if (event.type === 'fieldTrip') return true;
    if (['roundtable', 'conference', 'earningsCall', 'roadshow', 'roadshowSlot'].includes(event.type) && event.online !== true) return true;
    return false;
  };

  startJoin = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({dialogOpen: true});
  };

  closeDialog = () => {
    this.setState({dialogOpen: false});
  }

  render() {
    const {event, withTextSpan, profile, hardHidden} = this.props;

    return (
      <Fragment>
        {this.showMapsLink(event) || event.type !== 'conference' ? (
          <button className={this.props.className} onClick={this.startJoin}>
            {this.showMapsLink(event) ? (
              <Fragment>
                <img alt="Location-Icon" src={iconPositionTurkuoise}/>
                {withTextSpan ? (
                  <span className="text"><Translate id="meeting.join.button.offline"/></span>
                ) : (
                  <Translate id="meeting.join.button.offline"/>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <img src={iconWebTurquoise} alt="" className="d-none d-lg-inline"/>
                {withTextSpan ? (
                  <span className="text"><Translate id="meeting.join.button.online"/></span>
                ) : (
                  <Translate id="meeting.join.button.online"/>
                )}
              </Fragment>
            )}
          </button>
        ) : null}
        {!hardHidden || this.state.dialogOpen ? (
          <Overlay open={this.state.dialogOpen} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
            <div className="join-dialog-outer">
              <div className="join-dialog">
                <button onClick={this.closeDialog}>
                  <Translate id="meeting.join.dialog.back"/>
                </button>
                <div className="join-content">
                  {event.type !== 'roadshow' && (<h3>
                    <Translate id={`meeting.join.dialog.title1${this.showMapsLink(event) ? 'Address' : ''}`}/>&nbsp;
                    <Translate
                      id={`meeting.join.dialog.title2${event.type}`}/>:
                  </h3>)}
                  <h4>
                    {event.title}
                  </h4>
                  {!this.showMapsLink(event) && event.type !== 'roadshow' && (
                      <div className="privateLinkWarning">
                        <Translate id="meeting.join.dialog.privateLinkWarning"/>
                      </div>
                  )}
                  <div className="join-content-info">
                    {event.type === 'roadshow' ? (
                      <div className="roadshow-list">
                        {event.slots.sort((a, b) => moment(a.date.start).isBefore(moment(b.date.start)) ? -1 : 1).map((slot, sI) => (
                          <Fragment key={sI}>
                            <div className="roadshow-item">
                              <div className="label">
                                <div className="label-time">
                                  {moment(slot.date.start).format('LT')}
                                </div>
                                <div className="label-title">
                                  {slot.title}
                                </div>
                              </div>
                              <div className="button">
                                <JoinButton className="btn-infobox btn-dial" event={slot} withTextSpan={true} profile={profile}/>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    ) : (
                      <Fragment>
                        {this.showMapsLink(event) ? (
                          <div className="left">
                            <a href={GoogleMapsHelper.getRouteLinkToAddress(event.location.formatted)} target="_blank"
                               rel="noopener noreferrer" className="wider isMaps">
                              <Translate id="meeting.join.dialog.mapsLink"/>
                            </a>
                          </div>
                        ) : (
                          <div className="left">
                              {/**
                               <a href={`${process.env.REACT_APP_ZOOM_URL}${event?.itemId}/${profile?.itemId}`} target="_blank" rel="noopener noreferrer">
                               <Translate id="meeting.join.dialog.videoLink"/>
                               </a>
                               **/}
                              {event.zoomMeeting?.videoLink && (
                                  <a href={`${event.zoomMeeting.videoLink}`} target="_blank" rel="noopener noreferrer">
                                      <Translate id="meeting.join.dialog.videoLink"/>
                                  </a>
                              )}
                          </div>
                        )}
                        {this.showMapsLink(event) ? (
                          <Fragment>
                            <div>
                              <p>
                                <div className="right isLocation" dangerouslySetInnerHTML={{__html: event.addressInformation}}/>
                              </p>
                              <p>
                                <div className="right isLocation"
                                     dangerouslySetInnerHTML={{__html: event.location.formatted?.replaceAll(',', '<br/>')}}/>
                              </p>
                            </div>
                          </Fragment>
                        ) : (
                          event.zoomMeeting?.accessData ? (
                            <div className="right" dangerouslySetInnerHTML={{__html: event.zoomMeeting?.accessData}}/>
                          ) : null
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Overlay>
        ): null}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, null)(JoinButton);

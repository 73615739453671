import React, {useState, Fragment, useCallback, useEffect} from 'react';
import EventsApi from "../../../api/EventsApi";
import fileDownload from "js-file-download";
import icon_addToCalendar from "../../../gfx/meetings/upcoming/addToCalendar.svg";
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import BorderWithDot from "../../../elements/border/borderWithDot";
import moment from "moment";
import EventBus from "../../../elements/EventBus";

const DownloadIcsButton = ({evnt}) => {

  const [icsDownloadLoading, setIcsDownloadLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const startDownloadIcs = () => {
    if (evnt.type === 'roadshow') {
      setOpen(true);
    } else {
      downloadIcs([evnt.itemId]);
    }
  }

  const downloadIcs = (itemId) => {
    setIcsDownloadLoading(true)
    EventsApi.getIcs(evnt.appId, itemId)
      .then(response => {
        setIcsDownloadLoading(false)
        if (response.status === 200) {
          fileDownload(response.data, "event_" + evnt.itemId + ".ics", 'text/calendar');
          handleClose();
        }
      });
  }

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    EventBus.on('brandClick', handleClose);

    return () => {
      EventBus.remove('brandClick', handleClose);
    }
  })

  return (
    <Fragment>
      <button className="btn-download-ics" disabled={icsDownloadLoading} onClick={startDownloadIcs}>
        <img src={icon_addToCalendar} alt=""/>
      </button>
      <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
        <div className="download-ics-dialog-outer">
          <div className="download-ics-dialog">
            <button onClick={handleClose} type="button">
              <Translate id="meetings.downloadIcs.dialog.back"/>
            </button>
            <div className="download-ics-dialog-content">
              <div className="title-bar">
                <div className="title">
                  <Translate id="meetings.downloadIcs.dialog.title"/>
                </div>
                <BorderWithDot/>
              </div>
              <div className="slots">
                {evnt.slots?.sort((a, b) => moment(a.date.start).isBefore(moment(b.date.start)) ? -1 : 1).map((slot, sI) => (
                  <div className="slot" key={sI}>
                    <div className="checkbox-outer short d-inline-flex">
                      <input type="checkbox" checked={selectedSlot === slot.itemId} id={`checkbox${slot.itemId}${sI}`}
                             onChange={() => setSelectedSlot(slot.itemId)}/>
                      <label htmlFor={`checkbox${slot.itemId}${sI}`}>
                        <Translate id="meeting.from"/> {moment(slot.date.start).format('LT')}<br/>
                        <Translate id="meeting.until"/> {moment(slot.date.end).format('LT')}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <button onClick={() => downloadIcs(selectedSlot)} disabled={icsDownloadLoading || !selectedSlot} className="submit-btn">
                <Translate id="meetings.downloadIcs.dialog.submit"/>
              </button>
            </div>
          </div>
        </div>
      </Overlay>
    </Fragment>
  )
}

export default DownloadIcsButton;
export const ROUTE_DEFAULT = '/';

// UNAUTHORIZED
export const ROUTE_LOGIN = '/';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/auth/resetPassword/:token';
export const ROUTE_TOKEN_LOGIN = '/auth/token/:token';
export const ROUTE_PUBLICATION_LOGIN = '/publication/:companyId/:publicationItemId/:token';

// AUTHORIZED
export const ROUTE_MEETINGS = '/';
export const ROUTE_PAST = '/past';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_CONTACTS = '/contacts';
export const ROUTE_CONTACTS_PRINT = '/contacts/print/:eventAppId/:eventItemId/:itemIds';
export const ROUTE_PUBLICATIONS = '/publications';
export const ROUTE_PUBLICATIONS_DIRECT_ITEM = '/publications/:publicationItemId';
export const ROUTE_PUBLICATION_WEB_VIEW = '/publication/:id';

// LEGALS
export const ROUTE_DATA_PRIVACY = '/datenschutz';
export const ROUTE_IMPRINT = '/impressum';

// ERROR
export const ROUTE_ERROR = '/error';

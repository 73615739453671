import React from "react";

class BorderWithDot extends React.Component {

    render() {
        return (
            <div className={this.props.borderClass ? `${this.props.borderClass} border-with-dot` : 'border-with-dot'}
                 style={this.props.borderPositionX ? { transform: `translateX(${ this.props.borderPositionX + "px" })`} : {}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="4089" height="13.208" viewBox="0 0 4089 13.208">
                    <g id="Gruppe_2626" data-name="Gruppe 2626" transform="translate(-483 -281.418)">
                        <line id="Linie_256" data-name="Linie 256" x2="4084" transform="translate(483 288)" fill="none" stroke="#5db6c1" strokeWidth="2"/>
                        <path id="Pfad_1115" data-name="Pfad 1115" d="M2.217,4.433A2.2,2.2,0,0,0,4.442,2.217,2.2,2.2,0,0,0,2.217,0,2.194,2.194,0,0,0,0,2.217,2.194,2.194,0,0,0,2.217,4.433Z" transform="translate(4567.619 290.259) rotate(180)" fill="#5db6c1"/>
                        <path id="Pfad_1116" data-name="Pfad 1116" d="M9.92,2.029A5.6,5.6,0,1,0,10,9.076" transform="translate(4571 293.626) rotate(180)" fill="none" stroke="#5db6c1" strokeWidth="2"/>
                    </g>
                </svg>
            </div>

        )
    }
}

export default BorderWithDot;

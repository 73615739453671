import React from 'react';
import ProfileButton from "../contacts/ProfileButton";

function ParticipantsPersonRow({person}) {
    return (
        <div className="ParticipantsPersonRow">
            <div className="col-name">
                <div className="text-name">
                    {person.name}
                </div>
                <div className="text-institution d-lg-none">
                    {person.institution?.name}
                </div>
            </div>
            <div className="col-institution d-none d-lg-block">
                <div className="text-institution">
                    {person.institution?.name}
                </div>
            </div>
            <div className="col-profile">
                <ProfileButton appId={person.appId} itemId={person.itemId}/>
            </div>
        </div>
    )
}

export default ParticipantsPersonRow;
import React from 'react';
import {Route, Switch} from "react-router";
import {
    ROUTE_CONTACTS,
    ROUTE_CONTACTS_PRINT,
    ROUTE_MEETINGS,
    ROUTE_PAST,
    ROUTE_PROFILE,
    ROUTE_PUBLICATION_WEB_VIEW,
    ROUTE_PUBLICATIONS,
    ROUTE_PUBLICATIONS_DIRECT_ITEM
} from "../../config/routes";
import Profile from "./profile";
import Meetings from "./meetings";
import EventsApi from "../../api/EventsApi";
import {connect} from "react-redux";
import {eventsLoadSuccess} from "../../store/events/eventsAction";
import ContactsApi from "../../api/ContactsApi";
import {contactsLoadSuccess} from "../../store/contacts/contactsAction";
import Contacts from "./contacts";
import ProfileApi from "../../api/ProfileApi";
import {profileLoadSuccess} from "../../store/profile/profileAction";
import PublicationsApi from "../../api/PublicationsApi";
import {publicationsLoadSuccess} from "../../store/publications/publicationsAction";
import Publications from "./publications";
import PublicationWebView from "./publicationWebView";
import LanguageToggle from "../../elements/LanguageToggle";
import {withLocalize} from "react-localize-redux";
import ContactsPrint from "./contacts/print";

class Authorized extends React.Component {

    state = {
        loaded: false
    };

    componentDidMount() {
        if (!this.state.loaded) {
            this.setState({loaded: true});
            this.loadEventsFromApi();
            this.loadContactsFromApi();
            this.loadProfileFromApi();
            this.loadPublicationsFromApi();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeLanguage && (prevProps.profile?.selectedCompanyId !== this.props.profile?.selectedCompanyId || prevProps.activeLanguage?.code !== this.props.activeLanguage?.code) || !this.state.loaded) {
            this.setState({loaded: true});
            this.loadEventsFromApi();
            this.loadContactsFromApi();
            this.loadProfileFromApi();
            this.loadPublicationsFromApi();
        }
    }

    loadEventsFromApi = () => {
        EventsApi.loadEvents(false)
            .then(response => {
                if (response.status === 200) {
                    this.props.eventsLoadSuccess(response.data.data);
                }
            }).catch(e => null)
    };

    loadContactsFromApi = () => {
        ContactsApi.loadContacts()
            .then(response => {
                if (response.status === 200) {
                    this.props.contactsLoadSuccess(response.data.data);
                }
            }).catch(e => null)
    }

    loadProfileFromApi = () => {
        ProfileApi.get()
            .then(response => {
                if (response.status === 200) {
                    this.props.profileLoadSuccess(response.data.data.name, response.data.data.institution, response.data.data.email, response.data.data.itemId, response.data.data.kmb, response.data.data.coverage, response.data.data.image, response.data.data.company);
                }
            }).catch(e => null)
    }

    loadPublicationsFromApi = () => {
        PublicationsApi.get()
            .then(response => {
                if (response.status === 200) {
                    this.props.publicationsLoadSuccess(response.data.data);
                }
            })
    }

    render() {
        return (
            <div className="Authorized">
                <LanguageToggle className="d-none d-lg-block"/>
                <Switch>
                    <Route exact path={ROUTE_PROFILE} component={Profile}/>
                    <Route exact path={ROUTE_PUBLICATION_WEB_VIEW} component={PublicationWebView}/>
                    <Route exact path={ROUTE_PUBLICATIONS} component={Publications}/>
                    <Route exact path={ROUTE_PUBLICATIONS_DIRECT_ITEM} component={Publications}/>
                    <Route exact path={ROUTE_CONTACTS} component={Contacts}/>
                    <Route exact path={ROUTE_CONTACTS_PRINT} component={ContactsPrint}/>
                    <Route exact path={ROUTE_MEETINGS} component={Meetings}/>
                    <Route exact path={ROUTE_PAST} render={() => (
                        <Meetings past/>
                    )}/>
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, {
    eventsLoadSuccess, contactsLoadSuccess, profileLoadSuccess, publicationsLoadSuccess
})(withLocalize(Authorized));

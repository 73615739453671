import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import gfx_logoMontega from '../../../gfx/logo_montega.svg';
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {useSelector} from "react-redux";

const PublicationWebView = () => {
  const publicationsState = useSelector(state => state.publications);
  let { id } = useParams();
  let [publication, setPublication] = useState(null);

  useEffect(() => {
    if (id && publicationsState.loaded && publicationsState.items) {
      setPublication(publicationsState.items.find(p => p.id === parseInt(id)));
    }
  }, [id, publicationsState]);

  return (
    <div className="PublicationWebView">
      <div className="header">
        <img src={gfx_logoMontega} alt="montega"/>
        <h2>
          <Translate id="publicationWebView.header.title"/>
        </h2>
      </div>
      <div className="content">
        {publication && (
          <div className="content-box">
            <h3 className="title">
              {publication.downloads.web?.title}
            </h3>
            <div className="line-container">
              <BorderWithDot/>
            </div>
            <div className="content-text" dangerouslySetInnerHTML={{__html: publication.downloads.web?.html}}/>
            <div className="content-footer" dangerouslySetInnerHTML={{__html: publication.downloads.web?.footer}}/>
          </div>
        )}
      </div>
    </div>
  )
};

export default PublicationWebView;